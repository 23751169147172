import React from 'react';
import { compose } from 'redux';
import { renderRoutes } from 'react-router-config';
import { withStyles } from 'react-with-styles';
import Container from '@material-ui/core/Container';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import StickyFooter from 'react-sticky-footer';
import Spacing from './dls-web/exp/Spacing';
import Text, { SIZE, COLOR, WEIGHT } from './dls-web/exp/Text';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '@material-ui/core/Link';

import FlyoutMenuContent from './shared/components/FlyoutMenuContent';
import HeaderContent from './shared/components/HeaderContent';
import HeaderContainer from './header/containers/HeaderContainer';
import withGlobalTheme from './themes/withGlobalTheme';

class App extends React.Component {

  render() {
    // @ts-ignore
    const { route } = this.props;
    return (
      <div>
        {/* header */}
        <HeaderContainer
          // @ts-ignore
          flyoutMenuIsOpen={true}
          menuIndicatorIsActive
          hideNavigation={false}
          navigationIsHidden={false}
          disableFlyoutMenu={false}
          flyoutMenu={<FlyoutMenuContent flyoutMenuIsOpen />}
        >
          <HeaderContent />
        </HeaderContainer>
        {/* child routes won't render without this */}
        {renderRoutes(route.routes)}

        <StickyFooter
          bottomThreshold={10000}
          normalStyles={{
            backgroundColor: "#cccccc",
            padding: "2rem"
          }}
          stickyStyles={{
            backgroundColor: "rgba(255,255,255,.8)",
            padding: "2rem"
          }}
        >
          <Container maxWidth="lg">
            <Spacing bottom={.75}>
              <MailOutlineOutlinedIcon
                fontSize='small'
                style={{ marginBottom: '-.305rem', marginRight: '.2rem' }}
              />
              <Text inline size={SIZE.REGULAR} color={COLOR.INHERIT} weight={WEIGHT.DEFAULT}>
                hello@penslz.com
              </Text>
            </Spacing>
            {/*< br />*/}

            <Link color='inherit' href="https://www.instagram.com/penslz/">
              <InstagramIcon fontSize='small' />
            </Link>

            <Link color='inherit' href="https://www.facebook.com/penslz-108972850545751/">
              <FacebookIcon fontSize='small' />
            </Link>

            <Spacing top={2}>
              <Text size={SIZE.SMALL} color={COLOR.INHERIT} weight={WEIGHT.DEFAULT}>
                © Penslz LLC.
              </Text>
            </Spacing>
          </Container>
        </StickyFooter>
      </div>
    );
  }
}

export default compose(
  withGlobalTheme,
  withStyles(() => ({
  })),
)(App);
