export const CURRENT_THEME_KEY = 'airbnb-dls-web withStyles theme';
export const CURRENT_STYLE_SHEET_KEY = 'airbnb-dls-web withStyles';

export const THEME_KEYS = [CURRENT_THEME_KEY, CURRENT_STYLE_SHEET_KEY];

// See lib/dls/admin/theme_override.rb
export const CEREAL_THEME = 1 as const;

export const DEFAULT_THEME = CEREAL_THEME;

export type ThemeId = 1 | 3 | 6;
export const THEME_IDS = [CEREAL_THEME];

export default {
  DEFAULT_THEME,
  CEREAL_THEME,
};
