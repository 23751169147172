const chroma = require('chroma-js');

const ACTIVE_COLOR_MIN_LIGHTNESS = 35;
const ACTIVE_COLOR_LIGHTNESS_DELTA = 10; // based off of default button
const ACTIVE_SECONDARY_COLOR_OPACITY = 0.15;
const DISABLED_COLOR_OPACITY = 0.3;

// for future
// const SOME_BRAND_ACTIVE_COLOR = '#773152'; // overrides selectActiveColor
// const SOME_BRAND_DISABLED_COLOR = '#E3D4E1'; // overrides selectDisabledColor

/**
 * Returns the active color for a defaultColor button
 *
 * We convert the color to the Lab color space, a perceptual color model, and
 * base the new color off of the color's lightness. When the color is in range,
 * we decrease the lightness for a darkening effect. Otherwise, or when the
 * color is too dark, we increase it the lightness. The LAB color space more
 * accurately changes the color while maintaining its perceptual qualities.
 *
 * Learn more at https://en.wikipedia.org/wiki/Lab_color_space
 *
 * @param   {string} defaultColor CSS color value
 * @returns {string} String in hex format
 */
function standardActiveColor(color) {
  if (!color || color === 'transparent') {
    return color;
  }

  let newLightness;

  const lightness = chroma(color).lab()[0];
  if (lightness > ACTIVE_COLOR_MIN_LIGHTNESS) {
    newLightness = lightness - ACTIVE_COLOR_LIGHTNESS_DELTA;
  } else {
    newLightness = Math.max(lightness + ACTIVE_COLOR_LIGHTNESS_DELTA, ACTIVE_COLOR_MIN_LIGHTNESS);
  }

  // change the ligthness in the LAB color space
  return chroma(color)
    .set('lab.l', newLightness)
    .hex();
}

/**
 * Returns the active background color for a secondary button
 *
 * @param   {string} baseColor CSS color value
 * @returns {string} String in rgba format
 */
function secondaryActiveBackgroundColor(baseColor) {
  if (!baseColor || baseColor === 'transparent') {
    return baseColor;
  }
  return chroma(baseColor)
    .alpha(ACTIVE_SECONDARY_COLOR_OPACITY)
    .css();
}

/**
 * Returns the disabled color for a defaultColor button
 *
 * @param   {string} color CSS color value
 * @returns {string} String in rgba format
 */
function standardDisabledColor(color) {
  if (!color || color === 'transparent') {
    return color;
  }
  return chroma(color)
    .alpha(DISABLED_COLOR_OPACITY)
    .css();
}

module.exports = {
  ACTIVE_COLOR_MIN_LIGHTNESS,
  ACTIVE_COLOR_LIGHTNESS_DELTA,
  ACTIVE_SECONDARY_COLOR_OPACITY,
  DISABLED_COLOR_OPACITY,
  standardActiveColor,
  secondaryActiveBackgroundColor,
  standardDisabledColor,
};
