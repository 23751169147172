import AirbnbDefaultThemeWithExternals from '../PenslzDefaultThemeWithExternals';
import buildFont from './buildFont';
import __typography, { FONT_FAMILY } from './typography';

export { buildFont };

const font = buildFont(FONT_FAMILY, AirbnbDefaultThemeWithExternals);

export default {
  ...AirbnbDefaultThemeWithExternals,
  reactDates: {
    ...AirbnbDefaultThemeWithExternals.reactDates,
    font: {
      ...AirbnbDefaultThemeWithExternals.reactDates.font,
      input: {
        ...AirbnbDefaultThemeWithExternals.reactDates.font.input,
        size: font.formElement.fontSize,
        lineHeight: font.formElement.lineHeight,
        size_small: font.formElementSmall.fontSize,
        lineHeight_small: font.formElementSmall.lineHeight,
        letterSpacing_small: font.formElementSmall.letterSpacing,
      },
    },
  },
  font: {
    FONT_FAMILY,
    ...font,
  },
  // the goal of typography is to eventually replace font
  // to move to the new Cereal typography styles
  __typography,

  // dls19: DLS19Theme.dls19,
};
