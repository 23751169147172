export const CUSTOM_STYLES_KEY = 'link';

export default {
  fontFamily: '--font-font_family',
  textColor: '--color-text-link',
  textColorActive: '--color-text-link-active',
  textColorDisabled: '--color-text-muted',
  textColorHover: '--color-text-link-hover',
  textDecoration: '--font-link-text-decoration',
  textDecorationHover: '--font-link-text-decoration-hover',
  textDecorationFocus: '--font-link-text-decoration-focus',
  textDecorationDisabled: '--font-link-text-decoration-disabled',
  textDecorationMono: '--font-link-text-decoration-mono',
};
