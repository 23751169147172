import { prefersReducedMotionQuery } from '../prefersReducedMotion';

export default function focusedStyles(color: string) {
  return {
    boxShadow: `0 0 2px 2px ${color}`,
    outline: 'none',
  };
}

export function resetFocusStyles() {
  return {
    // disables the native focus styles since we're implementing a custom one
    outline: 'none',
    '::-moz-focus-inner': {
      border: 'none',
      padding: 0,
      margin: 0,
    },
    ':focus::-moz-focus-inner': {
      border: 'none',
    },
    ':-moz-focusring': {
      outline: 'none',
    },
  };
}

export function focusTransitionStyles() {
  return {
    // animation
    transition: 'box-shadow 0.2s ease',
    [prefersReducedMotionQuery]: {
      transition: 'none',
    },
  };
}

export function defaultFocusedRingStyles() {
  return {
    boxShadow: '0 0 0 4px #ffffff, 0 0 0 5px #717171, 0 0 0 6px rgba(255,255,255,0.5)',
    ...resetFocusStyles(),
    ...focusTransitionStyles(),
  };
}

export function inverseFocusedRingStyles() {
  return {
    boxShadow: '0 0 0 1px rgba(0,0,0,0.5), 0 0 0 5px rgba(255,255,255,0.7)',
    ...resetFocusStyles(),
    ...focusTransitionStyles(),
  };
}

export function inverseSecondaryFocusedRingStyles() {
  return {
    boxShadow: '0 0 0 5px rgba(0,0,0,0.8), 0 0 0 6px rgba(176,176,176,1.0)',
    ...resetFocusStyles(),
    ...focusTransitionStyles(),
  };
}
