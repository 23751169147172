/* eslint-disable import/export, no-use-before-define, @typescript-eslint/no-use-before-define */
import React from 'react';

type AnyComponent = React.ComponentType<any>;

type HOC<TIn extends AnyComponent, TOut extends AnyComponent> = (component: TIn) => TOut;

export default function withHOCs<C extends AnyComponent, C2 extends AnyComponent>(
  component: C,
  hoc1: HOC<C, C2>,
): C2;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent
>(component: C0, hoc1: HOC<C0, C1>, hoc2: HOC<C1, C2>): C2;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent
>(component: C0, hoc1: HOC<C0, C1>, hoc2: HOC<C1, C2>, hoc3: HOC<C2, C3>): C3;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent
>(component: C0, hoc1: HOC<C0, C1>, hoc2: HOC<C1, C2>, hoc3: HOC<C2, C3>, hoc4: HOC<C3, C4>): C4;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
): C5;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent,
  C6 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
  hoc6: HOC<C5, C6>,
): C6;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent,
  C6 extends AnyComponent,
  C7 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
  hoc6: HOC<C5, C6>,
  hoc7: HOC<C6, C7>,
): C7;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent,
  C6 extends AnyComponent,
  C7 extends AnyComponent,
  C8 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
  hoc6: HOC<C5, C6>,
  hoc7: HOC<C6, C7>,
  hoc8: HOC<C7, C8>,
): C8;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent,
  C6 extends AnyComponent,
  C7 extends AnyComponent,
  C8 extends AnyComponent,
  C9 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
  hoc6: HOC<C5, C6>,
  hoc7: HOC<C6, C7>,
  hoc8: HOC<C7, C8>,
  hoc9: HOC<C8, C9>,
): C9;

export default function withHOCs<
  C0 extends AnyComponent,
  C1 extends AnyComponent,
  C2 extends AnyComponent,
  C3 extends AnyComponent,
  C4 extends AnyComponent,
  C5 extends AnyComponent,
  C6 extends AnyComponent,
  C7 extends AnyComponent,
  C8 extends AnyComponent,
  C9 extends AnyComponent,
  C10 extends AnyComponent
>(
  component: C0,
  hoc1: HOC<C0, C1>,
  hoc2: HOC<C1, C2>,
  hoc3: HOC<C2, C3>,
  hoc4: HOC<C3, C4>,
  hoc5: HOC<C4, C5>,
  hoc6: HOC<C5, C6>,
  hoc7: HOC<C6, C7>,
  hoc8: HOC<C7, C8>,
  hoc9: HOC<C8, C9>,
  hoc10: HOC<C9, C10>,
): C10;

export default function withHOCs(
  component: AnyComponent,
  ...hocs: HOC<AnyComponent, AnyComponent>[]
): AnyComponent {
  return hocs.reduce((prevComponent, hoc) => hoc(prevComponent), component);
}
