import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, withStylesPropTypes } from 'react-with-styles';

const propTypes = {
  ...withStylesPropTypes,
  floating: PropTypes.bool,
  asyncContents: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

const defaultProps = {
  floating: false,
  asyncContents: false,
  ariaLabel: undefined,
};

function Navigation({ ariaLabel, css, floating, styles, children, asyncContents }) {
  // If contents are async, pass-through on the top-most navigation
  // so we can render the navigation on the Async component
  if (asyncContents) {
    return children;
  }

  return (
    <nav aria-label={ariaLabel}>
      <ul {...css(styles.list)}>
        {React.Children.map(children, (item) => {
          // Children can be conditionally rendered, and React.Children.map iterates over
          // null children as well, so we need to check if the current item is valid.
          if (!React.isValidElement(item)) return null;

          return <li {...css(styles.item, floating && styles.item_floating)}>{item}</li>;
        })}
      </ul>
    </nav>
  );
}

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default withStyles(() => ({
  list: {
    display: 'table',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    height: 64,
  },

  item: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}))(Navigation);
