import PropTypes from 'prop-types';
import { Props, InnerType } from './types';

export default function requiredUnless<PT extends PropTypes.Validator<any>>(
  optionalPropName: string,
  propType: PT,
  defaultValue = null,
): PropTypes.Validator<InnerType<PT>> {
  return function validator(props: Props, propName, componentName, ...rest) {
    if (props[optionalPropName]) {
      return propType(props, propName, componentName, ...rest);
    }

    if (props[propName] === defaultValue || typeof props[propName] === 'undefined') {
      return new TypeError(
        `${componentName}: when ${optionalPropName} is not set, prop “${propName}” must be present.`,
      );
    }

    return null;
  };
}
