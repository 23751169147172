// The total height of the header
export const HEADER_HEIGHT = 64;
export const EXPLORE_2_0_HEADER_HEIGHT = 80;

export const FILTER_BAR_HEIGHT = 48;
export const FILTER_BAR_ITEM_HEIGHT = 32;
export const SIDE_FILTER_BAR_TOP = EXPLORE_2_0_HEADER_HEIGHT + 24;
export const SIDE_FILTER_BAR_WIDTH = 288;

export const HEADER_BANNER_HEIGHT = 344;
export const TALL_BANNER_HEIGHT = 400;
export const TALL_BANNER_HEIGHT_LARGE = 480;

// Defines all of the z-index values that are used in the header
export const ZINDEX = {
  container: 5,
  flyoutMenu: 10,
  flyoutMenuMask: 15,
  logo: 20,
};

// Color themes. These determine the color of links and icons.
export enum THEMES {
  // Links are Hof, and the Belo icon is Rausch.
  Default = 'default',
  // Links and icons will be white
  Light = 'light',
  // Links and icons will be Hof
  Dark = 'dark',
  // Logo will be hackberry (pdp-platform)
  Hackberry = 'hackberry',
  // Logo will be purplerain
  Purplerain = 'purplerain',
  // Links and icons will be white, background will be babu
  Babu = 'babu',
}

// for JSX components using as PropType
export const ThemePropTypes = Object.values(THEMES);

export const VARIANTS = {
  StepsNavigation: 'stepsNavigation',
};

// Height of webcot tabs
export const NAV_HEIGHT = 41;

// Defines a header-specific breakpoint between "small" and "medium".
//
// @NOTE: this exported constant is experimental and is likely to be
// removed altogether in the future. Unless you are utilizing this
// constant for use in Webcot, please do NOT use it.
// It is also used in the Help Center v3 to show navigation links on
// the page when they are hidden in the menu.
export const NAVIGATION_PICKER_BREAKPOINT = 1370;

export const CHINA_HIDE_ITEM_NAVIGATION_BREAKPOINT = 1340;

// DEPRECATED, DO NOT USE
export const NAVIGATION_BREAKPOINT = 1075;

// DEPRECATED, DO NOT USE
export const HEADER_BREAKPOINTS = {
  withNavigation: `@media (min-width: ${NAVIGATION_BREAKPOINT}px)`,
  withoutNavigation: `@media (max-width: ${NAVIGATION_BREAKPOINT - 1}px)`,
};

// Define the event names that can be used to interact with the header
export const EVENTS = {
  login: 'login',
  loginComplete: 'login:complete',
  logout: 'logout',
  setNotification: 'header:setNotification',
  removeNotification: 'header:removeNotification',
  setUserProfilePicture: 'header:setUserProfilePicture',
  clearUserProfilePicture: 'header:clearUserProfilePicture',
  toggleNavigationMenuItem: 'header:toggleNavigationMenuItem',
  updateUserCurrency: 'header:updateUserCurrency',
};

export const CACHE_HIRE_BUTTON_API_CALL_TIL = 3 * 60 * 60 * 1000; // 3 hours
export const CACHE_EXPERIENCE_HOST_CHECK = 6 * 60 * 60 * 1000; // 6 hours
export const CACHE_THUMBNAIL_PROFILE_PIC_TTL = 24 * 60 * 60 * 1000; // 24 hours
export const CACHE_QUALIFIES_FOR_HOSTING_SERVICES_CHECK = 6 * 60 * 60 * 1000; // 6 hours

export const EXPERIENCE_HOST_TABS = {
  EXPERIENCES: 0,
  RESOURCES: 1,
  CALENDAR: 2,
  INBOX: 3,
  DASHBOARD: 4,
  REVIEWS: 5,
};

export const HOST_TYPE = {
  FirstUnpublished: 'first_unpublished_listing',
  Suspended: 'suspended',
};

export const CACHE_EARN_CREDIT_DROPDOWN_VIEWED_TTL = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months

export const WMPW_DURATION = '1_month';

export const QUEUE_STATUS_FOR_EXPERIENCE_HOST_MENU = 7;

// Note: The following must be kept in sync with .airbnb-header for consistency
// TODO: Migrate to dls theme
export const BORDER_LIGHT = '#dce0e0';

export const CACHE_THUMBNAIL_PROFILE_PIC_KEY = 'header_userpic_url';

export const FIELD_GUIDE = {
  TOGGLE_GUEST: 'fieldGuide:toggleGuest',
  TOGGLE_HOST: 'fieldGuide:toggleHost',
};
