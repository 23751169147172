import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import iconSize from '../utils/propTypes/iconSize';
import IconChevronDown from '../icons/IconChevronDown';
import Rotate from './Rotate';
import { PropsType } from '../private/types';

export const activeChevronPropTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  size: iconSize,
};

export type ActiveChevronProps = PropsType<typeof activeChevronPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...activeChevronPropTypes,
  // NOTE: Add public props to activeChevronPropTypes
});

const defaultProps = {
  color: 'currentColor',
  isActive: false,
  size: '1em',
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function ActiveChevron({ size, color, isActive }: PrivateProps) {
  return (
    <Rotate degrees={isActive ? 180 : 0}>
      <IconChevronDown decorative color={color} size={size} />
    </Rotate>
  );
}
ActiveChevron.propTypes = privatePropTypes;
ActiveChevron.defaultProps = defaultProps;

export default ActiveChevron as React.SFC<ActiveChevronProps>;
