// DLS Default Theme Shadows

const elevation = {
  level0: { boxShadow: 'none' },
  level1: { boxShadow: '0 0.5px 2px rgba(0, 0, 0, 0.18)' },
  level2: { boxShadow: '0 1px 4px rgba(0, 0, 0, 0.16)' },
  level3: { boxShadow: '0 2px 8px rgba(0, 0, 0, 0.16)' },
  level4: { boxShadow: '0 4px 16px rgba(0, 0, 0, 0.16)' },
};

const button = {
  ...elevation,
};

export default {
  elevation,
  button,
};
