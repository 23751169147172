// Manually inlining the babelHelpers we need for our preval'd files so they can
// be evaluated with preval at the same time that we have external helpers
// enabled. Since this will compiled with preval, this code will all disappear
// in the built module.
global.babelHelpers = {
  defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value,
        enumerable: true,
        configurable: true,
        writable: true,
      });
    } else {
      obj[key] = value; // eslint-disable-line no-param-reassign
    }

    return obj;
  },

  objectWithoutProperties(obj, keys) {
    const target = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const i in obj) {
      if (keys.indexOf(i) >= 0) continue; // eslint-disable-line no-continue
      // eslint-disable-next-line no-continue
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  },
};
