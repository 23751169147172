import PropTypes from 'prop-types';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';

// This is the only file that gets to import from react-with-styles directly
// eslint-disable-next-line no-restricted-imports
import {
  css,
  withStyles,
  withStylesPropTypes as _withStylesPropTypes,
  WithStylesProps,
  WithStylesOptions,
  Theme,
  Styles,
  CSSProperties,
} from 'react-with-styles';

// Note: while we are in the upgrade process for RWS, we need to make the css prop optional
const withStylesPropTypes: typeof _withStylesPropTypes = {
  ..._withStylesPropTypes,
  css: PropTypes.func,
} as typeof _withStylesPropTypes; // TODO: ask whether we can remove the `css: PropTypes.func` above, so we don't have to do this

export {
  withStyles,
  withStylesPropTypes,
  // @ts-ignore
  WithStylesProps,
  // @ts-ignore
  WithStylesOptions,
  // @ts-ignore
  Theme,
  // @ts-ignore
  Styles,
  // @ts-ignore
  CSSProperties,
  // Deprecated
  ThemedStyleSheet,
  css,
};
