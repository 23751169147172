import PropTypes from 'prop-types';
import { Props } from './types';

const hrefRegex = /^$|^#$|^javascript:/;

const href: PropTypes.Requireable<string> = (props: Props, propName, componentName) => {
  if (hrefRegex.test(props[propName])) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`,
    );
  }
  return null;
};

href.isRequired = (props: Props, propName, componentName, ...rest) => {
  if (!props[propName]) {
    return new Error(`Invalid prop \`${propName}\` was not specified in \`${componentName}\``);
  }
  return href(props, propName, componentName, ...rest);
};

export default href;
