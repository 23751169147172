// NOTE(gil): Any changes here require an update in
// size.js for PROGRESSIVE_BREAKPOINT_NAMES

export const MEDIUM_AND_ABOVE = 'mediumAndAbove' as 'mediumAndAbove';
export const LARGE_AND_ABOVE = 'largeAndAbove' as 'largeAndAbove';
export const XLARGE_AND_ABOVE = 'xlargeAndAbove' as 'xlargeAndAbove';

export type ProgressiveBreakpointName =
  | typeof MEDIUM_AND_ABOVE
  | typeof LARGE_AND_ABOVE
  | typeof XLARGE_AND_ABOVE;
