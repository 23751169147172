import PropTypes from 'prop-types';

const ariaCurrentTypes = {
  PAGE: 'page' as 'page',
  STEP: 'step' as 'step',
  LOCATION: 'location' as 'location',
  DATE: 'date' as 'date',
  TIME: 'time' as 'time',
  TRUE: 'true' as 'true',
};

export default ariaCurrentTypes;
export const ariaCurrentType = PropTypes.oneOf(Object.values(ariaCurrentTypes));
