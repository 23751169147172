// This component provides a string to React context that is consumed by the
// withBreakpoint higher-order component. We can use this to give access to
// the current breakpoint for components to use. We have exported the needed
// Functions, ContextTypes and Props to be used elsewhere, such as in top level
// components like withHypernovaBootstrap.

// NOTE: BreakpointProvider should only be used at the top of the react tree

import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps, restrictedProp } from 'airbnb-prop-types';
import brcast, { Broadcast } from 'brcast';
import brcastShape from '../shapes/brcast';
import {
  onBreakpointChange,
  enqueueInitializeBreakpointListeners,
} from '../utils/getCurrentBreakpoint';
import { PropsType } from '../private/types';

export const CHANNEL = '__current_breakpoint__';

export const breakpointProviderPropTypes = {
  initialCurrentBreakpoint: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export type BreakpointProviderProps = PropsType<typeof breakpointProviderPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...breakpointProviderPropTypes,
  // NOTE: Add public props to breakpointProviderPropTypes
});

const defaultProps = {
  initialCurrentBreakpoint: null,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

const contextTypes = {
  [CHANNEL]: restrictedProp(
    (_props: any, propName: any, componentName: any, location: any) =>
      `${componentName} received the ${propName} ${location} meaning it is nested inside of another BreakpointProvider - it should only appear once, at the top of the react tree.`,
  ),
};

const childContextTypes = {
  [CHANNEL]: brcastShape,
};

export default class BreakpointProvider extends React.Component<PrivateProps> {
  static propTypes = privatePropTypes;

  static defaultProps = defaultProps;

  static contextTypes = contextTypes;

  static childContextTypes = childContextTypes;

  broadcast: Broadcast<string | null>;

  currentBreakpoint: string | null;

  unsubscribe?: () => void;

  constructor(props: PrivateProps) {
    super(props);
    const { initialCurrentBreakpoint } = this.props;
    this.currentBreakpoint = initialCurrentBreakpoint;
    this.broadcast = brcast(initialCurrentBreakpoint);
  }

  getChildContext() {
    return {
      [CHANNEL]: this.broadcast,
    };
  }

  componentDidMount() {
    this.unsubscribe = onBreakpointChange((currentBreakpoint) => {
      this.currentBreakpoint = currentBreakpoint;
      this.broadcast.setState(currentBreakpoint);
    });

    enqueueInitializeBreakpointListeners();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}
