import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Spacing from '../../dls-web/exp/Spacing';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    // minHeight: 270,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export default function SimpleCard({
  copy,
}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {copy.highlight}
        </Typography>
        <Typography variant="h5" component="h2">
          {copy.title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {copy.subtitle}
        </Typography>

        <Typography variant="body1" component="p">
          {copy.body}
        </Typography>

        <Typography variant="body2" component="p">
          {copy.quote && <br />}
          {copy.quote && copy.quote}
        </Typography>

        <Spacing top={2}>
          <img className={classes.img} alt="" src={copy.imgSrc} />
        </Spacing>

      </CardContent>
      {/*
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
        */}
    </Card>
  );
}
