import PropTypes from 'prop-types';
import { or, range } from 'airbnb-prop-types';

// all values are based on unit size
const adjustableSpacing = {
  NONE: 0,
  MICRO: 0.5,
  TINY: 1,
  SMALL: 2,
  NORMAL: 3,
  SEMI: 4,
  LARGE: 6,
  XLARGE: 8,
};

export const validHalfSpacings = [0.5, 1.5];
export const adjustableSpacingShape = or([PropTypes.oneOf(validHalfSpacings), range(0, 13)]);
export default adjustableSpacing;
