import border from '../primitives/border';
import spacing from '../primitives/spacing';
import font from '../primitives/font';
import color from '../primitives/color';
import __typography from '../primitives/typography';
import size, { responsive as _responsive, unit } from '../primitives/size';
import shadow from '../primitives/shadow';
import { MEDIUM_AND_ABOVE, LARGE_AND_ABOVE, XLARGE_AND_ABOVE } from '../constants/progressives';

// Declare type explicitly to address the following error:
// [ts] Default export of the module has or is using private name
const responsive: {
  [x: string]: string;
  [MEDIUM_AND_ABOVE]: string; // eslint-disable-line @typescript-eslint/member-ordering
  [LARGE_AND_ABOVE]: string; // eslint-disable-line @typescript-eslint/member-ordering
  [XLARGE_AND_ABOVE]: string; // eslint-disable-line @typescript-eslint/member-ordering
  print: string; // eslint-disable-line @typescript-eslint/member-ordering
} = _responsive;

export default {
  font,
  color,
  unit,
  responsive,
  border,
  spacing,
  shadow,
  size,
  // the goal of typography is to eventually replace font
  // to move to the new Cereal typography styles
  __typography,
};
