const core = {
  rausch: '#FF5A5F',
  babu: '#008489',
  arches: '#FC642D',
  hof: '#484848',
  foggy: '#767676',
  hackberry: '#A61D55',
  purplerain: '#210034',
  blackberry: '#210034',
  skyberry: '#495BFF',
};

const brand = {
  some: '#460479',
};

const social = {
  alipay: '#00a9f1',
  email: '#2e2e30',
  facebook: '#4568b2',
  google: '#ffffff',
  kakaotalk: '#2e2e30',
  messenger: '#0084ff',
  telegram: '#0088cc',
  twitter: '#00aced',
  wechat: '#09bc13',
  weibo: '#df2029',
  whatsapp: '#34af23',
  yahooJapan: '#ff0033',
};

const white = '#ffffff';
const black = '#000000';
const clear = 'transparent';

module.exports = {
  brand,
  core,
  social,
  white,
  black,
  clear,
};
