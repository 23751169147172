import hexToRGBA from './utils/hexToRGBA';
import colors from './utils/colors';

const { core, brand, black, white, clear, buttons: buttonColors } = colors;

// fun fact: the colors in the `darker` object are somewhere in between core and dark in
// terms of lightness. TODO: find a better name.
const darker = {
  rausch: '#d43242',
  arches: '#d93900',
};

const dark = {
  babu: '#006C70',
  rausch: '#950004',
  arches: '#8A2400',
};

const accent = {
  bgGray: '#F2F2F2',
  lightGray: '#D8D8D8',
  darkGray: '#2B2B2B',
  hrGray: '#EBEBEB',
  interactiveGray: '#b0b0b0',
  beach: '#FFB400',
  blue: '#60B6B5',
  babuDark: hexToRGBA(dark.babu, 0.15),
  babuLight: '#CCEEEB',
  olinda: '#f7f3ea',
  online: '#5CDC97',
  rausch: core.rausch,
};

const status = {
  danger: core.arches,
  info: core.babu,
  warning: accent.beach,
  online: accent.online,
};

const buttons = {
  // Standard, secondary, and social button colors
  ...buttonColors,

  // Other button colors
  floatingBackground: white,
  floatingDivider: core.foggy,
};

const autocomplete = {
  resultsBackground: white,
  resultsBorder: accent.hrGray,
  resultActiveBackground: accent.babuLight,
  resultIcon: core.foggy,
  resultIconInverse: white,
};

const mapPriceMarker = {
  defaultBackground: white,
  defaultBorder: hexToRGBA(black, 0.2),
  defaultText: '#222222',
  shadow: hexToRGBA(black, 0.15),
  activeDefaultBackground: core.babu,
  activeDefaultBorder: core.babu,
  activeDefaultText: white,
  viewedText: core.foggy,
};

export default {
  hexToRGBA,
  opacity: hexToRGBA, // deprecated: use hexToRGBA instead
  brand,
  core,
  dark,
  darker,
  accent,
  status,
  white,
  black,
  focus: core.babu,
  clear,
  buttons,
  imageBackground: '#bbbbbb',
  textDark: core.hof,
  textLight: white,
  textInput: core.babu,
  textLink: core.babu,
  textLinkActive: dark.babu,
  textLinkHover: core.babu,
  textLinkInverse: white,
  textLinkInverseHover: white,
  textLinkInverseActive: '#dadada',
  textLinkNavigation: core.hof,
  textLinkNavigationHover: core.hof,
  textMuted: core.foggy,
  textDisabled: accent.hrGray,
  textMutedLarge: '#848484',
  textPlaceholder: accent.hrGray,
  backgroundLight: white,
  backgroundLightHighlightedOrSelected: accent.hrGray,
  backgroundDark: core.foggy,
  placeholder: core.foggy,
  divider: accent.hrGray,
  cursor: core.babu,
  heroBackground: core.rausch,
  sheetBackground: core.babu,
  sheetBackgroundInvalid: core.arches,
  sheetContentColor: white,
  sheetInactiveContentColor: core.babu,
  loaderColorLight: white,
  loaderColorDark: core.babu,
  loaderColorError: accent.beach,
  buttonBarButton: core.babu,
  buttonBarButtonTapped: '#00998C',
  buttonBarText: white,
  backgroundListingTags: 'rgba(43, 43, 43, 0.75)',
  rowPlaceholderText: '#878787',
  rowUnderlay: '#dedede',
  panelBorder: '#e4e4e4',
  mapRefreshLoaderBackground: core.babu,
  mapRefreshShadow: hexToRGBA(core.hof, 0.3),
  microSectionHeaderText: core.foggy,
  signpostShadow: hexToRGBA(core.hof, 0.3),
  mapSignpost: core.hof,
  regionBorder: core.babu,
  regionBackground: hexToRGBA(core.babu, 0.3),
  toolbarDark: core.babu,
  starBlankColor: accent.lightGray,
  starHighlightColor: accent.beach,
  starBabuHighlightColor: core.babu,
  inputBackground: white,
  inputBorder: accent.hrGray,
  inputFocusedBorder: core.babu,
  inputFocusedUnderlineBorder: core.babu,
  inputInvalidBorder: core.arches,
  inputInvalidBackground: '#FFF8F6',
  inputErrorMessage: darker.arches,
  inputPrefixSelectBackground: white,
  inputPrefixSelectBorder: accent.hrGray,

  checked: core.babu,
  checkBoxShadow: accent.babuLight,
  checkedDisabled: accent.bgGray,
  inputDisabled: accent.bgGray,
  selectDisabled: accent.bgGray,

  autocomplete,
  carousel: {
    overlay: hexToRGBA(black, 0.3),
  },
  listingCard: {
    indicator: hexToRGBA(accent.darkGray, 0.75),
  },
  mapPriceMarker,
  modal: {
    overlay: hexToRGBA(black, 0.75),
    whiteOverlay: hexToRGBA(white, 0.8),
    background: white,
  },
  toggleButton: {
    background: white,
    backgroundSelected: core.hof,
  },
  pageIndicatorDots: {
    background: hexToRGBA(accent.interactiveGray, 0.8),
    backgroundSelected: core.hof,

    backgroundLight: hexToRGBA('#FFFFFF', 0.8),
    backgroundSelectedLight: white,
  },

  loaderDots: {
    default: core.babu,
    light: white,
    dark: core.hof,
  },
  printOverrides: {
    textMuted: core.hof,
    textMutedLarge: core.hof,
  },
  carouselNavigation: {
    gradientInner: clear,
    gradientOuter: hexToRGBA(black, 0.25),
  },

  countBadge: {
    background: core.babu,
    textColor: white,
  },

  tab: {
    underlineColorSelected: core.babu,
    textColorSelected: core.babu,
  },

  attributeToggle: {
    backgroundSelected: core.babu,
    backgroundSelectedOn: core.babu,
    backgroundSelectedOff: accent.interactiveGray,
    borderColorUnselected: accent.interactiveGray,
  },
};
