/* eslint no-bitwise: off */
const HEX_STRING_REGEX = /^#?[0-9a-fA-F]{6}$/;

// Convert a HEX color string to RGBA, with an optional opacity.
export default function hexToRGBA(hexString: any, opacity = 1) {
  if (hexString === 'transparent') {
    return hexString;
  }
  if (!HEX_STRING_REGEX.test(hexString)) {
    throw new TypeError('hexString is not valid');
  }

  const hexInt =
    hexString.charAt(0) === '#' ? parseInt(hexString.slice(1), 16) : parseInt(hexString, 16);

  const r = hexInt >> 16;
  const g = (hexInt >> 8) & 0xff;
  const b = hexInt & 0xff;

  return `rgba(${r},${g},${b},${opacity})`;
}
