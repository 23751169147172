import PenslzDefaultThemeWithExternals from '../PenslzDefaultThemeWithExternals';

const WEIGHT_BOLD = '800';
const WEIGHT_BOOK = '600';
const WEIGHT_LIGHT = 'normal';

const FONT_FAMILY_AIRMOJI_STANDALONE = 'Airmoji_Standalone';

const title1FontSize = 46;
const title1LineHeight = 52;
const title1SmallScreenFontSize = 38;
const title1SmallScreenLineHeight = 44;

const title2FontSize = 32;
const title2LineHeight = 36;

const title3FontSize = 24;
const title3LineHeight = 30;

const largeFontSize = 18;
const largeLineHeight = 26;
const largeTallLineHeight = 30;

const regularFontSize = 16;
const regularLineHeight = 22;
const regularTallLineHeight = 28;

const smallFontSize = 14;
const smallLineHeight = 18;

const microFontSize = 12;
const microLineHeight = 16;

type StyleValue = number | string;

interface TextSizeStyles {
  fontSize: StyleValue;
  lineHeight: StyleValue;
  letterSpacing?: StyleValue;
}

interface FontStyles extends TextSizeStyles {
  offset?: StyleValue;
  textTransform?: StyleValue;
}

/**
 * Returns the font theme object for the Cereal font stack.
 *
 * @export
 * @param {String} fontFamily
 * @param {Object} [theme=PenslzDefaultThemeWithExternals]
 */
export default function buildFont(fontFamily: string, theme = PenslzDefaultThemeWithExternals) {
  const { color, unit, responsive } = theme;

  function textSize({ fontSize, lineHeight, letterSpacing }: TextSizeStyles) {
    return {
      fontSize,
      lineHeight: typeof lineHeight === 'string' ? lineHeight : `${lineHeight}px`,
      letterSpacing: letterSpacing || 'normal',
    };
  }

  function font({ fontSize, lineHeight, offset, letterSpacing, textTransform }: FontStyles) {
    return {
      ...textSize({ fontSize, lineHeight, letterSpacing }),
      fontFamily,
      textTransform,
      color: color.core.hof,
      paddingTop: offset,
      paddingBottom: offset,
    };
  }

  const title1 = {
    ...font({
      fontSize: title1FontSize,
      lineHeight: title1LineHeight,
      offset: unit,
    }),
    [responsive.small]: textSize({
      fontSize: title1SmallScreenFontSize,
      lineHeight: title1SmallScreenLineHeight,
    }),
  };

  const title2 = font({
    fontSize: title2FontSize,
    lineHeight: title2LineHeight,
    offset: (unit * 3) / 4,
  });

  const title3 = font({
    fontSize: title3FontSize,
    lineHeight: title3LineHeight,
    offset: unit / 4,
  });

  const textLarge = font({
    fontSize: largeFontSize,
    lineHeight: largeLineHeight,
  });

  const textRegular = font({
    fontSize: regularFontSize,
    lineHeight: regularLineHeight,
  });

  const textSmall = font({
    fontSize: smallFontSize,
    lineHeight: smallLineHeight,
  });

  const textMicro = font({
    fontSize: microFontSize,
    lineHeight: microLineHeight,
  });

  const textLargeTall = {
    ...textLarge,
    lineHeight: `${largeTallLineHeight}px`,
  };

  const textRegularTall = {
    ...textRegular,
    lineHeight: `${regularTallLineHeight}px`,
  };

  const textMicroWide = {
    ...textMicro,
    letterSpacing: 1,
  };

  // Note: Form inputs and elements that are meant to be of the same height must share these very
  // specific line-heights because FF <select> elements ignore any other line-heights that are set
  // on them. This dictates the line-height of all components.
  const formElementSmall = {
    // Note: textSmall's font size is currently 14px. On some mobile browsers, font sizes
    // below 16px cause inputs to autozoom on focus which can cause unexpected consequences.
    ...textSmall,
    lineHeight: '22px',
  };
  const formElement = {
    ...textRegular,
    lineHeight: '24px',
  };
  const formElementLarge = {
    ...textLarge,
    lineHeight: '27px',
  };

  const link = {
    textDecoration: 'none',
    textDecorationHover: 'underline',
    textDecorationFocus: 'underline',
    textDecorationDisabled: 'none',
    textDecorationMono: 'underline',
    textDecorationUnderline: 'underline',
  };

  const typography = {
    title1,
    title2,
    title3,

    textLarge,
    textRegular,
    textSmall,
    textMicro,

    textLargeTall,
    textRegularTall,
    textMicroWide,

    formLabel: textRegular,
    formErrorMessage: textSmall,
    formErrorMessageSmall: textMicro,
    formElementLarge,
    formElement,
    formElementSmall,

    label1: textRegular,
    label2: textSmall,
    label3: {
      ...textMicro,
      paddingTop: 3,
      paddingBottom: 3,
    },
    label4: textMicro,

    airmoji: {
      fontFamily: FONT_FAMILY_AIRMOJI_STANDALONE,
      fontWeight: 'normal',
    },
    link,
  };

  const alias = {
    navigation: typography.label2,
    small: typography.textSmall,
    textReduced: typography.textRegular,
    textLargeShort: typography.textLarge,
    textRegularShort: typography.textRegular,
    formInput: typography.formElement,
    button: typography.formElement,
    buttonSmall: typography.formElementSmall,
    buttonLarge: typography.formElementLarge,
  };

  const weights = {
    light: {
      fontWeight: WEIGHT_LIGHT,
    },
    book: {
      fontWeight: WEIGHT_BOOK,
    },
    bold: {
      fontWeight: WEIGHT_BOLD,
    },
  };

  return {
    ...typography,
    ...alias,
    ...weights,
  };
}
