// DLS default theme spacing values

import { unit } from './size';
import border from './border';

export default {
  button: {
    vertical: 10,
    horizontal: 22,
  },

  buttonSmall: {
    vertical: 6,
    horizontal: 15,
  },

  buttonLarge: {
    vertical: 2 * unit - border.buttonLarge.borderWidth,
    horizontal: 3.5 * unit - border.buttonLarge.borderWidth,
  },

  formElement: {
    vertical: 11,
    horizontal: 11,
    suffixText: -11,
    decorator: 11,
    marginBottom: unit,
  },

  formElementSmall: {
    vertical: 6,
    horizontal: 7,
    suffixText: -7,
    decorator: 7,
  },

  formElementLarge: {
    vertical: 2 * unit - border.formElement.borderWidth,
    horizontal: 2 * unit - border.formElement.borderWidth,
    decorator: 2 * unit - border.formElement.borderWidth,
    suffixText: -1 * (2 * unit - border.formElement.borderWidth),
  },

  statusLabel: {
    vertical: 3,
    horizontal: 0.75 * unit,
    colorBarWidth: 0.5 * unit,
  },

  pager: {
    vertical: unit - 1,
    horizontalInside: 1.25 * unit,
    horizontalOutside: unit,
  },

  tab: {
    vertical: 3 * unit,
    horizontal: 0,
    marginRight: 4 * unit,
  },

  tabLarge: {
    vertical: 3 * unit,
    horizontal: 0,
    marginRight: 5 * unit,
  },

  tabSmall: {
    vertical: 2 * unit,
    horizontal: 0,
    marginRight: 3 * unit,
  },

  tabMicro: {
    vertical: 1.5 * unit,
    horizontal: 0,
    marginRight: 2 * unit,
  },

  tabSpaced: {
    marginRightMediumAndAbove: 3 * unit,
    marginRightLargAndAbove: 4 * unit,
  },

  dualButtonBar: {
    vertical: unit,
    horizontalOutside: 2 * unit,
    horizontalInside: unit,
  },

  headerButton: {
    horizontal: 1.25 * unit,
    vertical: 0,
  },

  toggleButton: {
    marginTop: 0.5 * unit,
    marginBottom: 0.5 * unit,
    marginLeft: 0,
    marginRight: unit,
  },

  carouselNavigation: {
    horizontal: 2 * unit,
  },

  countBadge: {
    fillHorizontal: 0.25 * unit,
    outlineHorizontal: 0.5 * unit,
  },

  radioButton: {
    dotMargin: '33%',
  },

  ruleText: {
    horizontal: 2 * unit,
    vertical: 2 * unit,
  },

  select: {
    arrow: 5 * unit,
    arrowMarginTop: 2 * unit,
    arrowMarginOutside: 2 * unit,
  },

  selectSmall: {
    arrowMarginTop: 1.25 * unit,
    arrowMarginOutside: 1.75 * unit,
  },

  selectLarge: {
    arrow: 6 * unit,
    arrowMarginTop: 2.5 * unit,
    arrowMarginOutside: 2 * unit,
  },
};
