import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps, range } from 'airbnb-prop-types';
import directionPropType from 'react-with-direction/dist/proptypes/direction';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import Shimmer from '../exp/Shimmer';
import withLoading, { withLoadingPropTypes } from '../exp/withLoading';
import notNull from '../utils/propTypes/notNull';
import idProp from '../utils/propTypes/idProp';
import requiredByUnless from '../utils/propTypes/requiredByUnless';
import requiredUnless from '../utils/propTypes/requiredUnless';
import { textAlignPositionProp } from '../constants/textAlignPosition';
import {
  COLOR,
  LEADING,
  SIZE,
  TRACKING,
  WEIGHT,
  colorPropType,
  leadingPropType,
  sizePropType,
  trackingPropType,
  weightPropType,
} from '../constants/typographyConstants';
import conditionallyRestrictedProp from '../utils/propTypes/conditionallyRestrictedProp';
import { PropsType } from './types';
import { ariaCurrentType } from '../constants/ariaCurrentTypes';
// import isLetterSpacingAllowed from '../utils/isLetterSpacingAllowed';

export { COLOR, LEADING, SIZE, TRACKING, WEIGHT };

const noInlineWithTextAlign = conditionallyRestrictedProp({
  propType: PropTypes.bool,
  restrictedCondition: ({ inline, textAlign }) => !!textAlign && inline,
  restrictedMessage: (_props, propName, componentName) =>
    `${componentName}: ${propName} is forbidden to be true when textAlign is set`,
});

const noTextAlignWithInline = conditionallyRestrictedProp({
  propType: textAlignPositionProp,
  restrictedCondition: ({ inline }) => inline,
  restrictedMessage: (_props, propName, componentName) =>
    `${componentName}: ${propName} is forbidden for inline text`,
});

const MAX_LINE_CLAMP = 3;

export const typographyPropTypes = {
  allCaps: PropTypes.bool,
  ariaCurrent: ariaCurrentType,
  ariaHidden: PropTypes.bool,
  color: colorPropType,
  deprecatedSpacing: PropTypes.bool,
  dir: directionPropType, // use the `getTextDirection` util to set this prop
  htmlTitle: requiredByUnless('lineClamp', 'isLoading', PropTypes.string),
  id: idProp,
  inline: noInlineWithTextAlign,
  leading: leadingPropType,
  lineClamp: range(1, MAX_LINE_CLAMP + 1),
  noLineClampForPrint: PropTypes.bool,
  size: sizePropType,
  textAlign: noTextAlignWithInline,
  textRef: notNull(PropTypes.func),
  tracking: trackingPropType,
  weight: weightPropType,
};

export type TypographyProps = PropsType<typeof typographyPropTypes>;

export const typographyDefaultProps = {
  allCaps: false,
  ariaCurrent: undefined,
  ariaHidden: undefined,
  color: COLOR.DEFAULT,
  deprecatedSpacing: false,
  dir: undefined,
  htmlTitle: null,
  id: undefined,
  inline: false,
  leading: LEADING.DEFAULT,
  lineClamp: null,
  noLineClampForPrint: false,
  size: SIZE.REGULAR,
  textAlign: null,
  textRef() {},
  tracking: TRACKING.DEFAULT,
  weight: WEIGHT.DEFAULT,
};

const propTypes = forbidExtraProps({
  ...withLoadingPropTypes,
  ...withStylesPropTypes,
  ...typographyPropTypes,
  children: requiredUnless('isLoading', PropTypes.node),
});

const defaultProps = {
  ...typographyDefaultProps,
};

type PrivateProps = PropsType<typeof propTypes, typeof defaultProps>;

function getTag(inline: boolean | null | undefined) {
  if (inline) {
    return 'span';
  }

  return 'div';
}

// Adjusting the letter spacing in some languages can lead to sub-par UX. For example, Arabic is a
// cursive script, so letter-spacing may break calligraphic connections or cause letters to overlap
// incorrectly.
function localizeTracking(tracking: PrivateProps['tracking']) {
  // return isLetterSpacingAllowed(L10n.language()) ? tracking : TRACKING.DEFAULT;
  return tracking || TRACKING.DEFAULT;
}

function NewText({
  allCaps,
  ariaCurrent,
  ariaHidden,
  children,
  color,
  css,
  deprecatedSpacing,
  dir,
  htmlTitle,
  id,
  inline,
  isLoading,
  leading,
  lineClamp,
  noLineClampForPrint,
  size,
  styles,
  textAlign,
  textRef,
  tracking,
  weight,
}: PrivateProps) {
  const Tag = getTag(inline);
  const localizedTracking = localizeTracking(tracking);

  return (
    <Tag
      // TODO(Garrett): It would be better for components to set this further up in the tree since
      // Text is not interactive. Including this here for now for backwards compatibility.
      aria-disabled={color === COLOR.DISABLED ? true : undefined}
      aria-current={ariaCurrent != null ? ariaCurrent : undefined}
      aria-hidden={ariaHidden ? true : undefined}
      dir={dir != null ? dir : undefined}
      id={id != null ? id : undefined}
      ref={textRef}
      {...css(
        styles.text,
        styles[`fontFamily_${size}`],
        styles[`size_${size}`],
        styles[`weight_${size}_${weight}`],
        styles[`leading_${size}_${leading}`],
        styles[`tracking_${size}_${localizedTracking}`],
        styles[`color_${color}`],
        textAlign && styles[`align_${textAlign}`],
        allCaps && styles.allCaps,
        !!lineClamp && styles.lineClamp,
        !!lineClamp && styles[`lineClamp_${size}_${leading}_${lineClamp}`],
        !!lineClamp && !!noLineClampForPrint && styles.noLineClampForPrint,
        deprecatedSpacing && styles[`deprecatedSpacing_${size}`],
      )}
      title={!isLoading && htmlTitle != null ? htmlTitle : undefined}
    >
      {isLoading ? <Shimmer /> : children}
    </Tag>
  );
}

NewText.propTypes = propTypes;
NewText.defaultProps = defaultProps;

function wrapStylesInMediaQuery(styles: any, mediaQuery: any) {
  return mediaQuery ? { [mediaQuery]: styles } : styles;
}

function getTypographyStyles(typography: any, responsive: any) {
  const styleObject = {};

  const sizes = Object.keys(typography.size);
  const weights = Object.keys(typography.weight);
  const leadings = Object.keys(typography.leading);
  const trackings = Object.keys(typography.tracking);
  const lineClamps = Array.from({ length: MAX_LINE_CLAMP }, (_, index) => index + 1);

  sizes.forEach((sizeKey) => {
    const [sizeName, breakpoint] = sizeKey.split('_');
    const mediaQuery = breakpoint && responsive[breakpoint];

    // fontFamily
    const fontFamily = typography.fontFamily[sizeKey];

    if (fontFamily) {
      const fontFamilySelector = `fontFamily_${sizeName}`;
      // @ts-ignore
      styleObject[fontFamilySelector] = {
        // @ts-ignore
        ...styleObject[fontFamilySelector],
        ...wrapStylesInMediaQuery({ fontFamily }, mediaQuery),
      };
    }

    // fontSize
    const fontSize = typography.size[sizeKey];
    const sizeSelector = `size_${sizeName}`;

    // @ts-ignore
    styleObject[sizeSelector] = {
      // @ts-ignore
      ...styleObject[sizeSelector],
      ...wrapStylesInMediaQuery({ fontSize }, mediaQuery),
    };

    // fontWeight
    weights.forEach((weight) => {
      const fontWeight = typography.weight[weight][sizeKey];

      if (!fontWeight) {
        return;
      }

      const weightSelector = `weight_${sizeName}_${weight}`;
      // @ts-ignore
      styleObject[weightSelector] = {
        // @ts-ignore
        ...styleObject[weightSelector],
        ...wrapStylesInMediaQuery({ fontWeight }, mediaQuery),
      };
    });

    // lineHeight
    leadings.forEach((leading) => {
      const leadingValue = typography.leading[leading][sizeKey];

      if (!leadingValue) {
        return;
      }

      const lineHeightValue = leadingValue / fontSize;
      const lineHeight = `${lineHeightValue}em`;
      const leadingSelector = `leading_${sizeName}_${leading}`;

      // @ts-ignore
      styleObject[leadingSelector] = {
        // @ts-ignore
        ...styleObject[leadingSelector],
        ...wrapStylesInMediaQuery({ lineHeight }, mediaQuery),
      };

      // line clamping
      lineClamps.forEach((lineClamp) => {
        const lineClampSelector = `lineClamp_${sizeName}_${leading}_${lineClamp}`;
        // @ts-ignore
        styleObject[lineClampSelector] = {
          // @ts-ignore
          ...styleObject[lineClampSelector],
          ...wrapStylesInMediaQuery(
            lineClamp === 1
              ? {
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                }
              : {
                  maxHeight: `${lineHeightValue * lineClamp}em`,
                  WebkitLineClamp: lineClamp,
                  display: '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                },
            mediaQuery,
          ),
        };
      });
    });

    // letterSpacing
    trackings.forEach((tracking) => {
      const trackingValue = typography.tracking[tracking][sizeKey];

      if (!trackingValue) {
        return;
      }

      const letterSpacing = `${trackingValue / fontSize}em`;
      const trackingSelector = `tracking_${sizeName}_${tracking}`;

      // @ts-ignore
      styleObject[trackingSelector] = {
        // @ts-ignore
        ...styleObject[trackingSelector],
        ...wrapStylesInMediaQuery({ letterSpacing }, mediaQuery),
      };
    });

    // paddingTop/Bottom
    const spacing = typography.deprecatedSpacing[sizeKey];

    if (spacing) {
      const spacingSelector = `deprecatedSpacing_${sizeName}`;
      // @ts-ignore
      styleObject[spacingSelector] = {
        // @ts-ignore
        ...styleObject[spacingSelector],
        ...wrapStylesInMediaQuery(
          {
            paddingTop: spacing,
            paddingBottom: spacing,
          },
          mediaQuery,
        ),
      };
    }
  });

  return styleObject;
}

export default withStyles(({ color, responsive, __typography: typography }) => ({
  ...getTypographyStyles(typography, responsive),
  text: {
    margin: 0, // Override text margins defined by O2 or Bootstrap
    wordWrap: 'break-word',
  },

  allCaps: {
    textTransform: 'uppercase',
  },

  lineClamp: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  noLineClampForPrint: {
    [responsive.print]: {
      display: 'block',
      maxHeight: 'none',
      overflow: 'visible',
      WebkitLineClamp: 'none',
      whiteSpace: 'normal',
    },
  },

  align_start: {
    textAlign: 'left',
  },

  align_center: {
    textAlign: 'center',
  },

  align_end: {
    textAlign: 'right',
  },

  align_justify: {
    textAlign: 'justify',
  },

  color_default: {
    color: color.textDark,
  },

  color_disabled: {
    color: color.textDisabled,
  },

  color_inverse: {
    color: color.textLight,
  },

  color_muted: {
    color: color.textMuted,

    [responsive.print]: {
      color: color.printOverrides.textMuted,
    },
  },

  color_inherit: {
    color: 'inherit',
  },
}))(withLoading(NewText));
