import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { prefersReducedMotionQuery } from '../prefersReducedMotion';
import { PropsType } from '../private/types';

export const shimmerPropTypes = {
  block: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export type ShimmerProps = PropsType<typeof shimmerPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...shimmerPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to shimmerPropTypes
});

const defaultProps = {
  block: false,
  height: '1ex',
  width: '60%',
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Shimmer({ block, css, height, styles, width }: PrivateProps) {
  return (
    <span aria-busy="true" {...css(styles.shimmer, { width, height }, block && styles.block)} />
  );
}

Shimmer.propTypes = privatePropTypes;
Shimmer.defaultProps = defaultProps;

export default withStyles(
  () => ({
    block: {
      display: 'block',
    },

    shimmer: {
      animationDirection: 'alternate',
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: {
        from: { opacity: 0.1 },
        to: { opacity: 0.3 },
      },
      animationTimingFunction: 'ease-in-out',
      backgroundColor: 'currentColor',
      display: 'inline-block',
      position: 'relative',

      [prefersReducedMotionQuery]: {
        animation: 'none',
        opacity: 0.1,
      },
    },
  }),
  { pureComponent: true },
)(Shimmer);
