import { BREAKPOINT_NAMES } from '../../dls-web/size';
import { withStyles, WithStylesProps } from '../../dls-web/themes/withStyles';
import withBreakpoint, { WithBreakpointProps } from '../../dls-web/exp/withBreakpoint';
import Link from '../../dls-web/exp/Link';
import React from 'react';
import Text, { COLOR, SIZE, WEIGHT } from '../../dls-web/exp/Text';
import DisableBodyScrolling from '../../header-shared/components/DisableBodyScrolling';
import withHOCs from '../../with-hocs';

import {
  ABOUT,
  SERVICES,
  CONTACT,
} from '../constants/routeConstants';
import FlyoutMenuItemShape from '../shapes/FlyoutMenuItemShape';

type Props = {
  flyoutMenuIsOpen?: boolean;
  // isLoggedIn: boolean;
  // onLoginPress: () => void;
  // onLogoutPress: () => void;
  // onEditPaymentPress: () => void;
};

function FlyoutMenuContent({
  css,
  currentBreakpoint,
  // flyoutMenuIsOpen,
  // isLoggedIn,
  // onLoginPress,
  // onLogoutPress,
  styles,
}: Props & WithStylesProps & WithBreakpointProps) {
  // const homePageHeaderContentItem = {
  //   label: 'Home',
  //   // url: HOMEPAGE,
  //   url: '/',
  //   openInNewWindow: false,
  // };

  const aboutPageHeaderContentItem = {
    label: 'About',
    url: `/${ABOUT}`,
    openInNewWindow: false,
    // onPress: undefined,
  };

  const servicesPageHeaderContentItem = {
    label: 'Services',
    url: `/${SERVICES}`,
    openInNewWindow: false,
  };

  const contactPageHeaderContentItem = {
    label: 'Contact',
    url: `/${CONTACT}`,
    openInNewWindow: false,
    // onPress: undefined,
  };

  const headerContentList = [
    // homePageHeaderContentItem,
    aboutPageHeaderContentItem,
    servicesPageHeaderContentItem,
    contactPageHeaderContentItem,
  ];

  const footerContentList = [];

  const contentList =
    currentBreakpoint === BREAKPOINT_NAMES.SMALL
      ? headerContentList.concat(footerContentList)
      : headerContentList;

  return (
    <React.Fragment>
      <div {...css(styles.container)} tabIndex={-1} role="menu" id="flyout_menu_container">
        <ul {...css(styles.listContainer)}>
          {contentList.map(({ label, url, onPress, openInNewWindow }: FlyoutMenuItemShape) => (
            <li {...css(styles.element)} key={label}>
              {url ? (
                <Link href={url} openInNewWindow={openInNewWindow} onPress={onPress} inverse>
                  {label}
                </Link>
              ) : (
                <Text color={COLOR.INVERSE} size={SIZE.SMALL} weight={WEIGHT.LIGHTER}>
                  {label}
                </Text>
              )}
            </li>
          ))}
          {/*
            {isLoggedIn && (
              <>
                <li {...css(styles.element)} key={'Log Out'}>
                  <Link onPress={onLogoutPress} inverse>
                    {'Log Out'}
                  </Link>
                </li>
              </>
            )}
            {!isLoggedIn && (
              <React.Fragment>
                <li {...css(styles.element)} key={'Log In'}>
                  <Link onPress={onLoginPress} inverse>
                    {'Log In'}
                  </Link>
                </li>
              </React.Fragment>
            )}
            */}
        </ul>
      </div>
      <DisableBodyScrolling />
    </React.Fragment>
  );
}

export default withHOCs(
  FlyoutMenuContent,
  withStyles(({ color }) => ({
    container: {
      height: '100%',
      overflow: 'hidden',
      overflowX: 'hidden',
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
    },

    listContainer: {
      listStyleType: 'none',
      marginTop: 60,
      paddingLeft: 24,
      paddingRight: 24,
    },

    element: {
      paddingTop: 24,
      paddingBottom: 24,
      borderBottom: `1px solid ${color.accent.hrGray}`,
    },
  })),
  withBreakpoint,
);
