// DLS default theme border values

import { unit } from './size';

export default {
  button: {
    borderRadius: unit / 2,
    borderWidth: 2,
  },

  buttonSmall: {
    borderRadius: unit / 2,
    borderWidth: 1,
  },

  buttonLarge: {
    borderRadius: unit / 2,
    borderWidth: 2,
  },

  buttonRound: {
    borderRadius: '50%',
  },

  formElement: {
    borderRadius: unit / 2,
    borderWidth: 1,
  },

  statusLabel: {
    borderWidth: 1,
    borderRadius: 2,
  },

  countBadge: {
    borderRadius: 10,
    borderWidth: 1,
  },

  pager: {
    borderRadius: 0.5 * unit,
    borderWidth: 1,
  },

  profilePhoto: {
    borderWidth: 2,
    borderRadius: '50%',
  },

  progressBar: {
    borderRadiusDefault: 0,
    borderRadiusRound: 100,
    borderRadiusThick: 2,
  },

  rule: {
    borderWidth: 1,
  },

  tab: {
    borderWidth: 2,
  },

  dualButtonBar: {
    borderRadius: 10 * unit,
    dividerWidth: 1,
  },

  floatingButton: {
    borderRadius: 10 * unit,
  },

  headerButton: {
    borderRadius: 3 * unit,
  },

  checkBox: {
    borderWidth: 1,
    borderRadius: unit / 4,
  },

  switch: {
    borderWidth: 1,
    borderRadius: 4 * unit,
  },

  radioButton: {
    borderWidth: 1,
    borderRadius: '50%',
  },
};
