import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

const propTypes = forbidExtraProps({
  children: PropTypes.node,
  className: PropTypes.string,
  role: PropTypes.string,
});

const defaultProps = {
  children: undefined,
  className: undefined,
  role: undefined,
};

function HeaderNav({ children, ...otherProps }) {
  return <header {...otherProps}>{children}</header>;
}

HeaderNav.propTypes = propTypes;
HeaderNav.defaultProps = defaultProps;

export default HeaderNav;
