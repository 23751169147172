// import AirbnbUser from 'airbnb-user';
// import { handle } from 'redux-pack';

import {
  // SEARCH_INPUT_FOCUS,
  // NAVIGATION_CHANGE_COLOR_THEME,
  NAVIGATION_ITEM_SELECT,
  NAVIGATION_ITEM_DESELECT,
  // USER_LOGIN,
  // USER_LOGOUT,
  // USER_PROFILE_PICTURE_SET,
  // USER_PROFILE_PICTURE_CLEAR,
  // USER_CURRENCY_UPDATE,
  // NOTIFICATION_SET,
  // NOTIFICATION_REMOVE,
  FLYOUT_MENU_TOGGLE,
  // ACTION_NOTIFICATION_REMOVE,
  // DASHBOARD_ALERT_REMOVE,
  // MESSAGES_LOAD,
} from '../header-shared/actions';

// import { formatMessages, formatNotifications } from './store/parsers';
// import { NotificationsStatus } from './apps/Notifications/constants';

type StateProps = {
  hydrated: boolean;
  dynamicColorTheme: $TSFixMe;
  activeNavigationItem: $TSFixMe;
  flyoutMenuIsOpen: boolean;
};

const initialState = {
  // user: {
  //   isLoggedIn: false,
  //   profilePicUrl: null,
  //   name: null,
  //   currency: null,
  // },
  hydrated: false,
  dynamicColorTheme: null,
  activeNavigationItem: null,
  // navigationItemsWithNotifications: new Set(),
  flyoutMenuIsOpen: false,
  // messageNotifications: {
  //   dashboardAlertCount: 0,
  //   messages: [],
  //   notifications: [],
  //   status: NotificationsStatus.INITIAL,
  //   unreadMessageCount: 0,
  // },
};

export default function reducer(currentState: StateProps = initialState, action: $TSFixMe) {
  const { type, payload } = action;
  let state = currentState;

  // On the initial reducer pass, hydrate the store with the
  // default state defined here as well as the state passed
  // via the server.
  if (!state.hydrated) {
    state = { ...initialState, ...state, hydrated: true };
  }

  switch (type) {
    // case SEARCH_INPUT_FOCUS:
    //   return {
    //     ...state,
    //     activeNavigationItem: null,
    //   };
    // Updates the name of the active navigation item. If the action
    // is called with a payload equal to the existing value of
    // activeNavigationItem, the value is unset, allowing for easily
    // toggling navigation items on and off.
    case NAVIGATION_ITEM_SELECT:
      return {
        ...state,
        activeNavigationItem: state.activeNavigationItem === payload ? null : payload,
      };
    case NAVIGATION_ITEM_DESELECT:
      return {
        ...state,
        activeNavigationItem: null,
      };
    // case NAVIGATION_CHANGE_COLOR_THEME:
    //   return {
    //     ...state,
    //     dynamicColorTheme: payload.dynamicColorTheme,
    //   };
    // case USER_LOGIN:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       name: payload.name,
    //       profilePicUrl: payload.profilePicUrl,
    //       isHost: payload.isHost,
    //       currency: payload.currency,
    //       isLoggedIn: true,
    //       guidebooksCount: payload.guidebooksCount,
    //     },
    //     flyoutMenuIsOpen: false,
    //   };
    // case USER_LOGOUT:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       isLoggedIn: false,
    //     },
    //   };
    // case USER_PROFILE_PICTURE_SET:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       profilePicUrl: payload,
    //     },
    //   };
    // case USER_PROFILE_PICTURE_CLEAR:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       profilePicUrl: null,
    //     },
    //   };
    // case USER_CURRENCY_UPDATE:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       currency: payload,
    //     },
    //   };
    // case NOTIFICATION_SET: {
    //   const notifications = state.navigationItemsWithNotifications;
    //   if (notifications.has(payload)) {
    //     return state;
    //   }
    //
    //   notifications.add(payload);
    //
    //   return {
    //     ...state,
    //     navigationItemsWithNotifications: notifications,
    //   };
    // }
    // case NOTIFICATION_REMOVE: {
    //   const notifications = state.navigationItemsWithNotifications;
    //   if (!notifications.has(payload)) {
    //     return state;
    //   }
    //
    //   notifications.delete(payload);
    //
    //   return {
    //     ...state,
    //     navigationItemsWithNotifications: notifications,
    //   };
    // }
    case FLYOUT_MENU_TOGGLE:
      return {
        ...state,
        flyoutMenuIsOpen: !state.flyoutMenuIsOpen,
      };
    // case ACTION_NOTIFICATION_REMOVE:
    // case DASHBOARD_ALERT_REMOVE:
    //   return handle(state, action, {
    //     start(prevState) {
    //       const targetId = payload.id;
    //       const newNotifications = prevState.messageNotifications.notifications.slice();
    //       const removeIndex = newNotifications.findIndex((notif) => notif.id === targetId);
    //
    //       if (removeIndex > -1) {
    //         newNotifications.splice(removeIndex, 1);
    //       }
    //
    //       return {
    //         ...prevState,
    //         messageNotifications: {
    //           ...prevState.messageNotifications,
    //           notifications: newNotifications,
    //         },
    //       };
    //     },
    //   });
    // case MESSAGES_LOAD:
    //   return handle(state, action, {
    //     start(prevState) {
    //       return {
    //         ...prevState,
    //         messageNotifications: {
    //           ...prevState.messageNotifications,
    //           status: NotificationsStatus.LOADING,
    //         },
    //       };
    //     },
    //     success(prevState) {
    //       const { operations } = payload;
    //       if (!operations || !operations.length === 2) {
    //         return prevState;
    //       }
    //
    //       const user = AirbnbUser.current();
    //
    //       return {
    //         ...prevState,
    //         messageNotifications: {
    //           ...prevState.messageNotifications,
    //           dashboardAlertCount: user.num_alert,
    //           messages: formatMessages(operations[0].response.threads),
    //           notifications: formatNotifications(operations[1].response.dashboard_alerts),
    //           status: NotificationsStatus.SUCCESS,
    //           unreadMessageCount: user.num_msg,
    //         },
    //       };
    //     },
    //     failure(prevState) {
    //       return {
    //         ...prevState,
    //         messageNotifications: {
    //           ...prevState.messageNotifications,
    //           status: NotificationsStatus.ERROR,
    //         },
    //       };
    //     },
    //   });
    default:
      return state;
  }
}
