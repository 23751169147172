export const FONT_FAMILY =
  'Cereal,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif';

// TODO: audit these and figure out whether Cereal light is needed
const WEIGHT_BOLD = '800';
const WEIGHT_MEDIUM = '600';
const WEIGHT_BOOK = '400';

export default {
  fontFamily: {
    title1: FONT_FAMILY,
    title2: FONT_FAMILY,
    title3: FONT_FAMILY,
    title4: FONT_FAMILY,
    large: FONT_FAMILY,
    regular: FONT_FAMILY,
    small: FONT_FAMILY,
    mini: FONT_FAMILY,
    micro: FONT_FAMILY,
  },
  size: {
    title1: 38,
    title1_mediumAndAbove: 46,
    title2: 32,
    title3: 24,
    title4: 20,
    large: 18,
    regular: 16,
    small: 14,
    mini: 12,
    micro: 10,
  },
  weight: {
    lightest: {
      title1: WEIGHT_BOOK,
      title2: WEIGHT_BOOK,
      title3: WEIGHT_BOOK,
      title4: WEIGHT_BOOK,
      large: WEIGHT_BOOK,
      regular: WEIGHT_BOOK,
      small: WEIGHT_BOOK,
      mini: WEIGHT_BOOK,
      micro: WEIGHT_BOOK,
    },
    lighter: {
      title1: WEIGHT_MEDIUM,
      title2: WEIGHT_MEDIUM,
      title3: WEIGHT_BOOK,
      title4: WEIGHT_BOOK,
      large: WEIGHT_BOOK,
      regular: WEIGHT_BOOK,
      small: WEIGHT_BOOK,
      mini: WEIGHT_BOOK,
      micro: WEIGHT_BOOK,
    },
    default: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_BOOK,
      title4: WEIGHT_BOOK,
      large: WEIGHT_BOOK,
      regular: WEIGHT_BOOK,
      small: WEIGHT_BOOK,
      mini: WEIGHT_BOOK,
      micro: WEIGHT_BOOK,
    },
    bolder: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_MEDIUM,
      title4: WEIGHT_MEDIUM,
      large: WEIGHT_MEDIUM,
      regular: WEIGHT_MEDIUM,
      small: WEIGHT_MEDIUM,
      mini: WEIGHT_MEDIUM,
      micro: WEIGHT_MEDIUM,
    },
    boldest: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_BOLD,
      title4: WEIGHT_BOLD,
      large: WEIGHT_BOLD,
      regular: WEIGHT_BOLD,
      small: WEIGHT_BOLD,
      mini: WEIGHT_BOLD,
      micro: WEIGHT_BOLD,
    },
  },
  leading: {
    default: {
      title1: 44,
      title1_mediumAndAbove: 52,
      title2: 36,
      title3: 30,
      title4: 28,
      large: 26,
      regular: 22,
      small: 18,
      mini: 16,
      micro: 12,
    },
    tall: {
      title1: 50,
      title1_mediumAndAbove: 60,
      title2: 44,
      title3: 40,
      title4: 36,
      large: 30,
      regular: 28,
      small: 24,
      mini: 18,
      micro: 14,
    },
  },
  tracking: {
    default: {
      title1: 0,
      title2: 0,
      title3: 0,
      title4: 0,
      large: 0,
      regular: 0,
      small: 0,
      mini: 0,
      micro: 0,
    },
    wide: {
      title1: 1,
      title2: 1,
      title3: 1,
      title4: 1,
      large: 1,
      regular: 1,
      small: 1,
      mini: 1,
      micro: 1,
    },
  },
  deprecatedSpacing: {
    title1: 8,
    title2: 6,
    title3: 2,
    title4: 2,
  },
};
