import React from 'react';
import { compose } from 'redux';
import LazyHero from 'react-lazy-hero';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Spacing from '../../dls-web/exp/Spacing';
import Text, { SIZE, WEIGHT, COLOR } from '../../dls-web/exp/Text';
import withBreakpoint, { WithBreakpointProps } from '../../dls-web/exp/withBreakpoint';
import { PROGRESSIVE_BREAKPOINT_NAMES, matchesProgressive } from '../../dls-web/size';
import { withStyles, WithStylesProps } from '../../dls-web/themes/withStyles';

import aboutImgSrc from '../../assets/about_penslz.jpeg';
import hero from '../../assets/about_hero_yellow.jpeg';
import whoWeAreImgSrc from '../../assets/pencil.jpeg';

type Props = {
} & WithBreakpointProps & WithStylesProps;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  card: {
    minWidth: 275,
  },
}));

function AboutContent({ currentBreakpoint, css, styles }: Props) {
  const isScreenLargeAndAbove = matchesProgressive(
    currentBreakpoint,
    PROGRESSIVE_BREAKPOINT_NAMES.LARGE_AND_ABOVE,
  );

  const classes = useStyles();

  return (
    <React.Fragment>
      <LazyHero
        isCentered
        imageSrc={hero}
        opacity={0.5}
        color={'#2B2525'}
        minHeight={isScreenLargeAndAbove ? '70vh' : '60vh'}
        parallaxOffset={isScreenLargeAndAbove ? 200 : 50}
        style={{ overflow: "hidden" }}
      >
        <Text
          color={COLOR.INVERSE}
          size={isScreenLargeAndAbove ? SIZE.TITLE2 : SIZE.TITLE4}
          weight={isScreenLargeAndAbove ? WEIGHT.LIGHTER : WEIGHT.BOLDER}
        >
          <Spacing vertical={1}>
            Design stylish livable spaces
          </Spacing>
          <Spacing vertical={1}>
            that sparks joy
          </Spacing>
        </Text>
      </LazyHero>

      <Container maxWidth="sm" disableGutters>
        <div {...css(styles.insertCard)}>
          <Text size={SIZE.TITLE3} weight={WEIGHT.BOLDER}>
            About Penslz
          </Text>
          <Spacing top={2} bottom={4}>
            <Text size={SIZE.LARGE}>
              Penslz was founded with the passion to make home design joyful, yet more affordable. It's all about creating an aesthetically pleasing interior that reflects you, and excites you.
            </Text>
          </Spacing>
          <img className={classes.img} alt="aboutImgSrc" src={aboutImgSrc} />
        </div>
      </Container>

      <Container maxWidth="sm" disableGutters>
        <div {...css(styles.insertCard)}>
          <Text size={SIZE.TITLE3} weight={WEIGHT.BOLDER}>
            We are architects & interior designers
          </Text>
          <Spacing top={2} bottom={4}>
            <Text size={SIZE.LARGE}>
              We love sketching and free hand rendering, though we make vector graphics too. A pencil in hand gives creative freedom. At Penslz, we apply that to design the space you live.
            </Text>
          </Spacing>
          <img className={classes.img} alt="whoWeAreImgSrc" src={whoWeAreImgSrc} />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default compose(
  withBreakpoint,
  withStyles(() => ({
    insertCard: {
      textAlign: 'center',
      marginTop: '100px',
      marginBottom: '80px',
      marginLeft: '40px',
      marginRight: '40px',
    },
  })),
)(AboutContent);
