import PropTypes from 'prop-types';

const verticalAlignPosition = {
  BASELINE: 'baseline' as 'baseline',
  TOP: 'top' as 'top',
  MIDDLE: 'middle' as 'middle',
  BOTTOM: 'bottom' as 'bottom',
};

export const verticalAlignPositionProp = PropTypes.oneOf(Object.values(verticalAlignPosition));

export default verticalAlignPosition;
