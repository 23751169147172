import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Text, { SIZE, WEIGHT, COLOR } from '../../dls-web/exp/Text';
import Spacing from '../../dls-web/exp/Spacing';
import Card from './Card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      padding: theme.spacing(2),
      textAlign: 'center',
      // color: theme.palette.text.secondary,
    },
    card: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      // color: theme.palette.text.secondary,
    },
  }),
);

type Props = {
  sectionTitle: string,
  sectionSubtitle: string,
  grids: $TSFixMe,
};

export default function Section({
  sectionTitle,
  sectionSubtitle,
  grids,
}: Props) {
  const classes = useStyles();
  let config: boolean | "auto" | 1 | 2 | 3 | 4 | 6 | 12 | 5 | 7 | 8 | 9 | 10 | 11 | undefined = 1;
  let numOfColumns: number = grids.length;
  if (numOfColumns in [1, 2, 3, 4, 6, 12]) {
    // @ts-ignore
    config = 12 / numOfColumns;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.title} elevation={0}>
            <Text size={SIZE.TITLE3} weight={WEIGHT.BOLDER}>
              {sectionTitle}
            </Text>
            <Spacing top={2} bottom={0}>
              <Text size={SIZE.LARGE}>
                {sectionSubtitle}
              </Text>
            </Spacing>
          </Paper>
        </Grid>
        {grids.map(grid => (
          <Grid item sm={12} lg={config} md={config}>
            <Paper className={classes.card} elevation={0}>
              <Card copy={grid} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
