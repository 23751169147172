import PenslzDefaultThemeWithExternals from '../../dls-themes/themes/PenslzDefaultThemeWithExternals';
import cerealTypography from '../../dls-themes/themes/PenslzCerealTheme/typography';
import { buildFont as buildCerealFont } from '../../dls-themes/themes/PenslzCerealTheme';
import buildThemeExternals from '../utils/buildThemeExternals';

const baseTheme = PenslzDefaultThemeWithExternals;
const { FONT_FAMILY } = baseTheme.font;

const themeOverrides = {
  font: {
    ...buildCerealFont(FONT_FAMILY, baseTheme),
    FONT_FAMILY,
  },
  __typography: {
    ...cerealTypography,
    fontFamily: {
      title1: FONT_FAMILY,
      title2: FONT_FAMILY,
      title3: FONT_FAMILY,
      large: FONT_FAMILY,
      regular: FONT_FAMILY,
      small: FONT_FAMILY,
      mini: FONT_FAMILY,
      micro: FONT_FAMILY,
    },
  },
  border: baseTheme.border,
};

export default {
  ...baseTheme,
  ...themeOverrides,
  ...buildThemeExternals(baseTheme, themeOverrides),
};
