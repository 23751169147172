import { addEventListener } from 'consolidated-events';
import brcast from 'brcast';

import { breakpoints, BREAKPOINT_NAMES } from '../size';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

// @ts-ignore
// https://sourcegraph.d.musta.ch/github.com/airbnb/browser-shims@bd6b79a5737a7db52ae4d02c10c6a50413d17798/-/blob/browser-only.js
// TODO(ximin): put in a global file such as browser-shims for global initializations
global.requestIdleCallback = require('ric-shim');

const defaultBreakpoint = BREAKPOINT_NAMES.SMALL;

const currentBreakpoint = brcast<string>();

let resolveWith: (initialBreakpoint: any) => void;
let promise = new Promise((resolve) => {
  resolveWith = function resolveOriginalPromise(initialBreakpoint: any) {
    resolve(initialBreakpoint);

    resolveWith = function resolveNewPromise(breakpoint) {
      promise = Promise.resolve(breakpoint);
    };
  };
});

/*
  This module is a critical component of the Global Responsive Solution
    (https://airbnb.quip.com/hJjaAWGUAbRa)

  The goal is to provide a simple mechanism for components to access the current breakpoint in
  javascript in a reliable way. It can be used in concert with HideAt and ShowAt to remove
  children from the DOM rather than hiding them with CSS, and it can be used to replace MatchMedia
  in places where the breakpoint is needed for executing async code and other helpers.
*/

// These need to be sorted from largest to smallest.
const sortedBreakpoints = Object.entries(breakpoints).sort((a, b) => b[1] - a[1]);

// Set breakpoint and run listeners if it changed
function setCurrentBreakpoint() {
  const foundBreakpoint = sortedBreakpoints.find(
    // @ts-ignore
    ([, size]) => (global.window && global.window.innerWidth) >= size,
  );

  // fall back to small
  const nextBreakpoint = (foundBreakpoint && foundBreakpoint[0]) || defaultBreakpoint;

  if (nextBreakpoint !== currentBreakpoint.getState()) {
    currentBreakpoint.setState(nextBreakpoint);
  }

  resolveWith(nextBreakpoint);
}

let removeResizeEventListener: any;
function addListener() {
  // add event listener to window resize
  // @ts-ignore
  removeResizeEventListener = addEventListener(global.window, 'resize', setCurrentBreakpoint, {
    passive: true,
  });
}

export function getCurrentBreakpoint() {
  return currentBreakpoint.getState();
}

function ensureInitialized() {
  if (removeResizeEventListener) return;
  if (canUseDOM) {
    addListener();
  }
}

let listeners = 0;

// subscribe
export function onBreakpointChange(listener: (currentBreakpoint: any) => void) {
  ensureInitialized();

  listeners += 1;

  const unsubscribeListener = currentBreakpoint.subscribe(listener);

  return function unsubscribe() {
    listeners -= 1;

    if (listeners <= 0 && removeResizeEventListener) {
      removeResizeEventListener();
      removeResizeEventListener = null;
    }
    unsubscribeListener();
  };
}

function initializeBreakpointListeners() {
  ensureInitialized();

  if (canUseDOM) {
    setCurrentBreakpoint();
    addListener();
  }
}

// We need to wait until all hypernova trees have finished client rendering
// before we initialize breakpoint listeners, otherwise we will get a
// server/client mismatch for every tree past the first. We only need to
// initialize once, so to avoid the overhead of calling requestIdleCallback a
// bunch of times, we wrap it in this function.
let initializationEnqueued = false;
export function enqueueInitializeBreakpointListeners() {
  if (initializationEnqueued) {
    return promise;
  }
  initializationEnqueued = true;
  // @ts-ignore
  requestIdleCallback(initializeBreakpointListeners);
  return promise;
}
