import PropTypes from 'prop-types';
import { and } from 'airbnb-prop-types';
import { Props } from './types';

// See info on illegal ids:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id
const idRegex = /^_|^-|^\./;

const idProp: PropTypes.Requireable<string> = (props: Props, propName, componentName) => {
  if (idRegex.test(props[propName])) {
    return new Error(`${componentName}.${propName} must not start with "_", "-", or ".".`);
  }
  return null;
};

idProp.isRequired = (props: Props, propName, componentName, ...rest) => {
  if (!props[propName]) {
    return new Error(`Required prop \`${propName}\` was not specified in \`${componentName}\``);
  }
  return idProp(props, propName, componentName, ...rest);
};

const idPropTypes = [PropTypes.string, idProp];

export default and<string>(idPropTypes, 'idProp');
