import React, { useMemo } from 'react';
// @ts-ignore
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';
// @ts-ignore
import WithStylesDirectionAdapter from 'react-with-styles/lib/providers/WithStylesDirectionAdapter';
// @ts-ignore
import AphroditeInterface from 'react-with-styles-interface-amp-aphrodite';

type Props = {
  // @ts-ignore
  stylesTheme: $TSFixMe;
  // @ts-ignore
  stylesInterface?: $TSFixMe;
  children?: React.ReactNode;
};

/**
 * Configures react-with-styles with an interface, and theme.
 * To use, wrap your application in this provider like so:
 *
 * ```
 * <WithStylesContextProvider
 *  stylesInterface={AphroditeInterface}
 *  stylesTheme={DLSTheme}
 * >
 *  <App />
 * </WithStylesContextProvider>
 * ```
 *
 * ☝️ A note on performance:
 * Make sure that each interface and theme provided by your app is referrentially equivalent across
 * renders to prevent unnecessarily recalculating styles for components in the tree.
 *
 * @param {Props} { stylesInterface, stylesTheme, children }
 */
function WithStylesContextProvider({
  stylesInterface: providedInterface,
  stylesTheme,
  children,
}: Props) {
  const stylesInterface = providedInterface || AphroditeInterface;
  const context = useMemo(() => ({ stylesInterface, stylesTheme }), [stylesInterface, stylesTheme]);

  return (
    <WithStylesContext.Provider value={context}>
      <WithStylesDirectionAdapter>{children}</WithStylesDirectionAdapter>
    </WithStylesContext.Provider>
  );
}

export default WithStylesContextProvider;
