import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps, requiredBy } from 'airbnb-prop-types';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import notNull from '../utils/propTypes/notNull';
import visuallyHidden from '../utils/visuallyHidden';

import { PropsType } from '../private/types';

export const accessibleTextPropTypes = {
  ariaAtomic: PropTypes.bool,
  /**
   * Sets the aria-hidden HTML attribute - useful for when the text
   * needs to be referenced by id, but not read in the flow of the
   * page. Requires id to be present to be used
   */
  ariaHidden: PropTypes.bool,

  ariaLive: PropTypes.oneOf(['polite' as 'polite', 'assertive' as 'assertive']),

  hasBlockChildren: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /**
   * HTML id for the element - useful for aria references
   *
   * if `aria-hidden` is `true`, "id" is required.
   */
  id: requiredBy('ariaHidden', PropTypes.string),
  contentRef: notNull(PropTypes.func),
};

export type AccessibleTextProps = PropsType<typeof accessibleTextPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...accessibleTextPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to accessibleTextPropTypes
});

const defaultProps = {
  ariaAtomic: null,
  ariaHidden: null, // this must be `null` or `undefined` so `aria-hidden` doesn't render when false
  ariaLive: null, // use the browser default, which is `off`
  hasBlockChildren: false,
  id: null,
  contentRef() {},
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

/**
 * AccessibleText creates a text block that is only visible to
 * screen readers and not visible to sighted users.
 *
 * AccessibleText should only be used where other attributes or
 * elements are insufficient for screen readers. It should also
 * only be used in places where the text shown is not necessary
 * for sighted users. In cases where the text would be good for
 * sighted users as well, that text should just be visible.
 */
function AccessibleText({
  css,
  ariaAtomic,
  ariaHidden,
  ariaLive,
  hasBlockChildren,
  children,
  contentRef,
  id,
  styles,
}: PrivateProps) {
  const Tag = hasBlockChildren ? 'div' : 'span';

  return (
    <Tag
      id={id != null ? id : undefined}
      aria-atomic={ariaAtomic ? true : undefined}
      aria-hidden={ariaHidden ? true : undefined}
      aria-live={ariaLive != null ? ariaLive : undefined}
      ref={contentRef}
      {...css(styles.accessible)}
    >
      {children}
    </Tag>
  );
}

AccessibleText.propTypes = privatePropTypes;
AccessibleText.defaultProps = defaultProps;

export default withStyles(
  () => ({
    accessible: {
      ...visuallyHidden,
    },
  }),
  { pureComponent: true },
)(AccessibleText);
