import { applyMiddleware } from 'redux';
import { createInjectableStore } from 'redux-injectable-store';
import thunk from 'redux-thunk';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { composeWithDevTools } from 'redux-devtools-extension';
import deepmerge from 'deepmerge';

const initialState = {};

// The name of the action to be called when injecting and replacing a
// reducer's state.
export const REDUCER_HYDRATE_STATE = 'airbnb/global/REDUCER_HYDRATE_STATE';

// Create an enhancer with the following:
//   - Middleware
//     - Thunk: return function-based actions from action creators
//       - https://github.com/gaearon/redux-thunk
//     - Redux Pack: declarative promise handling for reducers
//        - https://github.com/lelandrichardson/redux-pack
//   - DevTools: provides debugging tools via Redux DevTools in Chrome console
//     - http://extension.remotedev.io/
// We blacklist certain action/state keys with large payloads below to avoid crashing the extension.
// This is necessary if you store non-serializable data in Redux (e.g, DOM nodes)
const BLACKLISTED_ACTIONS = [
  '',
  // 'airbnb/global/REDUCER_HYDRATE_STATE',
  // 'airbnb/p3/navigation/SET_SECTION_REF',
  // 'airbnb/p3/navigation/SET_SECTION_GROUP_REF',
];

const BLACKLISTED_STATE = ['homePDP.navigation'];

const composeEnhancers = composeWithDevTools({
  name: 'Penslz',
  actionSanitizer: (action: $TSFixMe) =>
    BLACKLISTED_ACTIONS.includes(action.type)
      ? { ...action, payload: `${action.type} is blacklisted` }
      : action,
  stateSanitizer: (state: $TSFixMe) =>
    BLACKLISTED_STATE.reduce((sanitized, path) => {
      const keys = path.split('.');
      let root = sanitized;
      let key = keys.shift();
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      while (key in root && keys.length > 0) {
        // @ts-ignore ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        root = root[key];
        key = keys.shift();
      }

      if (keys.length === 0) {
        // @ts-ignore ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
        root[key] = 'state is blacklisted';
      }
      return sanitized;
    }, state),
});

const getEnhancers = (customEnhancers = []) =>
  composeEnhancers(applyMiddleware(thunk, reduxPackMiddleware, ...customEnhancers));

// @ts-ignore ts-migrate(6133) FIXME: 'destinationArray' is declared but its value is ne... Remove this comment to see the full error message
const overwriteMerge = (destinationArray: $TSFixMe, sourceArray: $TSFixMe) => sourceArray;

// All actions will pass through the hydrateStateReducer first, allowing
// us to handle certain actions at a global level.
const hydrateStateReducer = (reducer: $TSFixMe) => (state: $TSFixMe, action: $TSFixMe) => {
  const { type, payload } = action;

  switch (type) {
    case REDUCER_HYDRATE_STATE: {
      const stateToHydrate = payload;

      // Given a payload, update the equivalent top-level key
      // in the state tree, merging that key's existing state with that passed in
      // the action payload. Merging, rather than replacing, allows us to merge a
      // reducer's default state with state being hydrated from the server.
      //
      // We do a deep merge for the same reasons as above.
      return deepmerge(state, stateToHydrate, { arrayMerge: overwriteMerge });
    }
    default:
      return reducer(state, action);
  }
};

// Create a variable to hold the singleton store instance
let store: $TSFixMe;

// Dispatches an action which is handled by hydrateStateReducer, defined above.
// The method accepts two arguments: the namespace of the state tree
// that will be replaced; and the state object that will be used to
// replace the provided namespace.
export function hydrateReducerState(state: $TSFixMe) {
  if (!store) return;

  store.dispatch({
    type: REDUCER_HYDRATE_STATE,
    payload: state,
  });
}

export default function createStore(forceCreate = false, options = {}) {
  // On the client side of things, we want to return the singleton
  // store that we've already created (if we already have).
  if (typeof document !== 'undefined' && store && !forceCreate) {
    return store;
  }

  // @ts-ignore ts-migrate(2339) FIXME: Property 'customEnhancers' does not exist on type ... Remove this comment to see the full error message
  const { customEnhancers = [] } = options;

  // On the server (or for the first call to this function on the client) we
  // want to create (and return) a new store each time.
  // @ts-ignore
  store = createInjectableStore(initialState, getEnhancers(customEnhancers), hydrateStateReducer);

  return store;
}
