import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from "react-router-dom";
import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux';
import configureStore from './configureStore';

import routes from './routes';
import './index.css';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {renderRoutes(routes)}
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
