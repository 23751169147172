import React from 'react';
import LazyHero from 'react-lazy-hero';
import Container from '@material-ui/core/Container';

import Spacing from '../../dls-web/exp/Spacing';
import Text, { SIZE, WEIGHT, COLOR } from '../../dls-web/exp/Text';
import { PROGRESSIVE_BREAKPOINT_NAMES, matchesProgressive } from '../../dls-web/size';
import withBreakpoint, { WithBreakpointProps } from '../../dls-web/exp/withBreakpoint';

import designImgSrc from '../../assets/design.jpg';
import hero from '../../assets/p1_hero_2.jpeg';
import shopImgSrc from '../../assets/p1_hero_1.jpg';

import Section from './Section';

type Props = WithBreakpointProps;

function P1Content({ currentBreakpoint }: Props) {
  const isScreenLargeAndAbove = matchesProgressive(
    currentBreakpoint,
    PROGRESSIVE_BREAKPOINT_NAMES.LARGE_AND_ABOVE,
  );

  return (
    <div>
      <LazyHero
        imageSrc={hero}
        opacity={0}
        minHeight={isScreenLargeAndAbove ? '75vh' : '58vh'}
        parallaxOffset={100}
      >
        <Spacing top={10}>
          <Text
            color={COLOR.INVERSE}
            size={isScreenLargeAndAbove ? SIZE.TITLE2 : SIZE.TITLE4}
            weight={isScreenLargeAndAbove ? WEIGHT.DEFAULT : WEIGHT.BOLDER}
          >
            Home design that brings the joy of best living.
          </Text>
        </Spacing>
      </LazyHero>

      <Spacing top={6}>
        <Container maxWidth="lg">
          <Section
            sectionTitle={'Flexible Plans'}
            sectionSubtitle={''}
            grids={
              [
                {
                  highlight: 'Cost saving',
                  title: 'Shop with us',
                  subtitle: 'You did the work, we help you save',
                  body: 'Enjoy exclusive discounts on furniture, lighting, home decor, window treatments, appliances you picked.',
                  imgSrc: shopImgSrc,
                  quote: '"best for those who already know what to get"',
                },
                {
                  highlight: 'Redefine the space',
                  title: 'Room design',
                  subtitle: 'We guide you through the process',
                  body: 'We design the space thoughtfully that will reflect you and excite you, with our curated design picks.',
                  imgSrc: designImgSrc,
                  quote: '"inspiring design with budget well managed"',
                },
              ]
            }
          />
        </Container>
      </Spacing>
    </div>
  );
}

export default withBreakpoint(P1Content);
