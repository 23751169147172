// This higher-order-function consumes a string from React context that is
// provided by the BreakpointProvider component (or top level components).
// We can use this to server render based on a breakpoint, if present.
// This HOC was developed to be used to wrap ShowAt/HideAt

import PropTypes from 'prop-types';
// @ts-ignore
import getComponentName from 'airbnb-prop-types/build/helpers/getComponentName';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { CHANNEL } from './BreakpointProvider';
import brcastShape from '../shapes/brcast';
import {
  onBreakpointChange,
  enqueueInitializeBreakpointListeners,
} from '../utils/getCurrentBreakpoint';
import {
  deprecatedBreakpointFromBreakpoint,
  progressiveBreakpointsFromBreakpoint,
  ProgressiveBreakpoints,
  PROGRESSIVE_BREAKPOINT_NAMES,
  PROGRESSIVE_BREAKPOINTS_UNKNOWN,
  BreakpointName,
} from '../size';
import { Omit, ElementConfig } from '../private/types';

const contextTypes = {
  [CHANNEL]: brcastShape,
};

export const withBreakpointDefaultProps = {
  currentBreakpoint: null,
  breakpoints: PROGRESSIVE_BREAKPOINTS_UNKNOWN,
};

export const withBreakpointPropTypes = {
  currentBreakpoint: PropTypes.string,
  breakpoints: PropTypes.shape(
    Object.values(PROGRESSIVE_BREAKPOINT_NAMES).reduce(
      (acc, name) => ({
        ...acc,
        [name]: PropTypes.bool.isRequired,
      }),
      { isBreakpointKnown: PropTypes.bool.isRequired },
    ),
  ),
};

export type WithBreakpointProps = {
  currentBreakpoint?: BreakpointName | null;
  breakpoints: ProgressiveBreakpoints;
};

/* eslint-disable react/forbid-foreign-prop-types */
export default function withBreakpoint<C extends React.ComponentType<any>>(
  WrappedComponent: C,
  { pureComponent = false } = {},
) {
  type ComponentProps = ElementConfig<C>;
  type Props = Omit<ComponentProps, keyof WithBreakpointProps>;

  type State = {
    currentBreakpoint?: BreakpointName | null;
  };

  const wrappedComponentName = getComponentName(WrappedComponent) || 'Component';

  const ComponentClass = pureComponent ? React.PureComponent : React.Component;

  /* @extends React.Component */
  class WithBreakpoint extends ComponentClass<Props, State> {
    static WrappedComponent = WrappedComponent;

    static contextTypes = contextTypes;

    static displayName = `withBreakpoint(${wrappedComponentName})`;

    static propTypes: PropTypes.ValidationMap<Props>;

    static defaultProps: Partial<Props>;

    constructor(props: Props, context?: any) {
      super(props, context);
      this.state = {
        currentBreakpoint: context[CHANNEL] ? context[CHANNEL].getState() : null,
      };
    }

    componentDidMount() {
      // we check to see if the channel is set in case withBreakpoint is being used
      // without BreakpointProvider
      if (this.context[CHANNEL]) {
        // subscribe to future breakpoint changes
        this.channelUnsubscribe = this.context[CHANNEL].subscribe((currentBreakpoint: any) => {
          this.setState({ currentBreakpoint });
        });
      } else {
        // if BreakpointProvider is not being used, subscribe to the breakpoint change itself
        this.breakpointChangeUnsubscribe = onBreakpointChange((currentBreakpoint) => {
          this.setState({ currentBreakpoint });
        });

        enqueueInitializeBreakpointListeners();
      }
    }

    componentWillUnmount() {
      if (this.channelUnsubscribe) {
        this.channelUnsubscribe();
      }
      if (this.breakpointChangeUnsubscribe) {
        this.breakpointChangeUnsubscribe();
      }
    }

    channelUnsubscribe?: () => void;

    breakpointChangeUnsubscribe?: () => void;

    render() {
      const { currentBreakpoint } = this.state;

      return (
        // @ts-ignore TS2322: Type '...' is not assignable to type 'LibraryManagedAttributes<C, any>'.
        <WrappedComponent
          {...this.props}
          currentBreakpoint={deprecatedBreakpointFromBreakpoint(currentBreakpoint)}
          breakpoints={progressiveBreakpointsFromBreakpoint(currentBreakpoint)}
        />
      );
    }
  }

  if (WrappedComponent.propTypes) {
    const { currentBreakpoint, breakpoints, ...restPropTypes } = WrappedComponent.propTypes;
    WithBreakpoint.propTypes = restPropTypes as PropTypes.ValidationMap<Props>;
  }

  if (WrappedComponent.defaultProps) {
    const { currentBreakpoint, breakpoints, ...restDefaultProps } = WrappedComponent.defaultProps;
    WithBreakpoint.defaultProps = restDefaultProps as Partial<Props>;
  }

  // Explicitly define return type to avoid `[ts] Return type of exported function has or is using private name 'WithBreakpoint'.` error
  // TODO: think of better workaround
  type WithBreakpointComponentType = React.ComponentClass<Props> & {
    WrappedComponent: React.ComponentType<ComponentProps>;
  };

  return hoistNonReactStatics(WithBreakpoint, WrappedComponent) as WithBreakpointComponentType;
}

export function withBreakpointPure<C extends React.ComponentType<any>>(WrappedComponent: C) {
  return withBreakpoint(WrappedComponent, { pureComponent: true });
}
