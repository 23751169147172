import ReactDatesDefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import RheostatDefaultTheme from 'rheostat/lib/themes/DefaultTheme';
import PenslzDefaultTheme from './PenslzDefaultTheme';

export default {
  ...PenslzDefaultTheme,
  ...ReactDatesDefaultTheme,
  ...RheostatDefaultTheme,
  reactDates: {
    ...ReactDatesDefaultTheme.reactDates,
    color: {
      ...ReactDatesDefaultTheme.reactDates.color,
      border: PenslzDefaultTheme.color.inputBorder,
      core: {
        ...ReactDatesDefaultTheme.reactDates.color.core,
        border: PenslzDefaultTheme.color.inputBorder,
      },
    },
  },
};
