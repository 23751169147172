// @preval

// NOTE: We use preval to prevent calculating all of these values at runtime.
// This must remain in CommonJS module syntax for it to work with the preval
// plugin.
import penmojis from './penmojis.json';
require('../private/babelHelpersForPreval');

export const PENMOJI_MAPPING = penmojis.reduce((carry, moji) => {
  const codePoint = parseInt(moji.charCode, 16);
  return {
    ...carry,
    [moji.serverName]: String.fromCodePoint(codePoint),
  };
}, {});

export const PENMOJIS = penmojis.reduce((carry, moji) => {
  const { serverName, humanReadableName } = moji;
  return {
    ...carry,
    [humanReadableName]: serverName,
  };
}, {});
