// REVIEWERS: adam-neary, joe-lencioni
import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import progressiveBreakpoint from '../utils/propTypes/progressiveBreakpoint';
import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import {
  withBreakpointPure,
  withBreakpointPropTypes,
  withBreakpointDefaultProps,
} from './withBreakpoint';
import { MEDIUM_AND_ABOVE, LARGE_AND_ABOVE, XLARGE_AND_ABOVE } from '../constants/progressives';
import isAmp from '../utils/isAmp';
import { PropsType } from '../private/types';

export const showAtPropTypes = {
  breakpoint: progressiveBreakpoint.isRequired,
  children: PropTypes.node,
  inline: PropTypes.bool,
};

export type ShowAtProps = PropsType<typeof showAtPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...showAtPropTypes,
  ...withStylesPropTypes,
  ...withBreakpointPropTypes,
  // NOTE: Add public props to showAtPropTypes
});

const defaultProps = {
  ...withBreakpointDefaultProps,
  children: undefined,
  inline: false,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

/*
  The goal is this component is to provide a simple API for hiding and showing content at different
  responsive breakpoint thresholds, even if the implementation under the hood becomes increasingly
  more robust/desirable.

  - [x] Phase 1: Use responsive styles to hide and show (even though this means children will be in
          the DOM!)
  - [x] Phase 2: Use breakpoints to add/remove from DOM, listening to resize events
  - [ ] Phase 3: If you're using BreakpointProvider, you're nearly at phase 3. Build provider
          component to set context for server rendering, and have monorail infer screen size from
          User Agent, making best guess and rendering accordingly. The implementation for such a
          feature would probably make sense as a global store (flux or otherwise), but the
          specifics of the implementation can wait until this is tackled.

  This component complements HideAt.
*/
function ShowAt({ css, breakpoints, breakpoint, inline, children, styles }: PrivateProps) {
  if (
    isAmp() &&
    (breakpoint === MEDIUM_AND_ABOVE ||
      breakpoint === LARGE_AND_ABOVE ||
      breakpoint === XLARGE_AND_ABOVE)
  ) {
    return null;
  }

  if (breakpoints && breakpoints.isBreakpointKnown) {
    // Check to see if we should show the thing.
    // @ts-ignore
    if (!breakpoints[breakpoint]) {
      return null;
    }

    // TODO React.Children.only and remove the wrapping element. But first we
    // need to remove the wrapping element from the initial server-rendered
    // version so that the styles and markup match after mounting.
    return <div {...css(inline && styles.inlineBlock)}>{children}</div>;
  }

  // This is for the initial server-render only. This will disappear once we
  // can know in JavaScript what breakpoint we are currently at.
  let breakpointStyle;
  if (inline && breakpoint === MEDIUM_AND_ABOVE) {
    breakpointStyle = styles.mediumAndAbove_inline;
  } else if (inline && breakpoint === LARGE_AND_ABOVE) {
    breakpointStyle = styles.largeAndAbove_inline;
  } else if (inline && breakpoint === XLARGE_AND_ABOVE) {
    breakpointStyle = styles.xlargeAndAbove_inline;
  } else if (breakpoint === MEDIUM_AND_ABOVE) {
    breakpointStyle = styles.mediumAndAbove;
  } else if (breakpoint === LARGE_AND_ABOVE) {
    breakpointStyle = styles.largeAndAbove;
  } else if (breakpoint === XLARGE_AND_ABOVE) {
    breakpointStyle = styles.xlargeAndAbove;
  }

  return <div {...css(styles.container, breakpointStyle)}>{children}</div>;
}

ShowAt.propTypes = privatePropTypes;
ShowAt.defaultProps = defaultProps;

export default withBreakpointPure(
  withStyles(({ responsive }) => ({
    inlineBlock: {
      display: 'inline-block',
    },

    container: {
      display: 'none',
    },

    mediumAndAbove: {
      [responsive.mediumAndAbove]: {
        display: 'block',
      },
    },

    largeAndAbove: {
      [responsive.largeAndAbove]: {
        display: 'block',
      },
    },

    xlargeAndAbove: {
      [responsive.xlargeAndAbove]: {
        display: 'block',
      },
    },

    mediumAndAbove_inline: {
      [responsive.mediumAndAbove]: {
        display: 'inline-block',
      },
    },

    largeAndAbove_inline: {
      [responsive.largeAndAbove]: {
        display: 'inline-block',
      },
    },

    xlargeAndAbove_inline: {
      [responsive.xlargeAndAbove]: {
        display: 'inline-block',
      },
    },
  }))(ShowAt),
);
