import { responsive } from './size';
import DLSColors from './color';

const WEIGHT_BOLD = '700';
const WEIGHT_BOOK = 'normal';
const WEIGHT_LIGHT = '300';

const FONT_FAMILY_AIRMOJI_STANDALONE = 'Airmoji_Standalone';

type StyleValue = number | string;

interface TextSizeStyles {
  fontSize: StyleValue;
  lineHeight: StyleValue;
  letterSpacing?: StyleValue;
}

interface FontStyles extends TextSizeStyles {
  color: string;
  fontFamily: StyleValue;
  offset?: StyleValue;
  textTransform?: StyleValue;
}

function textSize({ fontSize, lineHeight, letterSpacing }: TextSizeStyles) {
  return {
    fontSize,
    lineHeight: `${lineHeight}px`,
    letterSpacing,
  };
}

function font({ fontFamily, fontSize, lineHeight, offset, letterSpacing, color }: FontStyles) {
  const paddingTop = offset;
  const paddingBottom = offset;
  return {
    fontFamily,
    ...textSize({ fontSize, lineHeight, letterSpacing }),
    paddingTop,
    paddingBottom,
    color,
  };
}

function isCircular(family: string) {
  return family.startsWith('Circular');
}

export default function buildFont(family: string, color = DLSColors.core.hof) {
  const name = {
    // header
    title1: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 48,
        lineHeight: 56,
        offset: 8,
        letterSpacing: -0.8,
      }),
      [responsive.small]: textSize({
        fontSize: 40,
        lineHeight: 48,
        letterSpacing: -0.8,
      }),
    },

    title2: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 36,
        lineHeight: 40,
        offset: 6,
        letterSpacing: -0.6,
      }),
      [responsive.small]: textSize({
        fontSize: 32,
        lineHeight: 40,
        letterSpacing: -0.6,
      }),
    },

    title3: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 28,
        lineHeight: 32,
        offset: 2,
        letterSpacing: -0.6,
      }),
      [responsive.small]: textSize({
        fontSize: 25,
        lineHeight: 30,
        letterSpacing: -0.6,
      }),
    },

    title4: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 22,
        lineHeight: 32,
        offset: 2,
        letterSpacing: -0.6,
      }),
      [responsive.small]: textSize({
        fontSize: 22,
        lineHeight: 22,
        letterSpacing: -0.6,
      }),
    },

    textLarge: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 22,
        lineHeight: 28,
        offset: 0,
        letterSpacing: -0.2,
      }),
    },

    textLargeShort: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 22,
        lineHeight: 24,
        offset: 0,
        letterSpacing: -0.2,
      }),
    },

    textLargeTall: font({
      fontFamily: family,
      color,
      fontSize: 22,
      lineHeight: 32,
      offset: 0,
      letterSpacing: -0.2,
    }),

    textRegular: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 19,
        lineHeight: 24,
        offset: 0,
      }),
    },

    textRegularShort: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 19,
        lineHeight: 22,
        offset: 0,
      }),
    },

    textRegularTall: font({
      fontFamily: family,
      color,
      fontSize: 19,
      lineHeight: 28,
      offset: 0,
    }),

    textSmall: {
      ...font({
        fontFamily: family,
        color,
        fontSize: 15,
        lineHeight: 18,
        offset: 0,
        letterSpacing: 0.2,
      }),
    },

    textMicro: font({
      fontFamily: family,
      color,
      fontSize: 12,
      lineHeight: 16,
      offset: 0,
      letterSpacing: 0.2,
    }),

    textMicroWide: font({
      fontFamily: family,
      color,
      fontSize: 12,
      lineHeight: 16,
      offset: 0,
      letterSpacing: 1,
    }),

    // label
    label1: font({
      fontFamily: family,
      color,
      fontSize: 19,
      lineHeight: 20,
      offset: 0,
      letterSpacing: 0.5,
    }),

    label2: font({
      fontFamily: family,
      color,
      fontSize: 17,
      lineHeight: 22,
    }),

    label3: font({
      fontFamily: family,
      color,
      fontSize: 14,
      lineHeight: 16,
      offset: 3,
    }),

    label4: font({
      fontFamily: family,
      color,
      fontSize: 12,
      lineHeight: 16,
      offset: 0,
      letterSpacing: -0.5,
    }),

    small: font({
      fontFamily: family,
      color,
      fontSize: 14,
      lineHeight: 18,
    }),

    // forms,
    formLabel: font({
      fontFamily: family,
      color,
      fontSize: 19,
      lineHeight: 24,
    }),

    formElement: font({
      fontFamily: family,
      color,
      fontSize: 19,
      lineHeight: 27,
    }),

    formElementSmall: font({
      fontFamily: family,
      color,
      fontSize: 15,
      lineHeight: 22,
    }),

    formElementLarge: font({
      fontFamily: family,
      color,
      fontSize: 22,
      lineHeight: 30,
    }),

    formErrorMessage: font({
      fontFamily: family,
      color,
      fontSize: 15,
      lineHeight: 24,
    }),

    airmoji: {
      fontFamily: isCircular(family) ? family : FONT_FAMILY_AIRMOJI_STANDALONE,
      fontWeight: 'normal',
    },

    textReduced: {}, // TODO(Nora Tarano): Remove this after Cereal experiment

    link: {
      textDecoration: 'none',
      textDecorationHover: 'underline',
      textDecorationFocus: 'underline',
      textDecorationDisabled: 'none',
      textDecorationMono: 'underline',
      textDecorationUnderline: 'underline',
    },
  };

  const alias = {
    // @ts-ignore ts-migrate(2339) FIXME: Property 'body2' does not exist on type '{ title1:... Remove this comment to see the full error message
    default: name.body2,
    // @ts-ignore ts-migrate(2339) FIXME: Property 'header2' does not exist on type '{ title... Remove this comment to see the full error message
    hero: name.header2,
    navigation: name.label2,
    // @ts-ignore ts-migrate(2339) FIXME: Property 'body1' does not exist on type '{ title1:... Remove this comment to see the full error message
    large: name.body1,
    small: name.small,
    formInput: name.formElement,
    button: name.formElement,
    buttonSmall: name.formElementSmall,
    buttonLarge: name.formElementLarge,
  };

  const weights = {
    light: {
      fontWeight: WEIGHT_LIGHT,
    },
    book: {
      fontWeight: WEIGHT_BOOK,
    },
    bold: {
      fontWeight: WEIGHT_BOLD,
    },
  };

  return {
    ...name,
    ...alias,
    ...weights,
  };
}
