import PropTypes from 'prop-types';

export enum WEIGHT {
  LIGHTEST = 'lightest',
  LIGHTER = 'lighter',
  DEFAULT = 'default',
  BOLDER = 'bolder',
  BOLDEST = 'boldest',
}

export enum SIZE {
  TITLE1 = 'title1',
  TITLE2 = 'title2',
  TITLE3 = 'title3',
  TITLE4 = 'title4',
  LARGE = 'large',
  REGULAR = 'regular',
  SMALL = 'small',
  MINI = 'mini',
  MICRO = 'micro',
}

export enum LEADING {
  DEFAULT = 'default',
  TALL = 'tall',
}

export enum COLOR {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  MUTED = 'muted',
  INHERIT = 'inherit',
  INVERSE = 'inverse',
}

export enum TRACKING {
  DEFAULT = 'default',
  WIDE = 'wide',
}

export const sizePropType = PropTypes.oneOf(Object.values(SIZE));
export const leadingPropType = PropTypes.oneOf(Object.values(LEADING));
export const weightPropType = PropTypes.oneOf(Object.values(WEIGHT));
export const colorPropType = PropTypes.oneOf(Object.values(COLOR));
export const trackingPropType = PropTypes.oneOf(Object.values(TRACKING));
