import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

// Since we are only adding variable definitions to our styles, we have
// no need for the contextual create or resolve methods that are leveraged
// for RTL support. Hence, we use these global imports here.
// eslint-disable-next-line no-restricted-imports
import { ThemedStyleSheet, css } from '../themes/withStyles';
import { PropsType } from './types';

const propTypes = forbidExtraProps({
  children: PropTypes.node,
  componentID: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
  fillContainer: PropTypes.bool,
  inline: PropTypes.bool,
  styleMapping: PropTypes.object,
});

const defaultProps = {
  children: null,
  customStyles: null,
  fillContainer: false,
  inline: false,
  styleMapping: {},
};

type Props = PropsType<typeof propTypes>;

type PrivateProps = PropsType<typeof propTypes, typeof defaultProps>;

function CustomStylesInjector({
  children,
  componentID,
  customStyles,
  fillContainer,
  inline,
  styleMapping,
}: PrivateProps) {
  if (!customStyles || !Object.keys(customStyles).length) return children;

  const styles = ThemedStyleSheet.create(() => ({
    [componentID]: Object.fromEntries(
      // @ts-ignore
      Object.entries(customStyles)
        // @ts-ignore
        .map(([key, val]) => [styleMapping[key], val])
        .filter(([key]) => !!key),
    ),
  }))();

  return (
    <div
      {...css(
        styles[componentID],
        fillContainer && { height: '100%', width: '100%' },
        inline && { display: 'inline-block' },
      )}
    >
      {children}
    </div>
  );
}

CustomStylesInjector.propTypes = propTypes;
CustomStylesInjector.defaultProps = defaultProps;

export default CustomStylesInjector as React.SFC<Props>;
