// This component renders a stretchy middle section, flanked by optional
// non-stretchy sides, all middle-aligned.

import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { PropsType } from '../private/types';

export const flexBarPropTypes = {
  after: PropTypes.node,
  afterNoWrap: PropTypes.bool,
  align: PropTypes.oneOf(['top', 'middle', 'bottom']),
  before: PropTypes.node,
  beforeNoWrap: PropTypes.bool,
  children: PropTypes.node,
  fillVertical: PropTypes.bool,
  useFlex: PropTypes.bool,
};

export type FlexBarProps = PropsType<typeof flexBarPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...flexBarPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to flexBarPropTypes
});

const defaultProps = {
  after: null,
  afterNoWrap: false,
  align: 'middle',
  before: null,
  beforeNoWrap: false,
  children: null,
  fillVertical: false,
  useFlex: false,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function FlexBar({
  css,
  after,
  afterNoWrap,
  align,
  before,
  beforeNoWrap,
  children,
  fillVertical,
  styles,
  useFlex,
}: PrivateProps) {
  return (
    <div
      {...css(
        useFlex ? styles.flexContainer : styles.container,
        fillVertical && styles.fillVertical,
      )}
    >
      {before && (
        <div
          {...css(
            !useFlex && styles.child,
            align === 'top' && (useFlex ? styles.child_alignTop_flex : styles.child_alignTop),
            align === 'middle' &&
              (useFlex ? styles.child_alignMiddle_flex : styles.child_alignMiddle),
            align === 'bottom' &&
              (useFlex ? styles.child_alignBottom_flex : styles.child_alignBottom),
            beforeNoWrap && styles.child_noWrap,
          )}
        >
          {before}
        </div>
      )}

      <div
        {...css(
          !useFlex && styles.child,
          styles.child_middle,
          align === 'top' && (useFlex ? styles.child_alignTop_flex : styles.child_alignTop),
          align === 'middle' &&
            (useFlex ? styles.child_alignMiddle_flex : styles.child_alignMiddle),
          align === 'bottom' &&
            (useFlex ? styles.child_alignBottom_flex : styles.child_alignBottom),
        )}
      >
        {children}
      </div>

      {after && (
        <div
          {...css(
            !useFlex && styles.child,
            align === 'top' && (useFlex ? styles.child_alignTop_flex : styles.child_alignTop),
            align === 'middle' &&
              (useFlex ? styles.child_alignMiddle_flex : styles.child_alignMiddle),
            align === 'bottom' &&
              (useFlex ? styles.child_alignBottom_flex : styles.child_alignBottom),
            afterNoWrap && styles.child_noWrap,
          )}
        >
          {after}
        </div>
      )}
    </div>
  );
}

FlexBar.propTypes = privatePropTypes;
FlexBar.defaultProps = defaultProps;

export default withStyles(() => ({
  container: {
    display: 'table',
    width: '100%',
    borderSpacing: 0,
  },

  flexContainer: {
    display: 'flex',
    width: '100%',
    borderSpacing: 0,
  },

  child: {
    display: 'table-cell',
  },

  child_middle: {
    width: '100%',
  },

  child_alignTop_flex: {
    alignSelf: 'initial',
  },

  child_alignTop: {
    verticalAlign: 'top',
  },

  child_alignMiddle: {
    verticalAlign: 'middle',
  },

  child_alignMiddle_flex: {
    alignSelf: 'center',
  },

  child_alignBottom: {
    verticalAlign: 'bottom',
  },

  child_alignBottom_flex: {
    alignSelf: 'flex-end',
  },

  child_noWrap: {
    whiteSpace: 'nowrap',
  },

  fillVertical: {
    height: '100%',
  },
}))(FlexBar);
