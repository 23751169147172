export const FLYOUT_MENU_TOGGLE = 'header/FLYOUT_MENU_TOGGLE';
export const NAVIGATION_ITEM_SELECT = 'header/NAVIGATION_ITEM_SELECT';
export const NAVIGATION_ITEM_DESELECT = 'header/NAVIGATION_ITEM_DESELECT';

// ----------------------------------------------------------------------------
// Element visibility
// ----------------------------------------------------------------------------

export function toggleFlyoutMenu() {
  return {
    type: FLYOUT_MENU_TOGGLE,
  };
}

// ----------------------------------------------------------------------------
// Navigation Items + Search Field
// ----------------------------------------------------------------------------

export function selectNavigationItem(payload: $TSFixMe) {
  return {
    type: NAVIGATION_ITEM_SELECT,
    payload,
  };
}

export function deselectNavigationItem() {
  return {
    type: NAVIGATION_ITEM_DESELECT,
  };
}
