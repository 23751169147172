import PropTypes from 'prop-types';
import React from 'react';
import {
  forbidExtraProps,
  or,
  mutuallyExclusiveProps,
  mutuallyExclusiveTrueProps,
  childrenOfType,
} from 'airbnb-prop-types';

// import processThemeStyles from '../private/processThemeStyles';

import CustomStylesInjector from '../private/CustomStylesInjector';
import getPropTypeForStyles from '../private/styles/getPropTypeForStyles';
import linkStyleMapping, { CUSTOM_STYLES_KEY } from '../private/styles/linkStyleMapping';
import DOMButtonOrA, { buttonTypes } from './DOMButtonOrA';
import Loader from '../exp/Loader';
import idProp from '../utils/propTypes/idProp';
import textlike from '../utils/propTypes/textlike';
import iconProp from '../utils/propTypes/iconProp';
import hrefProp from '../utils/propTypes/href';
import ariaCurrentTypes from '../constants/ariaCurrentTypes';
import textAlignPosition, { textAlignPositionProp } from '../constants/textAlignPosition';
import verticalAlignPosition, {
  verticalAlignPositionProp,
} from '../constants/verticalAlignPosition';
import Airmoji from './Penmoji';
import Labeled from '../exp/Labeled';
import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { PropsType } from '../private/types';
import notNull from '../utils/propTypes/notNull';

const flagValidator = mutuallyExclusiveTrueProps('navigation', 'footer', 'mono');

export const linkPropTypes = {
  children: or([textlike, childrenOfType(Airmoji)]),
  href: hrefProp,
  submit: or([PropTypes.oneOf([false]), mutuallyExclusiveProps(PropTypes.bool, 'href', 'submit')]),
  onPress: PropTypes.func,
  beforeIcon: iconProp,
  afterIcon: iconProp,
  openInNewWindow: PropTypes.bool,
  navigation: flagValidator,
  footer: flagValidator,
  mono: flagValidator,
  loading: PropTypes.bool,
  textAlign: notNull(textAlignPositionProp),
  verticalAlign: verticalAlignPositionProp,
  itemPropSameAs: PropTypes.bool,
  disabled: PropTypes.bool,
  isExpander: PropTypes.bool,
  expanded: PropTypes.bool,
  ariaControls: idProp,
  ariaCurrent: PropTypes.oneOf(Object.values(ariaCurrentTypes)),
  ariaHasPopup: PropTypes.bool,
  ariaLabel: PropTypes.string,
  linkRef: PropTypes.func,
  ampVarsClickType: PropTypes.string,
  ampReplace: PropTypes.string,
  velouteId: PropTypes.string,
  inlineBlockWithIcon: PropTypes.bool,
  inverse: PropTypes.bool,
  noWrap: PropTypes.bool,
  fullWidth: PropTypes.bool,
  customStyles: getPropTypeForStyles(linkStyleMapping),
  removeOutlineOnPress: PropTypes.bool,
  inlineWithText: PropTypes.bool,
};

export type LinkProps = PropsType<typeof linkPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...linkPropTypes,
  ...withStylesPropTypes,
  // ...withBrandPropTypes,
  // NOTE: Add public props to linkPropTypes
});

const defaultProps = {
  children: null,
  onPress: null,
  openInNewWindow: false,
  navigation: false,
  footer: false,
  mono: false,
  loading: false,
  textAlign: textAlignPosition.START,
  verticalAlign: verticalAlignPosition.BASELINE,
  itemPropSameAs: false,
  submit: false,
  disabled: false,
  isExpander: false,
  expanded: false,
  ariaControls: undefined,
  ariaCurrent: null,
  ariaHasPopup: false,
  ariaLabel: undefined,
  ampVarsClickType: undefined,
  ampReplace: undefined,
  linkRef() {},
  velouteId: undefined,
  inlineBlockWithIcon: false,
  inverse: false,
  noWrap: false,
  fullWidth: false,
  customStyles: null,
  href: undefined,
  beforeIcon: undefined,
  afterIcon: undefined,
  removeOutlineOnPress: false,
  inlineWithText: false,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Link({
  css,
  // brand,
  children,
  href,
  onPress,
  beforeIcon,
  afterIcon,
  openInNewWindow,
  navigation,
  footer,
  mono,
  loading,
  textAlign,
  verticalAlign,
  itemPropSameAs,
  submit,
  disabled,
  isExpander,
  expanded,
  ariaControls,
  ariaCurrent,
  ariaHasPopup,
  ariaLabel,
  styles,
  ampVarsClickType,
  ampReplace,
  linkRef,
  velouteId,
  inlineBlockWithIcon,
  inverse,
  noWrap,
  fullWidth,
  customStyles,
  removeOutlineOnPress,
  inlineWithText,
}: PrivateProps) {
  if (loading) {
    return (
      <CustomStylesInjector
        componentID={CUSTOM_STYLES_KEY}
        customStyles={customStyles}
        styleMapping={linkStyleMapping}
      >
        <div {...css(styles.loader)}>
          <Loader inline />
        </div>
      </CustomStylesInjector>
    );
  }

  let inner: $TSFixMe;

  if (beforeIcon) {
    inner = <Labeled before={beforeIcon}>{children}</Labeled>;
  } else if (afterIcon) {
    inner = <Labeled after={afterIcon}>{children}</Labeled>;
  } else {
    inner = children;
  }

  // Note: only DOMButtonOrA should be aware of what component is actually going to render,
  // but because default styling for buttons and links are so different, we only want to reset
  // the button styles if it's actually a button.
  const isButton = !href || disabled;

  // When the Link is not an expander, we want aria-expanded to be undefined regardless of the
  // value of the expanded prop, because it is irrelevant in that case.
  // https://www.w3.org/TR/wai-aria-1.1/#aria-expanded
  const ariaExpanded = isExpander ? expanded : undefined;

  const inlineBlock = inlineBlockWithIcon && (!!beforeIcon || !!afterIcon);

  return (
    <CustomStylesInjector
      componentID={CUSTOM_STYLES_KEY}
      customStyles={customStyles}
      inline={inlineBlock}
      styleMapping={linkStyleMapping}
    >
      <DOMButtonOrA
        href={href}
        onClick={(e: $TSFixMe) => {
          if (disabled) {
            e.preventDefault();
          } else if (onPress != null) {
            onPress(e);
          }
        }}
        openInNewWindow={openInNewWindow}
        itemProp={itemPropSameAs ? 'sameAs' : null}
        type={submit ? buttonTypes.submit : buttonTypes.button}
        disabled={disabled}
        ariaControls={ariaControls}
        ariaCurrent={ariaCurrent}
        ariaDisabled={disabled}
        ariaExpanded={ariaExpanded}
        ariaHasPopup={ariaHasPopup}
        ariaLabel={ariaLabel}
        removeOutlineOnPress={removeOutlineOnPress}
        ampVarsClickType={ampVarsClickType}
        ampReplace={ampReplace}
        buttonAnchorRef={linkRef}
        velouteId={velouteId}
        {...css(
          styles.component,
          isButton && styles.component_button,
          !disabled && styles.component_interactions,
          textAlign === textAlignPosition.CENTER && styles.button_center_align,
          textAlign === textAlignPosition.START && styles.button_start_align,
          textAlign === textAlignPosition.END && styles.button_end_align,
          textAlign === textAlignPosition.JUSTIFY && styles.button_justify_align,
          verticalAlign === verticalAlignPosition.MIDDLE && styles.button_middle_align,
          verticalAlign === verticalAlignPosition.TOP && styles.button_top_align,
          verticalAlign === verticalAlignPosition.BOTTOM && styles.button_bottom_align,
          disabled && styles.component_disabled,
          navigation && styles.navigation,
          inverse && styles.color_inverse,
          footer && styles.footer,
          mono && styles.mono,
          inlineBlock && styles.component_inlineBlock,
          noWrap && styles.component_noWrap,
          fullWidth && isButton && styles.button_full_width,
          fullWidth && !isButton && styles.component_full_width,
          inlineWithText && styles.component_inlineWithText,
        )}
      >
        {inner}
      </DOMButtonOrA>
    </CustomStylesInjector>
  );
}

Link.propTypes = privatePropTypes;
Link.defaultProps = defaultProps;

export default withStyles(({ color, font, unit }) => ({
    // future use
    color_some: {
      color: color.brand.some,
      ':active': {
        color: color.buttons.selectActiveColor,
      },
      ':hover': {
        color: color.brand.some,
      },
    },

    color_inverse: {
      color: color.white,

      ':hover': {
        color: color.textLinkInverseHover,
      },

      ':active': {
        color: color.textLinkInverseActive,
      },
    },

    component: {
      color: color.textLink,
      font: 'inherit',
      fontFamily: font.FONT_FAMILY, // TODO remove this
      textDecoration: font.link.textDecoration,
    },

    component_noWrap: {
      whiteSpace: 'nowrap',
    },

    component_interactions: {
      ':hover': {
        textDecoration: font.link.textDecorationHover,
        color: color.textLinkHover,
      },

      ':focus': {
        textDecoration: font.link.textDecorationFocus,
      },

      ':active': {
        color: color.textLinkActive,
      },
    },

    component_button: {
      appearance: 'none',
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      userSelect: 'auto',

      ':active': {
        // Remove focus ring while clicking, but not when keyboard tabbing around.
        outline: 0,
      },
    },

    button_center_align: {
      textAlign: 'center',
    },

    button_start_align: {
      textAlign: 'left',
    },

    button_end_align: {
      textAlign: 'right',
    },

    button_justify_align: {
      textAlign: 'justify',
    },

    button_middle_align: {
      verticalAlign: 'middle',
    },

    button_top_align: {
      verticalAlign: 'top',
    },

    button_bottom_align: {
      verticalAlign: 'bottom',
    },

    button_full_width: {
      display: 'block',
      width: '100%',
    },

    component_disabled: {
      color: color.textMuted,
      cursor: 'default',

      ':hover': {
        color: color.textMuted,
        textDecoration: font.link.textDecorationDisabled,
      },

      ':active': {
        color: color.textMuted,
      },
    },

    component_inlineBlock: {
      display: 'inline-block',
    },

    component_full_width: {
      display: 'block',
    },

    component_inlineWithText: {
      textDecoration: font.link.textDecorationUnderline,
    },

    navigation: {
      color: color.textLinkNavigation,
      ':hover': {
        color: color.textLinkNavigationHover,
      },
    },

    footer: {
      color: color.textMuted,
      ':hover': {
        color: color.textMuted,
      },
    },

    mono: {
      color: color.core.hof,
      ':hover': {
        color: color.core.hof,
      },
      textDecoration: font.link.textDecorationMono,
    },

    loader: {
      display: 'inline',
      marginLeft: unit,
      marginRight: unit,
    },
  }),
  { pureComponent: true },
)(Link);
