export const mediumGradient = `
  hsla(0, 0%, 0%, 0.6),
  hsla(0, 0%, 0%, 0.592) 8.1%,
  hsla(0, 0%, 0%, 0.571) 15.5%,
  hsla(0, 0%, 0%, 0.538) 22.5%,
  hsla(0, 0%, 0%, 0.495) 29%,
  hsla(0, 0%, 0%, 0.444) 35.3%,
  hsla(0, 0%, 0%, 0.389) 41.2%,
  hsla(0, 0%, 0%, 0.33) 47.1%,
  hsla(0, 0%, 0%, 0.27) 52.9%,
  hsla(0, 0%, 0%, 0.211) 58.8%,
  hsla(0, 0%, 0%, 0.156) 64.7%,
  hsla(0, 0%, 0%, 0.105) 71%,
  hsla(0, 0%, 0%, 0.062) 77.5%,
  hsla(0, 0%, 0%, 0.029) 84.5%,
  hsla(0, 0%, 0%, 0.008) 91.9%,
  hsla(0, 0%, 0%, 0)
`;

export const buttonGradient = `linear-gradient(94.07deg, #E61E4D 2.08%, #E31C5F 49.68%, #D7047F 95.34%)`;
export const buttonGradientPlus = `linear-gradient(90deg, #BD1E59 0%, #92174D 50.05%, #7F1258 100%)`;
export const buttonGradientLux = `linear-gradient(90deg, #59086E 0%, #460479 50.05%, #440589 100%)`;
