import {
  MEDIUM_AND_ABOVE,
  LARGE_AND_ABOVE,
  XLARGE_AND_ABOVE,
  ProgressiveBreakpointName,
  PROGRESSIVE_BREAKPOINT_NAMES,
} from '../constants/progressives';
import { Values } from '../../ts-utils/types/Values';
import { Nullable } from 'typescript-nullable';

export const unit = 8;


export const BREAKPOINT_NAMES = {
  XLARGE: 'xlarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
} as const;
export type BreakpointName = Values<typeof BREAKPOINT_NAMES>;

export { PROGRESSIVE_BREAKPOINT_NAMES };

export const breakpoints: { [key in BreakpointName]: number } = {
  xlarge: 1440,
  large: 1128,
  medium: 744,
  small: 327,
};

export type VariationBreakpointDimension = { height: number; width: number };

export type VariationBreakpointName =
  | BreakpointName
  | 'xlargeTall'
  | 'largeTall'
  | 'mediumTall'
  | 'smallTall';

// used only in variations: happo, storybook, etc
export const variationBreakpoints: {
  [key in VariationBreakpointName]: VariationBreakpointDimension;
} = {
  xlarge: {
    height: 600,
    width: breakpoints[BREAKPOINT_NAMES.XLARGE],
  },
  large: {
    height: 600,
    width: breakpoints[BREAKPOINT_NAMES.LARGE],
  },
  medium: {
    height: 500,
    width: breakpoints[BREAKPOINT_NAMES.MEDIUM],
  },
  small: {
    height: 444,
    width: breakpoints[BREAKPOINT_NAMES.SMALL],
  },
  xlargeTall: {
    height: 1000,
    width: breakpoints[BREAKPOINT_NAMES.XLARGE],
  },
  largeTall: {
    height: 1000,
    width: breakpoints[BREAKPOINT_NAMES.LARGE],
  },
  mediumTall: {
    height: 1000,
    width: breakpoints[BREAKPOINT_NAMES.MEDIUM],
  },
  smallTall: {
    height: 1500,
    width: breakpoints[BREAKPOINT_NAMES.SMALL],
  },
};

export const responsive = {
  // DEPRECATED: please use largeAndAbove
  [BREAKPOINT_NAMES.LARGE]: `@media (min-width: ${breakpoints[BREAKPOINT_NAMES.LARGE]}px)`,

  // DEPRECATED: please treat small+medium as default, and progressively enhance with largeAndAbove
  [BREAKPOINT_NAMES.MEDIUM]: `@media (max-width: ${breakpoints[BREAKPOINT_NAMES.LARGE] - 1}px)`,

  [BREAKPOINT_NAMES.SMALL]: `@media (max-width: ${breakpoints[BREAKPOINT_NAMES.MEDIUM] - 1}px)`,

  [MEDIUM_AND_ABOVE]: `@media (min-width: ${breakpoints[BREAKPOINT_NAMES.MEDIUM]}px)`,

  [LARGE_AND_ABOVE]: `@media (min-width: ${breakpoints[BREAKPOINT_NAMES.LARGE]}px)`,

  [XLARGE_AND_ABOVE]: `@media (min-width: ${breakpoints[BREAKPOINT_NAMES.XLARGE]}px)`,

  print: '@media print',

  prefersReducedMotion: '@media (prefers-reduced-motion)',
};

export type ProgressiveBreakpoints = {
  [progressiveBreakpoint in ProgressiveBreakpointName | 'isBreakpointKnown']: boolean;
};

export const PROGRESSIVE_BREAKPOINTS_UNKNOWN: ProgressiveBreakpoints = {
  isBreakpointKnown: false,
  [MEDIUM_AND_ABOVE]: false,
  [LARGE_AND_ABOVE]: false,
  [XLARGE_AND_ABOVE]: false,
};

const BREAKPOINT_TO_PROGRESSIVE_BREAKPOINTS: { [breakpoint: string]: ProgressiveBreakpoints } = {
  [BREAKPOINT_NAMES.SMALL]: {
    isBreakpointKnown: true,
    [MEDIUM_AND_ABOVE]: false,
    [LARGE_AND_ABOVE]: false,
    [XLARGE_AND_ABOVE]: false,
  },
  [BREAKPOINT_NAMES.MEDIUM]: {
    isBreakpointKnown: true,
    [MEDIUM_AND_ABOVE]: true,
    [LARGE_AND_ABOVE]: false,
    [XLARGE_AND_ABOVE]: false,
  },
  [BREAKPOINT_NAMES.LARGE]: {
    isBreakpointKnown: true,
    [MEDIUM_AND_ABOVE]: true,
    [LARGE_AND_ABOVE]: true,
    [XLARGE_AND_ABOVE]: false,
  },
  [BREAKPOINT_NAMES.XLARGE]: {
    isBreakpointKnown: true,
    [MEDIUM_AND_ABOVE]: true,
    [LARGE_AND_ABOVE]: true,
    [XLARGE_AND_ABOVE]: true,
  },
};

export function progressiveBreakpointsFromBreakpoint(
  breakpoint: Nullable<BreakpointName> | Nullable<string>,
) {
  if (!breakpoint) return PROGRESSIVE_BREAKPOINTS_UNKNOWN;
  return BREAKPOINT_TO_PROGRESSIVE_BREAKPOINTS[breakpoint] || PROGRESSIVE_BREAKPOINTS_UNKNOWN;
}

export function deprecatedBreakpointFromBreakpoint(breakpoint: Nullable<string>): Nullable<string> {
  switch (breakpoint) {
    case BREAKPOINT_NAMES.XLARGE:
      return BREAKPOINT_NAMES.LARGE;
    default:
      return breakpoint;
  }
}

// DEPRECATED, use progressiveBreakpoints prop instead
export function matchesProgressive(
  breakpoint: Nullable<BreakpointName>,
  progressiveBreakpoint: '' | null | ProgressiveBreakpointName,
): boolean {
  if (!progressiveBreakpoint) return true;
  switch (breakpoint) {
    case BREAKPOINT_NAMES.SMALL:
      return false;
    case BREAKPOINT_NAMES.MEDIUM:
      return progressiveBreakpoint === PROGRESSIVE_BREAKPOINT_NAMES.MEDIUM_AND_ABOVE;
    case BREAKPOINT_NAMES.LARGE:
      return (
        progressiveBreakpoint === PROGRESSIVE_BREAKPOINT_NAMES.LARGE_AND_ABOVE ||
        progressiveBreakpoint === PROGRESSIVE_BREAKPOINT_NAMES.MEDIUM_AND_ABOVE
      );
    default:
      return true;
  }
}

export function isBelowMedium(progressiveBreakpoints: ProgressiveBreakpoints): boolean {
  return progressiveBreakpoints.isBreakpointKnown && !progressiveBreakpoints[MEDIUM_AND_ABOVE];
}

export function isBelowLarge(progressiveBreakpoints: ProgressiveBreakpoints): boolean {
  return progressiveBreakpoints.isBreakpointKnown && !progressiveBreakpoints[LARGE_AND_ABOVE];
}

export function isBelowXlarge(progressiveBreakpoints: ProgressiveBreakpoints): boolean {
  return progressiveBreakpoints.isBreakpointKnown && !progressiveBreakpoints[XLARGE_AND_ABOVE];
}

// includes MEDIUM_AND_ABOVE, excludes LARGE_AND_ABOVE
export function isMediumToLarge(progressiveBreakpoints: ProgressiveBreakpoints): boolean {
  return (
    progressiveBreakpoints.isBreakpointKnown &&
    progressiveBreakpoints[MEDIUM_AND_ABOVE] &&
    !progressiveBreakpoints[LARGE_AND_ABOVE]
  );
}

export default {
  flatButton: {
    size: 6 * unit,
    sizeSmall: 4 * unit,
    iconSize: 3 * unit,
    iconSizeSmall: 2 * unit,
  },

  countBadge: {
    height: 20,
    minWidth: 20,
  },

  progressBar: {
    height: unit,
  },

  rule: {
    heroWidth: 6 * unit,
  },

  checkBox: {
    size: 18,
    checkMarkSize: 32,
  },

  switch: {
    size: 4 * unit,
    backgroundWidth: 6 * unit,
  },

  radioButton: {
    size: 2.25 * unit,
    sizeLarge: 3 * unit,
    dotSize: 0.75 * unit,
    dotSizeLarge: unit,
  },
};
