import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps, mutuallyExclusiveTrueProps } from 'airbnb-prop-types';

import NewText, {
  typographyDefaultProps,
  typographyPropTypes,
  COLOR,
  LEADING,
  SIZE,
  TRACKING,
  WEIGHT,
} from '../private/NewText';
import notNull from '../utils/propTypes/notNull';
import withLoading, { withLoadingPropTypes } from './withLoading';
import { PropsType } from '../private/types';

// NewText exports
export { typographyDefaultProps, typographyPropTypes, COLOR, LEADING, SIZE, TRACKING, WEIGHT };

const sizingProp = mutuallyExclusiveTrueProps(
  'micro',
  'small',
  'reduced',
  'large',
  'short',
  'largeShort',
  'tall',
  'largeTall',
  'microWide',
);
const emphasisProp = mutuallyExclusiveTrueProps('bold', 'light');
const colorProp = mutuallyExclusiveTrueProps('muted', 'disabled', 'inverse');

export const textPropTypes = {
  children: PropTypes.node,

  // old props
  bold: emphasisProp,
  disabled: notNull(colorProp),
  inverse: colorProp,
  large: sizingProp,
  largeShort: sizingProp,
  largeTall: sizingProp,
  light: emphasisProp,
  micro: sizingProp,
  microWide: sizingProp,
  muted: colorProp,
  reduced: sizingProp,
  short: sizingProp,
  small: sizingProp,
  tall: sizingProp,

  // new props
  ...typographyPropTypes,
};

export type TextProps = PropsType<typeof textPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...textPropTypes,
  ...withLoadingPropTypes,
  // NOTE: Add public props to textPropTypes
});

const defaultProps = {
  bold: false,
  disabled: false,
  inverse: false,
  large: false,
  light: false,
  micro: false,
  muted: false,
  reduced: false,
  small: false,

  // typographyDefaultProps are intentionally not included to allow us to detect when new props are
  // explicitly provided.
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

const COLOR_MAP = {
  disabled: COLOR.DISABLED,
  inverse: COLOR.INVERSE,
  muted: COLOR.MUTED,
};

const LEADING_MAP = {
  largeTall: LEADING.TALL,
  tall: LEADING.TALL,
};

const SIZE_MAP = {
  large: SIZE.LARGE,
  largeShort: SIZE.LARGE,
  largeTall: SIZE.LARGE,
  micro: SIZE.MINI,
  microWide: SIZE.MINI,
  small: SIZE.SMALL,
};

const TRACKING_MAP = {
  microWide: TRACKING.WIDE,
};

const WEIGHT_MAP = {
  bold: WEIGHT.BOLDEST,
  light: WEIGHT.LIGHTEST,
};

function getMappedValue(
  map: { [index: string]: string },
  props: { [index: string]: boolean },
  defaultValue?: string,
) {
  const key = Object.keys(map).find((key) => props[key]);
  return key ? map[key] : defaultValue;
}

function Text({
  // deprecated props
  bold,
  disabled,
  inverse,
  large,
  largeShort,
  largeTall,
  light,
  micro,
  microWide,
  muted,
  reduced,
  short,
  small,
  tall,

  // withLoading
  isLoading,

  // new or shared props
  ...otherProps
}: PrivateProps) {
  const oldProps = {
    bold: !!bold,
    disabled: !!disabled,
    inverse: !!inverse,
    large: !!large,
    largeShort: !!largeShort,
    largeTall: !!largeTall,
    light: !!light,
    micro: !!micro,
    microWide: !!microWide,
    muted: !!muted,
    reduced: !!reduced,
    short: !!short,
    small: !!small,
    tall: !!tall,
  };

  const {
    color: newColor,
    leading: newLeading,
    size: newSize,
    tracking: newTracking,
    weight: newWeight,
  } = otherProps;

  const hasNewProps = newColor || newLeading || newSize || newTracking || newWeight;
  let mappedProps = {};

  if (!hasNewProps) {
    mappedProps = {
      color: getMappedValue(COLOR_MAP, oldProps),
      inline: !!micro || !!microWide, // these used to render <small> which is inline by default
      leading: getMappedValue(LEADING_MAP, oldProps),
      size: getMappedValue(SIZE_MAP, oldProps),
      tracking: getMappedValue(TRACKING_MAP, oldProps),
      weight: getMappedValue(WEIGHT_MAP, oldProps, WEIGHT.BOLDER),
    };
  }

  return <NewText noLoading={!isLoading} {...mappedProps} {...otherProps} />;
}

Text.propTypes = privatePropTypes;
Text.defaultProps = defaultProps;

export default withLoading(Text);
