import PropTypes from 'prop-types';
import getComponentName from 'airbnb-prop-types/build/helpers/getComponentName';
import { Props } from './types';

const iconProp: typeof PropTypes.element = (props: Props, propName, componentName, ...rest) => {
  const { [propName]: propValue } = props;
  if (propValue == null || propValue === false) {
    return null;
  }

  const elementError = PropTypes.element(props, propName, componentName, ...rest);
  if (elementError) {
    return elementError;
  }

  const name = getComponentName(propValue.type);
  if (
    !name.startsWith('Icon') &&
    name !== 'AnIcon' &&
    name !== 'ActiveChevron' &&
    !name.startsWith('withDirection(IconWithDirection')
  ) {
    return new Error(`${componentName}.${propName} does not appear to be an Icon component`);
  }

  return null;
};

iconProp.isRequired = (props: Props, propName, componentName, ...rest) => {
  const propValue = props[propName];
  if (propValue == null || propValue === false) {
    return new TypeError(
      `Required prop \`${propName}\` was not specified in \`${componentName}\`.`,
    );
  }
  return iconProp(props, propName, componentName, ...rest);
};

export default iconProp;
