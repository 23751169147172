import PropTypes from 'prop-types';

const textAlignPosition = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
  JUSTIFY: 'justify',
};

export const textAlignPositionProp = PropTypes.oneOf(Object.values(textAlignPosition));

export default textAlignPosition;
