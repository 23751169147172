// @preval

// NOTE: We use preval to prevent calculating all of these values at runtime.
// This directory must remain in CommonJS module syntax for it to work with the
// preval plugin.

require('../../private/babelHelpersForPreval');
const { brand, core, social, black, white, clear } = require('./baseColors');
const buttons = require('./buttonColors');

module.exports = {
  core,
  brand,
  social,
  black,
  white,
  clear,
  buttons,
};
