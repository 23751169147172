import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps, mutuallyExclusiveTrueProps, disallowedIf } from 'airbnb-prop-types';

// import processThemeStyles from '../private/processThemeStyles';

import CustomStylesInjector from '../private/CustomStylesInjector';
import getPropTypeForStyles from '../private/styles/getPropTypeForStyles';
import buttonStyleMapping, { CUSTOM_STYLES_KEY } from '../private/styles/buttonStyleMapping';

import removeFirefoxButtonPadding from '../utils/removeFirefoxButtonPadding';
import DOMButtonOrA from './DOMButtonOrA';
import idProp from '../utils/propTypes/idProp';
import iconProp from '../utils/propTypes/iconProp';
import textlike from '../utils/propTypes/textlike';
import hrefProp from '../utils/propTypes/href';
import { withStyles, withStylesPropTypes, CSSProperties } from '../themes/withStyles';
// import withBrand, { BRAND_SOME, withBrandPropTypes } from '../themes/withBrand';
import { PropsType } from '../private/types';
import {
  inverseFocusedRingStyles,
  inverseSecondaryFocusedRingStyles,
  defaultFocusedRingStyles,
} from '../utils/focusedStyles';

import AccessibleText from './AccessibleText';
import Labeled from './Labeled';
import Loader from './Loader';

const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2;

const SHADOW_LEVELS = [0, 1, 2, 3, 4];

const TYPES = {
  button: 'button',
  submit: 'submit',
};

const hierarchyLevel = mutuallyExclusiveTrueProps('primary', 'secondary', 'mono');
const blockProps = mutuallyExclusiveTrueProps('block', 'fullWidth', 'displayBlock');

export const buttonTypes = {
  button: 'button',
  submit: 'submit',
};

export const buttonPropTypes = {
  onPress: PropTypes.func,

  // Legacy - do not use
  block: blockProps,

  // New version of "block" with old behavior
  fullWidth: blockProps,

  // "true" block behavior
  displayBlock: blockProps,

  type: PropTypes.oneOf(Object.values(TYPES)),
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: textlike,
  beforeIcon: iconProp,
  afterIcon: iconProp,
  inverse: PropTypes.bool,
  primary: hierarchyLevel,
  secondary: hierarchyLevel,
  mono: hierarchyLevel,
  href: disallowedIf(hrefProp, 'type', PropTypes.oneOf([TYPES.submit])),
  buttonRoleForLink: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  rectangular: PropTypes.bool,
  ampVarsClickType: PropTypes.string,
  ampReplace: PropTypes.string,
  ariaHasPopup: PropTypes.bool,
  ariaDescribedBy: idProp,
  noWrap: PropTypes.bool,
  buttonRef: PropTypes.func,
  velouteId: PropTypes.string,
  // ...withBrandPropTypes,
  hideText: PropTypes.bool,
  shadowLevel: PropTypes.oneOf(SHADOW_LEVELS),
  customStyles: getPropTypeForStyles(buttonStyleMapping),
  preserveIconStyles: PropTypes.bool,
};

export type ButtonProps = PropsType<typeof buttonPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...buttonPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to buttonPropTypes
});

const defaultProps = {
  onPress() {},
  block: false,
  fullWidth: false,
  displayBlock: false,
  compact: false,
  type: TYPES.button,
  disabled: false,
  loading: false,
  inverse: false,
  primary: false,
  secondary: false,
  mono: false,
  openInNewWindow: false,
  small: false,
  large: false,
  rectangular: false,
  ampVarsClickType: undefined,
  ampReplace: undefined,
  ariaHasPopup: false,
  ariaDescribedBy: undefined,
  noWrap: false,
  buttonRef: null,
  velouteId: undefined,
  hideText: false,
  shadowLevel: 0,
  buttonRoleForLink: false,
  customStyles: null,
  afterIcon: undefined,
  beforeIcon: undefined,
  children: undefined,
  href: undefined,
  preserveIconStyles: false,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

// Exported for testing
export const buttonStyles = [
  // Standard buttons
  'primary',
  'primarySmall',
  'default',
  'some',
  'inverse',

  // Secondary buttons
  'secondary',
  'someSecondary',
  'secondaryInverse',
  'mono',
];

function Button({
  css,
  block,
  fullWidth,
  displayBlock,
  children,
  compact,
  beforeIcon,
  afterIcon,
  onPress,
  type,
  disabled,
  loading,
  inverse,
  primary,
  secondary,
  mono,
  href,
  buttonRoleForLink,
  openInNewWindow,
  small,
  large,
  rectangular,
  ampVarsClickType,
  ampReplace,
  ariaDescribedBy,
  ariaHasPopup,
  noWrap,
  buttonRef,
  styles,
  velouteId,
  // brand,
  hideText,
  shadowLevel,
  customStyles,
  preserveIconStyles,
}: PrivateProps) {
  // const isSomeBrand = brand === BRAND_SOME;
  const useLightLoader = (!inverse && !mono && !secondary) || (secondary && inverse);
  const useDarkLoader = mono;
  const interactive = !loading && !disabled;

  let inner: $TSFixMe;
  if (beforeIcon) {
    inner = (
      // @ts-ignore
      <Labeled
        centered
        before={
          preserveIconStyles
            ? beforeIcon
            : React.cloneElement(beforeIcon, {
                color: inverse ? beforeIcon.props.color : undefined,
                size: small ? 15 : 18,
              })
        }
        spaceBetween={hideText ? 0 : 1.5}
      >
        {/*
        // @ts-ignore */}
        {!hideText && children}
      </Labeled>
    );
  } else if (afterIcon) {
    inner = (
      // @ts-ignore
      <Labeled
        centered
        after={
          preserveIconStyles
            ? afterIcon
            : React.cloneElement(afterIcon, {
                color: inverse ? afterIcon.props.color : undefined,
                size: small ? 15 : 18,
              })
        }
        spaceBetween={hideText ? 0 : 1.5}
      >
        {/*
        // @ts-ignore */}
        {!hideText && children}
      </Labeled>
    );
  } else if (!hideText) {
    inner = children;
  }

  const colorKey =
    (inverse && secondary && 'secondaryInverse') ||
    (inverse && 'inverse') ||
    (mono && 'mono') ||
    // (isSomeBrand && secondary && 'someSecondary') ||
    // (isSomeBrand && 'some') ||
    (secondary && 'secondary') ||
    (primary && small && 'primarySmall') ||
    (primary && 'primary') ||
    'default';

  return (
    <CustomStylesInjector
      componentID={CUSTOM_STYLES_KEY}
      customStyles={customStyles}
      styleMapping={buttonStyleMapping}
    >
      <DOMButtonOrA
        disabled={disabled}
        loading={loading}
        onClick={onPress}
        type={type}
        href={href}
        role={buttonRoleForLink ? 'button' : null}
        openInNewWindow={openInNewWindow}
        ampVarsClickType={ampVarsClickType}
        ampReplace={ampReplace}
        ariaDescribedBy={ariaDescribedBy}
        ariaHasPopup={ariaHasPopup}
        buttonAnchorRef={buttonRef}
        velouteId={velouteId}
        {...css(
          styles.container,
          interactive && styles.container_interactive,
          !rectangular && styles.container_rounded,
          !rectangular && small && styles.container_roundedSmall,
          (block || fullWidth) && styles.container_fullWidth,
          displayBlock && styles.container_displayBlock,

          /* size and spacing styles */
          small ? styles.container_sizeSmall : styles.container_sizeRegular,
          large && styles.container_sizeLarge,
          compact && styles.container_sizeCompact,

          /* shadow styles */
          shadowLevel === 0 && styles.container_shadowLevel0,
          shadowLevel === 1 && styles.container_shadowLevel1,
          shadowLevel === 2 && styles.container_shadowLevel2,
          shadowLevel === 3 && styles.container_shadowLevel3,
          shadowLevel === 4 && styles.container_shadowLevel4,

          /* button color styles */
          styles[`colors_${colorKey}`],
          interactive && styles[`colors_${colorKey}_interactive`],
        )}
      >
        {loading && <Loader light={useLightLoader} dark={useDarkLoader} />}
        {loading && (
          // @ts-ignore
          <AccessibleText>{inner}</AccessibleText>
        )}
        <span
          {...css(
            styles.text,
            styles.text_sizeRegular,
            small && styles.text_sizeSmall,
            large && styles.text_sizeLarge,
            loading && styles.text_hidden,
            noWrap && styles.text_noWrap,
          )}
        >
          {inner}
        </span>
      </DOMButtonOrA>
    </CustomStylesInjector>
  );
}

Button.propTypes = privatePropTypes;
Button.defaultProps = defaultProps;
Button.TYPES = TYPES;

export default withStyles(({ border, color, font, unit, spacing, shadow }) => {
  const buttonColorStyles: { [x: string]: CSSProperties } = {};

  buttonStyles.forEach((style) => {
    const inverseFocusRing = style === 'inverse';
    const inverseSecondaryFocusRing = style === 'secondaryInverse';

    buttonColorStyles[`colors_${style}`] = {
      background: color.buttons[`${style}Color`],
      borderColor: color.buttons[`${style}Border`],
      color: color.buttons[`${style}Text`],

      ':disabled': {
        background: color.buttons[`${style}DisabledColor`],
        borderColor: color.buttons[`${style}DisabledBorder`],
        color: color.buttons[`${style}DisabledText`],
      },

      ':focus':
        (inverseFocusRing && inverseFocusedRingStyles()) ||
        (inverseSecondaryFocusRing && inverseSecondaryFocusedRingStyles()) ||
        defaultFocusedRingStyles(),
    };

    buttonColorStyles[`colors_${style}_interactive`] = {
      ':hover': {
        background: color.buttons[`${style}HoverColor`],
        borderColor: color.buttons[`${style}HoverBorder`],
        color: color.buttons[`${style}HoverText`],
      },

      ':active': {
        background: color.buttons[`${style}ActiveColor`],
        borderColor: color.buttons[`${style}ActiveBorder`],
        color: color.buttons[`${style}ActiveText`],
      },
    };
  });

  return {
    container: {
      cursor: 'default',
      display: 'inline-block',
      margin: 0,
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      transitionProperty: 'background, border-color, color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
      width: 'auto',

      ...removeFirefoxButtonPadding(),
    },

    container_interactive: {
      cursor: 'pointer',
    },

    container_rounded: {
      borderRadius: border.button.borderRadius,
    },

    container_roundedSmall: {
      borderRadius: border.buttonSmall.borderRadius,
    },

    container_fullWidth: {
      width: '100%',
    },

    container_displayBlock: {
      display: 'block',
      width: '100%',
    },

    container_shadowLevel0: {
      ...shadow.button.level0,
    },

    container_shadowLevel1: {
      ...shadow.button.level1,
    },

    container_shadowLevel2: {
      ...shadow.button.level2,
    },

    container_shadowLevel3: {
      ...shadow.button.level3,
    },

    container_shadowLevel4: {
      ...shadow.button.level4,
    },

    container_sizeRegular: {
      ...font.button,
      ...font.bold,

      borderWidth: border.button.borderWidth,
      borderStyle: 'solid',
      borderColor: color.clear,

      paddingLeft: spacing.button.horizontal,
      paddingRight: spacing.button.horizontal,
      paddingBottom: spacing.button.vertical,
      paddingTop: spacing.button.vertical,
      minWidth: GOLDEN_RATIO * 2 * spacing.button.horizontal,
    },

    container_sizeSmall: {
      ...font.buttonSmall,
      ...font.book,

      borderWidth: border.buttonSmall.borderWidth,
      borderStyle: 'solid',
      borderColor: color.clear,

      paddingLeft: spacing.buttonSmall.horizontal,
      paddingRight: spacing.buttonSmall.horizontal,
      paddingBottom: spacing.buttonSmall.vertical,
      paddingTop: spacing.buttonSmall.vertical,
      minWidth: GOLDEN_RATIO * 2 * spacing.buttonSmall.horizontal,
    },

    container_sizeLarge: {
      ...font.buttonLarge,
      ...font.bold,

      paddingLeft: spacing.buttonLarge.horizontal,
      paddingRight: spacing.buttonLarge.horizontal,
      paddingBottom: spacing.buttonLarge.vertical,
      paddingTop: spacing.buttonLarge.vertical,
      minWidth: GOLDEN_RATIO * 2 * spacing.buttonLarge.horizontal,
    },

    container_sizeCompact: {
      paddingRight: unit,
      paddingLeft: unit,
    },

    ...buttonColorStyles,

    text: {
      color: 'inherit',
    },

    text_hidden: {
      visibility: 'hidden',
    },

    text_noWrap: {
      whiteSpace: 'nowrap',
    },

    text_sizeRegular: {
      ...font.button,
      color: 'inherit',
    },

    text_sizeSmall: {
      ...font.buttonSmall,
      color: 'inherit',
    },

    text_sizeLarge: {
      ...font.buttonLarge,
      color: 'inherit',
    },
  };
})(Button);
