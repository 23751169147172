import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { PropsType } from '../private/types';

export const hideForPrintPropTypes = {
  children: PropTypes.node, // TODO: should be required
};

export type HideForPrintProps = PropsType<typeof hideForPrintPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...hideForPrintPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to hideForPrintPropTypes
});

const defaultProps = {
  children: null,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function HideForPrint({ children, css, styles }: PrivateProps) {
  return <div {...css(styles.container)}>{children}</div>;
}

HideForPrint.propTypes = privatePropTypes;
HideForPrint.defaultProps = defaultProps;

export default withStyles(
  ({ responsive }) => ({
    container: {
      display: 'inherit',

      [responsive.print]: {
        display: 'none',
      },
    },
  }),
  { pureComponent: true },
)(HideForPrint);
