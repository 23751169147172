import App from './App';

import P1Routes from './p1/routes';
import AboutRoutes from './about/routes';

export default [
  {
    component: App,
    path: '',
    routes: [
      P1Routes,
      AboutRoutes,
    ],
  },
];
