import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { prefersReducedMotionQuery } from '../prefersReducedMotion';
import { PropsType } from '../private/types';

const ANIMATION_DURATION_MS = 250;
const TIMING_FUNCTION = 'ease-in-out';

export const rotatePropTypes = {
  children: PropTypes.node.isRequired,
  degrees: PropTypes.number,
};

export type RotateProps = PropsType<typeof rotatePropTypes>;

const privatePropTypes = forbidExtraProps({
  ...rotatePropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to rotatePropTypes
});

const defaultProps = {
  degrees: 0,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Rotate({ css, degrees, children, styles }: PrivateProps) {
  return <div {...css(styles.iconWrapper, { transform: `rotate(${degrees}deg)` })}>{children}</div>;
}

Rotate.propTypes = privatePropTypes;
Rotate.defaultProps = defaultProps;

export default withStyles(
  () => ({
    iconWrapper: {
      display: 'table-cell',
      verticalAlign: 'middle',
      transitionProperty: 'transform',
      transitionDuration: `${ANIMATION_DURATION_MS}ms`,
      transitionTimingFunction: TIMING_FUNCTION,

      [prefersReducedMotionQuery]: {
        transition: 'none',
      },
    },
  }),
  { pureComponent: true },
)(Rotate);
