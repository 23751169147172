export const prefersReducedMotionQuery = '@media (prefers-reduced-motion)';

let prefersReducedMotionStatus: boolean;

export default function prefersReducedMotion() {
  if (typeof window === 'undefined' || !('matchMedia' in window)) {
    return false;
  }

  if (prefersReducedMotionStatus !== undefined) {
    return prefersReducedMotionStatus;
  }

  const motionQuery = matchMedia('(prefers-reduced-motion)');

  prefersReducedMotionStatus = motionQuery.matches;

  if (prefersReducedMotion !== undefined) {
    motionQuery.addListener(() => {
      prefersReducedMotionStatus = motionQuery.matches;
    });
  }

  return prefersReducedMotionStatus;
}
