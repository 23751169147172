import { withStyles, WithStylesProps } from '../../dls-web/themes/withStyles';
import FlexBar from '../../dls-web/exp/FlexBar';
import HideForPrint from '../../dls-web/exp/HideForPrint';
import React from 'react';
import ShowAt from '../../dls-web/exp/ShowAt';
import Text, { SIZE, WEIGHT, COLOR } from '../../dls-web/exp/Text';
import Spacing from '../../dls-web/exp/Spacing';
import { WithDefaultProps } from '../../ts-utils/types/WithDefaultProps';

import EventListener from '../../event-listener';

import { HEADER_HEIGHT, ZINDEX, THEMES } from '../../header-shared-constants';
import DisableBodyScrolling from '../../header-shared/components/DisableBodyScrolling';
import HeaderNav from './HeaderNav';
import { mediumGradient } from '../../dls-current/constants/gradients';
import Logo from '../../shared/components/Logo';
import Navigation from './Navigation';

// The width of the header's dividing line (when used)
export const DIVIDER_WIDTH = 1;

export interface HeaderProps {
  // If provided, Navigation will pass-through and expect to be rendered on the async component.
  asyncContents?: boolean;

  // If provided, will hide the flyout and force the logo to always
  // be a link to the homepage.
  disableFlyoutMenu?: boolean;
  children?: React.ReactNode;

  // Variants determine the color of links and icons in the header. Permutations
  // are documented in the constant above.
  colorTheme?: THEMES;

  // If true, the header is absolutely positioned, above content
  // and the background color removed.
  floating?: boolean;

  // If provided, this prop renders the node into a location that makes
  // it compatible, z-index-wise, with the logo.
  flyoutMenu?: React.ReactNode;
  flyoutMenuIsOpen?: boolean;

  // When true, the sticky header always displays the search bar.
  // When false, whether or not to display the search bar falls back
  // to what is specified by other props.
  // forceSearchBarOnStickyHeader?: boolean;

  // If true, the header will appear in the DOM but not visually
  hidden?: boolean;

  // The following flags, false by default, simply hide pieces of
  // the base header component.
  hideLogo?: boolean;
  hideNavigation?: boolean;
  // hideSearch?: boolean;

  // If true, the navigation will initially be hidden. Once the component
  // mounts, the navigation will gently fade in. This can be useful if you're
  // serving a logged-out version of the header from the server, and
  // switching to a logged-in version on the client, and want to prevent
  // a flash of the former to the latter
  initiallyHideNavigation?: boolean;

  // If provided, completely replaces the logo with the provided node
  logo?: React.ReactNode;

  // If provided, appends additional content after the Belo icon.
  // Used for optional sub-branding (i.e. Lux, Business Travel, etc)
  logoText?: string;

  // An optional callback when user clicks logo to navigate to `/`
  onHomeNavigation?: () => void;

  // An optional callback which is run when the logo is pressed.
  onPressLogo?: (e: React.KeyboardEvent | React.MouseEvent) => void;

  // Replacement to standard search bar
  // searchReplacement?: React.ReactNode;

  // If true, the header will be displayed when printing the page,
  // even when hidden: true
  showForPrint?: boolean;

  // If true, the header is positioned as fixed to the top of the
  // window and remains visible at all times.
  sticky?: boolean;

  // Remove horizontal and vertical rules.
  suppressBorders?: boolean;

  // Remove only horizontal rules.
  suppressBottomBorder?: boolean;

  // When true, header has no border with transparent background
  useTransparentBackground?: boolean;

  // When true, header has no border with gradient background from black to transparent
  useTransparentGradientBackground?: boolean;
}

const defaultProps = {
  asyncContents: false,
  children: null,
  colorTheme: THEMES.Default,
  disableFlyoutMenu: false,
  floating: false,
  flyoutMenu: null,
  flyoutMenuIsOpen: false,
  // forceSearchBarOnStickyHeader: false,
  hidden: false,
  hideLogo: false,
  hideNavigation: false,
  // hideSearch: false,
  initiallyHideNavigation: false,
  logo: null,
  logoText: undefined,
  onHomeNavigation() {},
  onPressLogo() {},
  // searchReplacement: null,
  showForPrint: false,
  sticky: false,
  suppressBorders: false,
  suppressBottomBorder: false,
  useTransparentBackground: false,
  useTransparentGradientBackground: false,
};

type Props = WithDefaultProps<HeaderProps, typeof defaultProps> & WithStylesProps;

interface State {
  navigationIsHidden: boolean;
  transitionIsActive: boolean;
}

class Header extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      navigationIsHidden: props.initiallyHideNavigation,
      transitionIsActive: false,
    };

    this.handleFlyoutMenuKeyDown = this.handleFlyoutMenuKeyDown.bind(this);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  componentDidMount() {
    const { initiallyHideNavigation, hideNavigation } = this.props;

    if (initiallyHideNavigation && !hideNavigation) {
      this.setState({
        navigationIsHidden: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.flyoutMenuIsOpen !== nextProps.flyoutMenuIsOpen) {
      this.setState({
        transitionIsActive: true,
      });
    }
  }

  handleFlyoutMenuKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      this.props.onPressLogo(e);
    }
  }

  handleTransitionEnd() {
    this.setState({
      transitionIsActive: false,
    });
  }

  getColorTheme() {
    const { sticky, flyoutMenuIsOpen, colorTheme } = this.props;

    const useDarkOrLightColorTheme = !sticky && !flyoutMenuIsOpen;

    if (
      (useDarkOrLightColorTheme && colorTheme === THEMES.Light) ||
      (colorTheme === THEMES.Babu && !flyoutMenuIsOpen)
    ) {
      return THEMES.Light;
    }

    if (useDarkOrLightColorTheme && colorTheme === THEMES.Dark) {
      return THEMES.Dark;
    }

    // If the color theme is Light and the flyout menu is open,
    // the belo and background will both be white, which makes the
    // belo invisible. This sets the belo color to Rausch in that case.
    if (flyoutMenuIsOpen && colorTheme === THEMES.Light) {
      return THEMES.Default;
    }

    return colorTheme;
  }

  render() {
    const {
      asyncContents,
      children,
      colorTheme,
      css,
      disableFlyoutMenu,
      floating,
      flyoutMenu,
      flyoutMenuIsOpen,
      // forceSearchBarOnStickyHeader,
      hidden,
      hideLogo,
      hideNavigation,
      // hideSearch,
      logo,
      logoText,
      onHomeNavigation,
      onPressLogo,
      // searchReplacement,
      showForPrint,
      sticky,
      styles,
      suppressBorders,
      suppressBottomBorder,
      useTransparentGradientBackground,
    } = this.props;

    const { navigationIsHidden, transitionIsActive } = this.state;

    const useTransparentBackground =
      this.props.useTransparentBackground || colorTheme === THEMES.Babu;

    let logoContent: $TSFixMe;

    // To determine the logo content, first check whether the props have requested
    // to hide the logo entirely. If not, perform another check to see if the props
    // have provided custom content to render in the logo’s place. If so, we’ll render
    // that. If not, we'll just render the standard Belo logo.
    if (!hideLogo) {
      if (logo) {
        logoContent = (
          <div>
            <FlexBar
              before={
                <Spacing vertical={2}>{logo}</Spacing>}
              after={
                <ShowAt breakpoint="mediumAndAbove">
                  <Spacing horizontal={2}>
                    <Text
                      size={SIZE.TITLE3}
                      weight={WEIGHT.BOLDER}
                      color={COLOR.INHERIT}
                    >
                      Penslz
                    </Text>
                  </Spacing>
                </ShowAt>
              }
            />
            {(transitionIsActive || flyoutMenuIsOpen) && flyoutMenu}
          </div>
        );
      } else {
        const logoElement = (
          <Logo
            text={logoText}
            colorTheme={this.getColorTheme()}
            onHomeNavigation={onHomeNavigation}
            onPress={onPressLogo}
            menuIndicatorIsActive={flyoutMenuIsOpen}
            disableFlyoutMenu={disableFlyoutMenu}
          />
        );

        logoContent = (
          <div>
            <div {...css(styles.logoContainer)}>{logoElement}</div>
            {(transitionIsActive || flyoutMenuIsOpen) && <div {...css(styles.flyoutMenuMask)} />}
            <div
              {...css(
                styles.flyoutMenuContainer,
                flyoutMenuIsOpen && styles.flyoutMenuContainer_open,
              )}
              onTransitionEnd={this.handleTransitionEnd}
            >
              {(transitionIsActive || flyoutMenuIsOpen) && flyoutMenu}
            </div>
          </div>
        );
      }
    }

    return (
      <HeaderNav
        role="banner"
        {...css(
          styles.container,
          hidden && styles.container_hidden,
          hidden && showForPrint && styles.container_showForPrint,
          flyoutMenuIsOpen && styles.container_withFlyout,
        )}
      >
        {flyoutMenuIsOpen && (
          <EventListener target="window" type="keydown" onEvent={this.handleFlyoutMenuKeyDown} />
        )}

        {flyoutMenuIsOpen && !transitionIsActive && <DisableBodyScrolling />}

        <div
          {...css(
            styles.content,
            sticky && styles.content_sticky,
            !sticky && floating && styles.content_floating,
            (suppressBorders || suppressBottomBorder) && styles.content_suppressBorders,
            useTransparentBackground && styles.content_transparent,
            useTransparentGradientBackground && styles.content_transparentGradient,
          )}
        >
          <FlexBar
            align="middle"
            before={logoContent}
            after={
              !hideNavigation &&
              !navigationIsHidden && (
                // <ShowAt breakpoint="largeAndAbove">
                  <HideForPrint>
                    <Navigation floating={floating} asyncContents={asyncContents}>
                      {children}
                    </Navigation>
                  </HideForPrint>
                // </ShowAt>
              )
            }
          />
        </div>
      </HeaderNav>
    );
  }
}

export default withStyles(({ color, responsive, unit }) => ({
  // Layout

  container: {
    // Positions the header above the rest of the page
    position: 'relative',
    zIndex: ZINDEX.container,
  },

  container_withFlyout: {
    zIndex: ZINDEX.flyoutMenu,
  },

  container_hidden: {
    display: 'none',
  },

  container_showForPrint: {
    [responsive.print]: {
      display: 'block',
    },
  },

  // Content

  content: {
    background: color.white,
    boxShadow: `0 ${DIVIDER_WIDTH}px 0 ${color.divider}`,
    transitionDuration: '200ms',
    transitionProperty: 'background, box-shadow',
    transitionTimingFunction: 'ease-out',
    width: '100%',

    [responsive.print]: {
      boxShadow: 'none',
    },
  },

  content_floating: {
    background: 'transparent',
    boxShadow: 'none',
    left: 0,
    position: 'absolute',
    top: 0,
  },

  content_sticky: {
    background: color.white,
    borderBottom: `1px solid ${color.panelBorder}`,
    boxShadow: 'none',

    [responsive.print]: {
      borderBottom: 'none',
      position: 'static',
    },
  },

  content_suppressBorders: {
    borderBottom: 'none',
    boxShadow: 'none',
  },

  content_transparent: {
    background: 'transparent',
    boxShadow: 'none',
  },

  content_transparentGradient: {
    background: `linear-gradient(to bottom, ${mediumGradient})`,
    boxShadow: 'none',
  },

  // Logo

  logoContainer: {
    // Positions the logo to sit above the rest of the header,
    // the flyout menu, and the flyout menu's mask.
    position: 'relative',
    zIndex: ZINDEX.logo,
  },

  // Flyout Menu

  flyoutMenuContainer: {
    // Positions the flyout menu above the main
    // header, but below the logo and its mask.
    backgroundColor: color.white,
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: ZINDEX.flyoutMenu,
    transform: 'translateY(-100%)',
    transitionDuration: '0.2s',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease-out',

    [responsive.print]: {
      display: 'none',
    },
  },

  flyoutMenuContainer_open: {
    transform: 'translateY(0)',
  },

  flyoutMenuMask: {
    height: HEADER_HEIGHT,
    width: '100%',
    backgroundColor: color.white,
    position: 'absolute',
    top: 0,
    left: 0,
    // Safari doesn't properly repaint and paints black
    willChange: 'transform',
    // Positions the flyout menu's mask to sit below the logo but
    // above the flyout menu itself. The intended effect is to make
    // the flyout menu appear to be sliding out from underneath the
    // rest of the header but maintaining visibility of the logo,
    // which is used to toggle the menu open and closed.
    zIndex: ZINDEX.flyoutMenuMask,
  },
}))(Header);
