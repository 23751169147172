import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';
import idProp from '../utils/propTypes/idProp';
import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { adjustableSpacingShape } from '../constants/adjustableSpacing';
import notNull from '../utils/propTypes/notNull';
import { PropsType } from '../private/types';

export const spacingPropTypes = {
  id: notNull(idProp),
  top: PropTypes.number,
  topMediumAndAbove: adjustableSpacingShape,
  topLargeAndAbove: adjustableSpacingShape,
  bottom: PropTypes.number,
  bottomMediumAndAbove: adjustableSpacingShape,
  bottomLargeAndAbove: adjustableSpacingShape,
  left: PropTypes.number,
  leftMediumAndAbove: adjustableSpacingShape,
  leftLargeAndAbove: adjustableSpacingShape,
  right: PropTypes.number,
  rightMediumAndAbove: adjustableSpacingShape,
  rightLargeAndAbove: adjustableSpacingShape,
  horizontal: PropTypes.number,
  horizontalMediumAndAbove: adjustableSpacingShape,
  horizontalLargeAndAbove: adjustableSpacingShape,
  vertical: PropTypes.number,
  verticalAlign: PropTypes.string,
  verticalMediumAndAbove: adjustableSpacingShape,
  verticalLargeAndAbove: adjustableSpacingShape,
  textInline: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export type SpacingProps = PropsType<typeof spacingPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...spacingPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to spacingPropTypes
});

export const defaultProps = {
  id: undefined,
  top: null,
  topMediumAndAbove: null,
  topLargeAndAbove: null,
  bottom: null,
  bottomMediumAndAbove: null,
  bottomLargeAndAbove: null,
  left: null,
  leftMediumAndAbove: null,
  leftLargeAndAbove: null,
  right: null,
  rightMediumAndAbove: null,
  rightLargeAndAbove: null,
  horizontal: null,
  horizontalMediumAndAbove: null,
  horizontalLargeAndAbove: null,
  vertical: null,
  verticalMediumAndAbove: null,
  verticalLargeAndAbove: null,
  verticalAlign: undefined,
  inline: false,
  textInline: false,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Spacing({
  css,
  children,
  id,
  inline,
  textInline,
  styles,
  theme,
  top,
  topMediumAndAbove,
  topLargeAndAbove,
  bottom,
  bottomMediumAndAbove,
  bottomLargeAndAbove,
  left,
  leftMediumAndAbove,
  leftLargeAndAbove,
  right,
  rightMediumAndAbove,
  rightLargeAndAbove,
  horizontal,
  horizontalMediumAndAbove,
  horizontalLargeAndAbove,
  vertical,
  verticalAlign,
  verticalMediumAndAbove,
  verticalLargeAndAbove,
}: PrivateProps) {
  const computedTop = top != null ? top : vertical;
  const computedBottom = bottom != null ? bottom : vertical;
  const computedLeft = left != null ? left : horizontal;
  const computedRight = right != null ? right : horizontal;

  const computedStyles = {
    ...(computedTop !== null ? { marginTop: computedTop * theme.unit } : {}),
    ...(computedBottom !== null ? { marginBottom: computedBottom * theme.unit } : {}),
    ...(computedLeft !== null ? { marginLeft: computedLeft * theme.unit } : {}),
    ...(computedRight !== null ? { marginRight: computedRight * theme.unit } : {}),
  };

  return (
    <div
      {...css(
        verticalAlign && { verticalAlign },
        inline && styles.inlineBlock,
        textInline && styles.inline,
        computedStyles,
        topMediumAndAbove != null && styles[`topMediumAndAbove-${topMediumAndAbove}`],
        topLargeAndAbove != null && styles[`topLargeAndAbove-${topLargeAndAbove}`],
        bottomMediumAndAbove != null && styles[`bottomMediumAndAbove-${bottomMediumAndAbove}`],
        bottomLargeAndAbove != null && styles[`bottomLargeAndAbove-${bottomLargeAndAbove}`],
        verticalMediumAndAbove != null &&
          styles[`verticalMediumAndAbove-${verticalMediumAndAbove}`],
        verticalLargeAndAbove != null && styles[`verticalLargeAndAbove-${verticalLargeAndAbove}`],
        rightMediumAndAbove != null && styles[`rightMediumAndAbove-${rightMediumAndAbove}`],
        rightLargeAndAbove != null && styles[`rightLargeAndAbove-${rightLargeAndAbove}`],
        leftMediumAndAbove != null && styles[`leftMediumAndAbove-${leftMediumAndAbove}`],
        leftLargeAndAbove != null && styles[`leftLargeAndAbove-${leftLargeAndAbove}`],
        horizontalMediumAndAbove != null &&
          styles[`horizontalMediumAndAbove-${horizontalMediumAndAbove}`],
        horizontalLargeAndAbove != null &&
          styles[`horizontalLargeAndAbove-${horizontalLargeAndAbove}`],
      )}
      id={id}
    >
      {children}
    </div>
  );
}

Spacing.propTypes = privatePropTypes;
Spacing.defaultProps = defaultProps;

export default withStyles(({ unit, responsive }) => {
  const responsiveUnits = [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const marginStyles = {};

  responsiveUnits.forEach((size) => {
    // @ts-ignore
    marginStyles[`topMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginTop: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`topLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginTop: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`bottomMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginBottom: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`bottomLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginBottom: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`rightMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginRight: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`rightLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginRight: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`leftMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginLeft: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`leftLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginLeft: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`verticalMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginTop: size * unit,
        marginBottom: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`verticalLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginTop: size * unit,
        marginBottom: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`horizontalMediumAndAbove-${size}`] = {
      [responsive.mediumAndAbove]: {
        marginLeft: size * unit,
        marginRight: size * unit,
      },
    };
    // @ts-ignore
    marginStyles[`horizontalLargeAndAbove-${size}`] = {
      [responsive.largeAndAbove]: {
        marginLeft: size * unit,
        marginRight: size * unit,
      },
    };
  });

  return {
    inline: {
      display: 'inline',
    },

    inlineBlock: {
      display: 'inline-block',
    },

    ...marginStyles,
  };
})(Spacing);
