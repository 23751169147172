const {
  // brand,
  core, social, white, clear,
} = require('./baseColors');
const {
  standardActiveColor,
  secondaryActiveBackgroundColor,
  standardDisabledColor,
} = require('./buttonStateColors');

// Helper function to build standard button style colors
function buildStandardButton(id, { baseColor, baseText = white, baseBorder = clear } = {}) {
  return {
    // default
    [`${id}Border`]: baseBorder,
    [`${id}Color`]: baseColor,
    [`${id}Text`]: baseText,

    // active state
    [`${id}ActiveBorder`]: standardActiveColor(baseBorder),
    [`${id}ActiveColor`]: standardActiveColor(baseColor),
    [`${id}ActiveText`]: baseText,

    // disabled state
    [`${id}DisabledBorder`]: standardDisabledColor(baseBorder),
    [`${id}DisabledColor`]: standardDisabledColor(baseColor),
    [`${id}DisabledText`]: baseText,

    // hover state
    [`${id}HoverBorder`]: baseBorder,
    [`${id}HoverColor`]: baseColor,
    [`${id}HoverText`]: baseText,
  };
}

// Helper function to build secondary button style colors
function buildSecondaryButton(id, { baseColor, inverse, mono } = {}) {
  return {
    // default state
    [`${id}Border`]: baseColor,
    [`${id}Color`]: clear,
    [`${id}Text`]: baseColor,

    // active state
    [`${id}ActiveBorder`]: inverse || mono ? baseColor : standardActiveColor(baseColor),
    [`${id}ActiveColor`]: secondaryActiveBackgroundColor(baseColor),
    [`${id}ActiveText`]: inverse || mono ? baseColor : standardActiveColor(baseColor),

    // disabled state
    [`${id}DisabledBorder`]: standardDisabledColor(baseColor),
    [`${id}DisabledColor`]: clear,
    [`${id}DisabledText`]: standardDisabledColor(baseColor),

    // hover state
    [`${id}HoverBorder`]: baseColor,
    [`${id}HoverColor`]: clear,
    [`${id}HoverText`]: baseColor,
  };
}

// Helper function to build social button style colors
function buildSocialButton(id, { baseText = white, ...otherOptions } = {}) {
  return {
    ...buildStandardButton(id, { baseText, ...otherOptions }),

    [`${id}DisabledText`]: baseText === white ? baseText : standardDisabledColor(baseText),
  };
}

const buttons = {
  // Standard buttons
  ...buildStandardButton('primary', { baseColor: core.rausch }),
  ...buildStandardButton('default', { baseColor: core.babu }),

  ...buildStandardButton('inverse', { baseColor: white, baseText: core.hof }),

  // Secondary buttons
  ...buildSecondaryButton('secondary', { baseColor: core.babu }),

  ...buildSecondaryButton('secondaryInverse', { baseColor: white, inverse: true }),
  ...buildSecondaryButton('mono', { baseColor: core.hof, mono: true }),

  // Buttons with custom small colors
  ...buildStandardButton('primarySmall', { baseColor: '#DB3742' }),

  // Social buttons
  ...buildSocialButton('alipay', { baseColor: social.alipay }),
  ...buildSocialButton('email', { baseColor: social.email }),
  ...buildSocialButton('facebook', { baseColor: social.facebook }),
  ...buildSocialButton('kakaotalk', { baseColor: social.kakaotalk }),
  ...buildSocialButton('messenger', { baseColor: social.messenger }),
  ...buildSocialButton('telegram', { baseColor: social.telegram }),
  ...buildSocialButton('twitter', { baseColor: social.twitter }),
  ...buildSocialButton('wechat', { baseColor: social.wechat }),
  ...buildSocialButton('weibo', { baseColor: social.weibo }),
  ...buildSocialButton('whatsapp', { baseColor: social.whatsapp }),
  ...buildSocialButton('yahooJapan', { baseColor: social.yahooJapan }),
  ...buildSocialButton('google', {
    baseBorder: core.foggy,
    baseColor: social.google,
    baseText: core.hof,
  }),

  // Backward compatibility
  ...buildStandardButton('defaultSmall', { baseColor: core.babu }),
  ...buildSecondaryButton('secondarySmall', { baseColor: core.babu }),
};

export default buttons;
