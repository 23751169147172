export const CUSTOM_STYLES_KEY = 'button';

export default {
  fontFamily: '--font-button-font-family',
  fontSize: '--font-button-font-size',
  fontWeight: '--font-bold-font-weight',
  lineHeight: '--font-button-line-height',
  letterSpacing: '--font-button-letter-spacing',
  textTransform: '--font-button-text-transform',

  backgroundColor: '--color-buttons-default-color',
  borderColor: '--color-buttons-default-border',
  textColor: '--color-buttons-default-text',
  backgroundColorHover: '--color-buttons-default-hover-color',
  borderColorHover: '--color-buttons-default-hover-border',
  textColorHover: '--color-buttons-default-hover-text',
  backgroundColorActive: '--color-buttons-default-active-color',
  borderColorActive: '--color-buttons-default-active-border',
  textColorActive: '--color-buttons-default-active-text',
  backgroundColorDisabled: '--color-buttons-default-disabled-color',
  borderColorDisabled: '--color-buttons-default-disabled-border',
  textColorDisabled: '--color-buttons-default-disabled-text',

  borderRadius: '--border-button-border-radius',
  borderWidth: '--border-button-border-width',

  paddingHorizontal: '--spacing-button-horizontal',
  paddingSmallHorizontal: '--spacing-button-small-horizontal',
  paddingVertical: '--spacing-button-vertical',
};
