const FONT_FAMILY = 'Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif';

const WEIGHT_BOLD = '700';
const WEIGHT_BOOK = '400';
const WEIGHT_LIGHT = '300';

export default {
  fontFamily: {
    title1: FONT_FAMILY,
    title2: FONT_FAMILY,
    title3: FONT_FAMILY,
    large: FONT_FAMILY,
    regular: FONT_FAMILY,
    small: FONT_FAMILY,
    mini: FONT_FAMILY,
    micro: FONT_FAMILY,
  },
  size: {
    title1: 40,
    title1_mediumAndAbove: 48,
    title2: 32,
    title2_mediumAndAbove: 36,
    title3: 25,
    title3_mediumAndAbove: 28,
    large: 22,
    regular: 19,
    small: 15,
    mini: 12, // mini is not defined, so we always match micro
    micro: 12,
  },
  weight: {
    lightest: {
      title1: WEIGHT_LIGHT,
      title2: WEIGHT_LIGHT,
      title3: WEIGHT_LIGHT,
      large: WEIGHT_LIGHT,
      regular: WEIGHT_LIGHT,
      small: WEIGHT_LIGHT,
      mini: WEIGHT_LIGHT,
      micro: WEIGHT_LIGHT,
    },
    lighter: {
      title1: WEIGHT_BOOK,
      title2: WEIGHT_BOOK,
      title3: WEIGHT_BOOK,
      large: WEIGHT_LIGHT,
      regular: WEIGHT_LIGHT,
      small: WEIGHT_LIGHT,
      mini: WEIGHT_LIGHT,
      micro: WEIGHT_LIGHT,
    },
    default: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_BOLD,
      large: WEIGHT_BOOK,
      regular: WEIGHT_BOOK,
      small: WEIGHT_BOOK,
      mini: WEIGHT_BOOK,
      micro: WEIGHT_BOOK,
    },
    bolder: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_BOLD,
      large: WEIGHT_BOLD,
      regular: WEIGHT_BOLD,
      small: WEIGHT_BOLD,
      mini: WEIGHT_BOLD,
      micro: WEIGHT_BOLD,
    },
    boldest: {
      title1: WEIGHT_BOLD,
      title2: WEIGHT_BOLD,
      title3: WEIGHT_BOLD,
      large: WEIGHT_BOLD,
      regular: WEIGHT_BOLD,
      small: WEIGHT_BOLD,
      mini: WEIGHT_BOLD,
      micro: WEIGHT_BOLD,
    },
  },
  leading: {
    default: {
      title1: 48,
      title1_mediumAndAbove: 56,
      title2: 40,
      title2_mediumAndAbove: 40,
      title3: 30,
      title3_mediumAndAbove: 32,
      large: 28,
      regular: 24,
      small: 18,
      mini: 16,
      micro: 16,
    },
    tall: {
      title1: 48,
      title1_mediumAndAbove: 56,
      title2: 40,
      title2_mediumAndAbove: 40,
      title3: 30,
      title3_mediumAndAbove: 32,
      large: 32,
      regular: 28,
      small: 18,
      mini: 16,
      micro: 16,
    },
  },
  tracking: {
    default: {
      title1: -0.8,
      title2: -0.6,
      title3: -0.6,
      large: -0.2,
      regular: 0,
      small: 0.2,
      mini: 0.2,
      micro: 0.2,
    },
    wide: {
      title1: 1,
      title2: 1,
      title3: 1,
      large: 1,
      regular: 1,
      small: 1,
      mini: 1,
      micro: 1,
    },
  },
  deprecatedSpacing: {
    title1: 8,
    title2: 6,
    title3: 2,
  },
};
