import { toggleFlyoutMenu } from '../../header-shared/actions';
// import connect from '../../redux-connect';
import { connect } from 'react-redux';

import Header, { HeaderProps } from '../components/Header';

function mapStateToProps({
  header,
}: {
  header: { activeNavigationItem: $TSFixMe; flyoutMenuIsOpen: boolean };
}) {
  return {
    activeNavigationItem: header.activeNavigationItem,
    flyoutMenuIsOpen: header.flyoutMenuIsOpen,
  };
}

const mapDispatchToProps = {
  onPressLogo: toggleFlyoutMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

export type HeaderContainerProps = HeaderProps;
