import React from 'react';
import withBreakpoint, { WithBreakpointProps } from '../../dls-web/exp/withBreakpoint';
// import { PROGRESSIVE_BREAKPOINT_NAMES, matchesProgressive } from '../../dls-web/size';
import Link from '@material-ui/core/Link';
import Navigation from '../../header/components/Navigation';
import Spacing from '../../dls-web/exp/Spacing';

import { ABOUT, SERVICES, CONTACT } from '../constants/routeConstants';

type Props = {
} & WithBreakpointProps;

class HeaderContent extends React.Component<Props> {
  render() {
    // const isScreenLargeAndAbove = matchesProgressive(
    //   currentBreakpoint,
    //   PROGRESSIVE_BREAKPOINT_NAMES.LARGE_AND_ABOVE,
    // );

    return (
      <Navigation>
        <Spacing horizontal={2}>
          <Link href={`/`} color='textPrimary' underline='none'>Home</Link>
        </Spacing>
        <Spacing horizontal={2}>
          <Link href={`/${ABOUT}`} color='textPrimary' underline='none'>About</Link>
        </Spacing>
        {/*
          <Spacing horizontal={2}>
            <Link href={`/${SERVICES}`} color='textPrimary' underline='none'>Services</Link>
          </Spacing>

          <Spacing horizontal={2}>
            <Link href={`/${CONTACT}`} color='textPrimary' underline='none'>Contact</Link>
          </Spacing>
          */}
      </Navigation>
    );
  }
}

export default withBreakpoint(HeaderContent);
