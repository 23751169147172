import globalCache from 'global-cache';

import CerealTheme from '../cereal/CerealTheme';

import {
  DEFAULT_THEME,
  CEREAL_THEME,
} from '../constants';

// Map of theme id to the theme.
const THEMES_BY_ID = {
  [CEREAL_THEME]: CerealTheme,
};

export default function buildTheme(themeId) {
  const theme = THEMES_BY_ID[themeId] || THEMES_BY_ID[DEFAULT_THEME];
  return globalCache.setIfMissingThenGet(`monorail airbnb-dls-web theme: ${themeId}`, () => theme);
}
