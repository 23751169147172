import PropTypes from 'prop-types';
import { Props, InnerType } from './types';

// prop is required by requiredByPropName unless optionalPropName
function requiredByUnless<PT extends PropTypes.Validator<any>>(
  requiredByPropName: string,
  optionalPropName: string,
  propType: PT,
  defaultValue = null,
): PropTypes.Validator<InnerType<PT>> {
  // eslint-disable-next-line consistent-return
  return function validator(props: Props, propName, componentName, ...rest) {
    if (
      props[requiredByPropName] &&
      (props[propName] === defaultValue || typeof props[propName] === 'undefined') &&
      !props[optionalPropName]
    ) {
      return new TypeError(
        `${componentName}: when ${requiredByPropName} is true and ${optionalPropName} is not set, prop “${propName}” must be present.`,
      );
    }

    return propType(props, propName, componentName, ...rest);
  };
}

export default requiredByUnless;
