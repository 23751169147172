import React from 'react';

let previousWindowScrollY;

export function disableBodyScrolling() {
  previousWindowScrollY = window.scrollY;

  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
}

export function enableBodyScrolling() {
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('overflow');

  if (previousWindowScrollY) {
    window.scrollTo(0, previousWindowScrollY);
  }
}

let scrollingDisabledCount = 0;

export default class DisableBodyScrolling extends React.Component {
  componentDidMount() {
    scrollingDisabledCount += 1;
    disableBodyScrolling();
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    scrollingDisabledCount -= 1;
    if (scrollingDisabledCount <= 0) {
      enableBodyScrolling();
    }
  }

  render() {
    return null;
  }
}
