// This higher-order-function consumes a boolean from React context that is
// provided by the LoadingProvider component. We can use this to create subtrees
// of our application that render as a loading state.
//
// We ideally want this to be as seamless and easy to use as possible, so we
// have baked this in to low-level components, like BaseText. These components
// render a Shimmer component when the loading state has been provided. Ideally,
// this will allow you to maintain the same layout as the loaded state, but
// rendered progressively while the data is being fetched. We hope that this
// will be a nicer experience than seeing a generic spinner.

/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
// @ts-ignore
import getComponentName from 'airbnb-prop-types/build/helpers/getComponentName';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { CHANNEL } from './LoadingProvider';
import brcastShape from '../shapes/brcast';
import { Omit, ElementConfig, PropsType } from '../private/types';

const contextTypes = {
  [CHANNEL]: brcastShape,
};

const loadingPropTypes = {
  noLoading: PropTypes.bool,
};

export type LoadingProps = PropsType<typeof loadingPropTypes>;

const defaultProps = {
  noLoading: false,
};

// export for convenience, in order for components to spread these onto their propTypes
export const withLoadingPropTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export type WithLoadingProps = PropsType<typeof withLoadingPropTypes>;

export default function withLoading<C extends React.ComponentType<any>>(WrappedComponent: C) {
  type ComponentProps = ElementConfig<C> & LoadingProps;
  type Props = Omit<ComponentProps, keyof WithLoadingProps>;

  type State = { isLoading: boolean };

  const wrappedComponentName = getComponentName(WrappedComponent) || 'Component';

  class WithLoading extends React.Component<Props, State> {
    static WrappedComponent = WrappedComponent;

    static contextTypes = contextTypes;

    // TODO: think of better workaround than casting
    static propTypes = (loadingPropTypes as unknown) as PropTypes.ValidationMap<Props>;

    // TODO: think of better workaround than casting
    static defaultProps = (defaultProps as unknown) as Partial<Props>;

    static displayName = `withLoading(${wrappedComponentName})`;

    constructor(props: Props, context?: any) {
      super(props, context);
      this.state = {
        isLoading: context[CHANNEL] ? context[CHANNEL].getState() : false,
      };
    }

    componentDidMount() {
      if (this.context[CHANNEL]) {
        // subscribe to future loading changes
        this.unsubscribe = this.context[CHANNEL].subscribe((isLoading: any) => {
          this.setState({
            isLoading,
          });
        });
      }
    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }

    unsubscribe?: () => void;

    render() {
      const { isLoading } = this.state;
      const { noLoading, ...restProps } = this.props as LoadingProps;
      // @ts-ignore [ts] JSX element type 'WrappedComponent' does not have any construct or call signatures.
      return <WrappedComponent {...restProps} isLoading={!noLoading && isLoading} />;
    }
  }

  // Explicitly define return type to avoid
  // `[ts] Return type of exported function has or is using private name 'WithLoading'.`
  // TODO: think of better workaround
  type WithLoadingComponentType = React.ComponentClass<Props> & {
    WrappedComponent: React.ComponentType<ComponentProps>;
  };

  return hoistNonReactStatics(WithLoading, WrappedComponent) as WithLoadingComponentType;
}
