import createStore, { hydrateReducerState } from './create-store/createStore';

import Reducers from './reducer';

export default function configureStore(bootstrappedData: object) {
  const store = createStore();
  store.injectAll(Reducers, true);
  hydrateReducerState(bootstrappedData || {});
  return store;
}
