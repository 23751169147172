import React from 'react';
import PropTypes from 'prop-types';
import { addEventListener } from 'consolidated-events';
import has from 'has';
import { WithDefaultProps } from '../ts-utils/types/WithDefaultProps';

const knownTargets = {
  window: () => global.window,
  document: () => global.document,
};

type OwnProps = {
  target?: $TSFixMe;
  type:
    | 'beforeprint'
    | 'beforeunload'
    | 'blur'
    | 'click'
    | 'error'
    | 'focus'
    | 'focusin'
    | 'focusout'
    | 'hashchange'
    | 'keydown'
    | 'keypress'
    | 'keyup'
    | 'load'
    | 'message'
    | 'mousedown'
    | 'mouseenter'
    | 'mouseleave'
    | 'mousemove'
    | 'mouseout'
    | 'mouseover'
    | 'mouseup'
    | 'pageshow'
    | 'popstate'
    | 'resize'
    | 'scroll'
    | 'select'
    | 'touchcancel'
    | 'touchend'
    | 'touchmove'
    | 'touchstart'
    | 'visibilitychange'
    | 'unload'
    | 'wheel';
  onEvent?: $TSFixMe;
  passive?: boolean;
  capture?: boolean;
};

const propTypes = {
  // `target` is anything that implements the `EventTarget` abstract interface,
  // (usually window, document, or document.body).
  target(props: Props, propName: keyof Props, componentName: string) {
    // We use a custom validator function for this, because things like
    // `Window`, `Document`, and `HTMLElement` are not defined in node.
    const value = props[propName];

    // String 'window' or 'document' is fine
    if (has(knownTargets, value)) {
      return null;
    }

    // Mimick built-in isRequired
    // https://github.com/facebook/react/blob/de7d1da9/src/isomorphic/classic/types/ReactPropTypes.js#L116-L123
    if (value == null) {
      return new Error(`Required prop \`${propName}\` was not specified in \`${componentName}\`.`);
    }

    // We cannot validate in node
    if (typeof window === 'undefined') {
      return null;
    }

    // We can't validate that it is an instance of `EventTarget` directly
    // because that is an abstract interface which is undefined in some browsers
    // (e.g. Safari).
    const allowedConstructors = [Window, Document, HTMLDocument, HTMLElement];
    if (allowedConstructors.some((constructor) => value instanceof constructor)) {
      return null;
    }

    // eslint-disable-next-line no-restricted-properties
    const type = Object.prototype.toString.call(value).replace(/.*\s(.*?)]/, '$1');

    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Must be an instance of \`EventTarget\`, got \`${type}\`.`, // eslint-disable-line max-len
    );
  },

  type: PropTypes.oneOf([
    'beforeprint',
    'beforeunload',
    'blur',
    'click',
    'error',
    'focus',
    'focusin',
    'focusout',
    'hashchange',
    'keydown',
    'keypress',
    'keyup',
    'load',
    'message',
    'mousedown',
    'mouseenter',
    'mouseleave',
    'mousemove',
    'mouseout',
    'mouseover',
    'mouseup',
    'pageshow',
    'popstate',
    'resize',
    'scroll',
    'select',
    'touchcancel',
    'touchend',
    'touchmove',
    'touchstart',
    'visibilitychange',
    'unload',
    'wheel',
  ]).isRequired,

  onEvent: PropTypes.func.isRequired,

  passive: PropTypes.bool,

  capture: PropTypes.bool,
};

const defaultProps = {
  target: undefined,
  passive: false,
  capture: false,
};

function addListener({ target, type, onEvent, passive, capture }: $TSFixMe) {
  if (typeof target === 'string' && !has(knownTargets, target)) {
    throw new Error(`Unknown target "${target}" specified in EventListener"`);
  }

  // @ts-ignore ts-migrate(7053) FIXME: No index signature with a parameter of type 'strin... Remove this comment to see the full error message
  const finalTarget = typeof target === 'string' ? knownTargets[target]() : target;
  return addEventListener(finalTarget, type, onEvent, { passive, capture });
}

type Props = WithDefaultProps<OwnProps, typeof defaultProps>;

export default class EventListener extends React.Component<Props> {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  removeEventListener: $TSFixMe;

  componentDidMount() {
    this.removeEventListener = addListener(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { target, type, onEvent, passive, capture } = this.props;

    if (
      target !== nextProps.target ||
      type !== nextProps.type ||
      onEvent !== nextProps.onEvent ||
      passive !== nextProps.passive ||
      capture !== nextProps.capture
    ) {
      if (this.removeEventListener) {
        this.removeEventListener();
      }
      this.removeEventListener = addListener(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.removeEventListener) {
      this.removeEventListener();
    }
  }

  render() {
    return null;
  }
}
