import PropTypes from 'prop-types';
import { Props } from './types';

export default function notNull<T>(
  propType: PropTypes.Validator<T | null | undefined>,
): PropTypes.Validator<T | undefined> {
  return function validator(props: Props, propName, componentName, ...rest) {
    if (props[propName] === null) {
      return new TypeError(`${componentName}: ${propName} cannot be null.`);
    }

    return propType(props, propName, componentName, ...rest);
  };
}
