import PropTypes from 'prop-types';
import { restrictedProp } from 'airbnb-prop-types';
import { ArgumentType } from '../../private/types';
import { Props, InnerType } from './types';

type RestrictedMessage = ArgumentType<typeof restrictedProp>;

export default function<PT extends PropTypes.Validator<any>>({
  propType,
  restrictedCondition,
  restrictedMessage,
}: {
  propType: PT;
  restrictedCondition: (props: Props, propName: string) => boolean;
  restrictedMessage: RestrictedMessage;
}): PropTypes.Validator<InnerType<PT>> {
  return function conditionallyRestrictedProp(props, propName, componentName, ...rest) {
    if (restrictedCondition(props, propName)) {
      return restrictedProp(restrictedMessage)(props, propName, componentName, ...rest);
    }
    return propType(props, propName, componentName, ...rest);
  };
}
