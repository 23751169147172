import React from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps, mutuallyExclusiveTrueProps } from 'airbnb-prop-types';

import { PropsType } from '../private/types';
// import processThemeStyles from '../private/processThemeStyles';
import CustomStylesInjector from '../private/CustomStylesInjector';
import getPropTypeForStyles from '../private/styles/getPropTypeForStyles';
import loaderStyleMapping, { CUSTOM_STYLES_KEY } from '../private/styles/loaderStyleMapping';
import { withStyles, withStylesPropTypes } from '../themes/withStyles';
// import withBrand, { BRAND_SOME, withBrandPropTypes } from '../themes/withBrand';

const colorScheme = mutuallyExclusiveTrueProps('light', 'dark');

export const loadingPropTypes = {
  // ...withBrandPropTypes,
  small: PropTypes.bool,
  large: PropTypes.bool,
  light: colorScheme,
  dark: colorScheme,
  inline: PropTypes.bool,
  customStyles: getPropTypeForStyles(loaderStyleMapping),
};

export type LoaderProps = PropsType<typeof loadingPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...loadingPropTypes,
  ...withStylesPropTypes,
});

const defaultProps = {
  small: false,
  large: false,
  dark: false,
  light: false,
  inline: false,
  customStyles: null,
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Loader({
  css,
  small,
  large,
  light,
  dark,
  styles,
  inline,
  // brand,
  customStyles,
}: PrivateProps) {
  // const isSomeBrand = brand === BRAND_SOME;
  let dotColor: $TSFixMe;

  // if (isSomeBrand) {
  //   dotColor = styles.dotDefault_some;
  // } else {
  //   dotColor = styles.dotDefault;
  // }
  dotColor = styles.dotDefault;

  if (light) {
    dotColor = styles.dotLight;
  } else if (dark) {
    dotColor = styles.dotDark;
  }

  return (
    <CustomStylesInjector
      componentID={CUSTOM_STYLES_KEY}
      customStyles={customStyles}
      inline={inline}
      styleMapping={loaderStyleMapping}
    >
      <div
        {...css(
          styles.container,
          !inline && styles.container_absoluteCenter,
          inline && styles.container_inline,
        )}
      >
        <div
          {...css(
            styles.dot,
            large && styles.dot_large,
            small && styles.dot_small,
            dotColor,
            styles.dot1,
          )}
        />
        <div
          {...css(
            styles.dot,
            large && styles.dot_large,
            small && styles.dot_small,
            dotColor,
            styles.dot2,
          )}
        />
        <div {...css(styles.dot, large && styles.dot_large, small && styles.dot_small, dotColor)} />
      </div>
    </CustomStylesInjector>
  );
}

Loader.propTypes = privatePropTypes;
Loader.defaultProps = defaultProps;

export default withStyles(({ color }) => ({
  container: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },

  container_absoluteCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },

  container_inline: {
    display: 'inline-block',
  },

  dot: {
    width: 6,
    height: 6,
    marginRight: 4,
    borderRadius: '100%',
    display: 'inline-block',
    animationName: {
      '0%, 80%, 100%': {
        opacity: 0,
      },
      '30%, 50%': {
        opacity: 1,
      },
    },
    animationDuration: '0.8s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    animationFillMode: 'both',
    verticalAlign: 'middle',
  },

  dotDefault: {
    backgroundColor: color.loaderDots.default,
  },

  dotLight: {
    backgroundColor: color.loaderDots.light,
  },

  dotDark: {
    backgroundColor: color.loaderDots.dark,
  },

  dotDefault_some: {
    backgroundColor: color.brand.some,
  },

  dot1: {
    animationDelay: '-0.3s',
  },

  dot2: {
    animationDelay: '-0.15s',
  },

  dot_large: {
    width: 12,
    height: 12,
    marginRight: 8,
  },

  dot_small: {
    width: 4,
    height: 4,
    marginRight: 2,
  },
}))(Loader);
