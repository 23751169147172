import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import textlike from '../utils/propTypes/textlike';
import Spacing from './Spacing';
import { withStyles, withStylesPropTypes } from '../themes/withStyles';
import { PropsType } from '../private/types';

export const labeledPropTypes = {
  children: textlike,
  after: PropTypes.node,
  before: PropTypes.node,
  centered: PropTypes.bool,
  inline: PropTypes.bool,
  spaceBetween: PropTypes.number,
  verticalAlign: PropTypes.oneOf(['middle', 'top']),
};

export type LabeledProps = PropsType<typeof labeledPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...labeledPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to labeledPropTypes
});

const defaultProps = {
  children: null,
  after: null,
  before: null,
  centered: false,
  inline: false,
  spaceBetween: 1,
  verticalAlign: 'middle',
};

type PrivateProps = PropsType<typeof privatePropTypes, typeof defaultProps>;

function Labeled({
  css,
  after,
  before,
  centered,
  children,
  inline,
  spaceBetween,
  styles,
  verticalAlign,
}: PrivateProps) {
  const justifyContent = centered ? 'center' : null;
  const childStyles = [
    styles.child,
    inline && styles.child_inline,
    !inline && verticalAlign === 'middle' && styles.child_vaMiddle,
    !inline && verticalAlign === 'top' && styles.child_vaTop,
  ];
  return (
    <div {...css(inline ? styles.container_inline : styles.container, { justifyContent })}>
      {before && (
        <div {...css(childStyles)}>
          <Spacing right={spaceBetween}>{before}</Spacing>
        </div>
      )}

      <div {...css(childStyles)}>
        <div>{children}</div>
      </div>

      {after && (
        <div {...css(childStyles)}>
          <Spacing left={spaceBetween}>{after}</Spacing>
        </div>
      )}
    </div>
  );
}

Labeled.propTypes = privatePropTypes;
Labeled.defaultProps = defaultProps;

export default withStyles(() => ({
  container: {
    display: 'flex',
  },

  container_inline: {
    display: 'inline-block',
  },

  child: {
    display: 'inline-flex',
  },

  child_inline: {
    display: 'inline-block',
  },

  child_vaMiddle: {
    alignSelf: 'center',
  },

  child_vaTop: {
    alignSelf: 'initial',
  },
}))(Labeled)
