import * as React from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import AphroditeInterface from 'react-with-styles-interface-amp-aphrodite';
import { WithDefaultProps } from '../ts-utils/types/WithDefaultProps';
import { THEME_IDS, DEFAULT_THEME, ThemeId } from './constants';
// @ts-ignore
import registerRWSThemeAndInterface from './registerRWSThemeAndInterface';
import buildTheme from './utils/buildTheme';
import WithStylesContextProvider from '../with-styles/WithStylesContextProvider';

type GlobalThemeProps = {
  themeId?: ThemeId;
};

const propTypes = {
  themeId: PropTypes.oneOf(THEME_IDS),
};

const defaultProps = {
  themeId: DEFAULT_THEME,
};

export default function withGlobalTheme<C extends React.ComponentType<any>>(WrappedComponent: C) {
  // type ComponentProps = React.ElementConfig<C>;
  // @ts-ignore
  type ComponentProps = $TSFixMe;
  type Props = GlobalThemeProps & ComponentProps;
  type PrivateProps = WithDefaultProps<GlobalThemeProps, typeof defaultProps> & ComponentProps;

  class ThemedComponent extends React.Component<PrivateProps> {
    constructor(props: PrivateProps) {
      super(props);

      registerRWSThemeAndInterface(props.themeId);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps: PrivateProps) {
      if (THEME_IDS.includes(nextProps.themeId) && nextProps.themeId !== this.props.themeId) {
        registerRWSThemeAndInterface(nextProps.themeId);
      }
    }

    render() {
      const { themeId, ...componentProps } = this.props;
      return (
        <WithStylesContextProvider
          stylesInterface={AphroditeInterface}
          stylesTheme={buildTheme(themeId)}
        >
          {/*
          // @ts-ignore */}
          <WrappedComponent {...componentProps} />
        </WithStylesContextProvider>
      );
    }
  }

  // @ts-ignore
  ThemedComponent.propTypes = {
    ...WrappedComponent.propTypes,
    ...propTypes,
  };
  // @ts-ignore
  ThemedComponent.defaultProps = {
    ...WrappedComponent.defaultProps,
    ...defaultProps,
  };
  // @ts-ignore
  ThemedComponent.displayName = `withGlobalTheme(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'})`;

  return ThemedComponent as React.ComponentClass<Props>;
}
