import color from '../color';

export default ({ inverse = false } = {}) => ({
  // Removes weird bonus padding from button in Firefox.
  '::-moz-focus-inner': {
    border: 0,
    padding: 0,
    margin: 0,
  },
  ':focus::-moz-focus-inner': {
    border: `1px dotted ${inverse ? color.white : color.black}`,
  },
  ':-moz-focusring': {
    'outline-color': inverse ? color.white : color.black,
  },
});
