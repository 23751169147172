import deepmerge from 'deepmerge';
import has from 'has';

export default function buildThemeExternals(baseTheme, themeOverrides = {}) {
  const { reactDates } = baseTheme; // fallbacks

  const reactDatesOverrides = {
    ...(themeOverrides.font && {
      font: {
        input: {
          ...(themeOverrides.font.formElement && {
            ...(has(themeOverrides.font.formElement, 'fontSize') && {
              size: themeOverrides.font.formElement.fontSize,
            }),
            ...(has(themeOverrides.font.formElement, 'lineHeight') && {
              lineHeight: themeOverrides.font.formElement.lineHeight,
            }),
          }),
          ...(themeOverrides.font.formElementSmall && {
            ...(has(themeOverrides.font.formElementSmall, 'fontSize') && {
              size_small: themeOverrides.font.formElementSmall.fontSize,
            }),
            ...(has(themeOverrides.font.formElementSmall, 'lineHeight') && {
              lineHeight_small: themeOverrides.font.formElementSmall.lineHeight,
            }),
            ...(has(themeOverrides.font.formElementSmall, 'letterSpacing') && {
              letterSpacing_small: themeOverrides.font.formElementSmall.letterSpacing,
            }),
          }),
        },
      },
    }),
    ...(themeOverrides.border &&
      themeOverrides.border.formElement && {
        border: {
          input: {
            ...(has(themeOverrides.border.formElement, 'borderRadius') && {
              borderRadius: themeOverrides.border.formElement.borderRadius,
            }),
          },
          pickerInput: {
            ...(has(themeOverrides.border.formElement, 'borderWidth') && {
              borderWidth: themeOverrides.border.formElement.borderWidth,
            }),
            ...(has(themeOverrides.border.formElement, 'borderRadius') && {
              borderRadius: themeOverrides.border.formElement.borderRadius,
            }),
          },
        },
      }),
  };

  return {
    reactDates: deepmerge(reactDates, reactDatesOverrides),
  };
}
