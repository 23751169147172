// This component provides a boolean to React context that is consumed by the
// withLoading higher-order component. We can use this to create subtrees of our
// application that render as a loading state.
//
// We ideally want this to be as seamless and easy to use as possible, so we
// have baked this in to low-level components, like BaseText. These components
// render a Shimmer component when the loading state has been provided. Ideally,
// this will allow you to maintain the same layout as the loaded state, but
// rendered progressively while the data is being fetched. We hope that this
// will be a nicer experience than seeing a generic spinner.

import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';
import brcast, { Broadcast } from 'brcast';
import brcastShape from '../shapes/brcast';
import { PropsType } from '../private/types';

export const CHANNEL = '__is_loading__';

export const loadingProviderPropTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export type LoadingProviderProps = PropsType<typeof loadingProviderPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...loadingProviderPropTypes,
  // NOTE: Add public props to loadingProviderPropTypes
});

type PrivateProps = PropsType<typeof privatePropTypes>;

const childContextTypes = {
  [CHANNEL]: brcastShape,
};

export default class LoadingProvider extends React.Component<PrivateProps> {
  static propTypes = loadingProviderPropTypes;

  static childContextTypes = childContextTypes;

  broadcast: Broadcast<boolean>;

  constructor(props: LoadingProviderProps) {
    super(props);
    this.broadcast = brcast(props.isLoading);
  }

  getChildContext() {
    return {
      [CHANNEL]: this.broadcast,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: LoadingProviderProps) {
    const { isLoading } = this.props;
    if (isLoading !== nextProps.isLoading) {
      this.broadcast.setState(nextProps.isLoading);
    }
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}
