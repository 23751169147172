import PropTypes from 'prop-types';

export default function getPropTypeForStyles(styles: { [styleKey: string]: string | number }) {
  const stylePropTypes: { [key: string]: PropTypes.Requireable<string | number> } = {};

  Object.keys(styles).forEach((styleKey) => {
    stylePropTypes[styleKey] = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
  });

  return PropTypes.shape(stylePropTypes);
}
