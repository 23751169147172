// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';
// Stylesheet
import { withStyles, withStylesPropTypes } from '../themes/withStyles';

// @ts-ignore
import { PENMOJI_MAPPING } from '../constants/penmojiConstants';
import { PropsType } from '../private/types';

export const PenmojiPropType = PropTypes.oneOf(Object.keys(PENMOJI_MAPPING));

export const penmojiPropTypes = {
  name: PenmojiPropType.isRequired,
  accessibilityLabel: PropTypes.string,
};

export type PenmojiProps = PropsType<typeof penmojiPropTypes>;

const privatePropTypes = forbidExtraProps({
  ...penmojiPropTypes,
  ...withStylesPropTypes,
  // NOTE: Add public props to penmojiPropTypes
});

type PrivateProps = PropsType<typeof privatePropTypes>;

// Components
function Penmoji({ css, name, styles, accessibilityLabel }: PrivateProps) {
  const penmoji = PENMOJI_MAPPING[name];
  return (
    <span
      {...css(styles.penmoji)}
      aria-label={accessibilityLabel != null ? accessibilityLabel : undefined}
      aria-hidden={!accessibilityLabel}
    >
      {penmoji}
    </span>
  );
}

Penmoji.propTypes = privatePropTypes;

export default withStyles(({ font }) => ({
  penmoji: font.penmoji,
}))(Penmoji);
