import React from 'react';
import { withStyles, WithStylesProps } from 'react-with-styles';
import ActiveChevron from '../../dls-web/exp/ActiveChevron';
import ShowAt from '../../dls-web/exp/ShowAt';
import HideAt from '../../dls-web/exp/HideAt';
import color from '../../dls-web/color';
import FlexBar from '../../dls-web/exp/FlexBar';
import Text, { SIZE, WEIGHT, COLOR } from '../../dls-web/exp/Text';

// TODO(Ximin): remove css styles for logo for size config
import '../../App.css';

import { EXPLORE_2_0_HEADER_HEIGHT, HEADER_HEIGHT } from '../../header-shared-constants';
import logo from '../../assets/logo.png';

type Props = {
  text?: string;
  onPress: (e: $TSFixMe) => void;
  menuIndicatorIsActive: boolean;
  colorTheme?: string;
  onHomeNavigation: () => void;
  disableFlyoutMenu?: boolean;
} & WithStylesProps;

function Logo({ menuIndicatorIsActive, onPress, css, styles }: Props) {
  const penslzLogo = <img src={logo} className="App-logo" alt="logo" />;

  const link = (
    <FlexBar
      before={
        <a href="/" {...css(styles.container)}>
          <div {...css(styles.content)}>
            {penslzLogo}
          </div>
        </a>
      }
      after={<Text size={SIZE.TITLE4} weight={WEIGHT.BOLDER} color={COLOR.INHERIT}>Penslz</Text>}
    />
  );

  return (
    <div>
      <ShowAt breakpoint="largeAndAbove">{link}</ShowAt>
      <HideAt breakpoint="largeAndAbove">
        <button
          onClick={onPress}
          aria-label={'header.accessible text for Penslz logo button that opens flyout navigation menu'}
          aria-haspopup
          {...css(styles.container, styles.container_button)}
          type="button"
        >
          <div {...css(styles.content)}>
            <div {...css(styles.logo)}>
              <div {...css(styles.icon)}>
                {penslzLogo}
              </div>
            </div>
            <div {...css(styles.menuIndicator)}>
              <ActiveChevron isActive={menuIndicatorIsActive} color={color.black} />
            </div>
          </div>
        </button>
      </HideAt>
    </div>
  );
}

export default withStyles(
  ({ responsive, unit }) => ({
    container: {
      display: 'table-cell',
    },

    container_button: {
      appearance: 'none',
      background: 'none',
      border: 'none',
      padding: 0,
      margin: 0,

      ':focus': {
        outline: 'none',
      },
    },

    content: {
      display: 'table-cell',
      height: HEADER_HEIGHT,
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      transition: '0.25s color',
      paddingLeft: unit * 3,
      paddingRight: unit,
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',

      // to remove the inline block extra margin so logo can be vertically aligned
      fontSize: 0,
      letterSpacing: 0,
      wordSpacing: 0,

      [responsive.mediumAndAbove]: {
        height: EXPLORE_2_0_HEADER_HEIGHT,
        paddingRight: unit * 2,
      },
    },

    logo: {
      display: 'inline-block',
    },

    icon: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },

    menuIndicator: {
      display: 'inline-block',
      fontSize: 9,
      marginLeft: unit,
      transition: '1s color',
    },
  }),
  { pureComponent: true },
)(Logo);
