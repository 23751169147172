// MANDATORY_REVIEWERS: airbnb/dls-maintainers-web, airbnb/webinfra-refactor-unblockers

// @ts-ignore
import globalCache from 'global-cache';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
// @ts-ignore
import aphroditeInterface from 'react-with-styles-interface-amp-aphrodite';

import buildTheme from './utils/buildTheme';

import { DEFAULT_THEME, CURRENT_THEME_KEY, CURRENT_STYLE_SHEET_KEY, ThemeId } from './constants';

// @ts-ignore
function replaceTheme(theme: $TSFixMe) {
  ThemedStyleSheet.registerTheme(theme);
  ThemedStyleSheet.registerInterface(aphroditeInterface);

  if (typeof window !== 'undefined') {
    globalCache.set(CURRENT_THEME_KEY, theme);
    globalCache.set(CURRENT_STYLE_SHEET_KEY, ThemedStyleSheet);
  }
}

export default function registerRWSThemeAndInterface(themeId: ThemeId = DEFAULT_THEME) {
  // Build the theme object for the specified themeId
  const theme = buildTheme(themeId);

  // Register the new theme as required. We only want to register a new theme if it hasn't been
  // done before. All components will be rendered with this theme.
  if (typeof window === 'undefined') {
    // always replaces theme on the server
    replaceTheme(theme);
  } else {
    const currentTheme = globalCache.get(CURRENT_THEME_KEY);
    const currentStyleSheet = globalCache.get(CURRENT_STYLE_SHEET_KEY);
    if (!currentStyleSheet || currentTheme !== theme) {
      replaceTheme(theme);
    }
  }
}

// This will register the default theme or the bootstrapped theme. However, this can be overwritten
// by calling this function with a different theme id.
registerRWSThemeAndInterface(DEFAULT_THEME);
